import { Table } from 'antd'
import {
  darkGrey,
  extraDesktopWidthMax,
  fadedGrey,
  greyThemeDark1,
  lightGrey3,
} from '@edulastic/colors'
import styled from 'styled-components'
import { grey, white } from 'color-name'

export const StyledTable = styled(Table)`
  // when u change this u have to change "StyledTable" in "src/client/common/styled.js" to make every css in sync
  // DO NOT ADD USE CASE SPECIFIC CSS HERE, ONLY ADD GENERIC CSS
  // Import this and add USE CASE SPECIFIC CSS

  .ant-table-body {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-collapse: collapse;
      }
    }
  }

  .ant-table-scroll {
    .ant-table-header {
      // mozilla
      scrollbar-color: transparent transparent;
    }
    .ant-table-header::-webkit-scrollbar {
      background-color: transparent;
    }
    overflow: auto;
    table {
      border-collapse: collapse;
      border-spacing: 0px 10px;
      thead {
        tr {
          background: transparent;
          text-transform: uppercase;
          .normal-text {
            text-transform: none;
          }

          th {
            padding: 8px;
            text-align: center;
            font-weight: bold;
            font-size: 10px;
            border: 0px;
            color: #aaafb5;
            background: ${white};

            .ant-table-column-sorters {
              /* display: inline; */
            }

            &.ant-table-column-sort {
              background: ${white} !important;
            }

            .ant-table-header-column {
              .ant-table-column-sorters {
                right: 3px;
                &:hover::before {
                  background: ${white};
                }
                .ant-table-column-sorter-inner {
                  height: 18px;
                }
              }
            }
          }
        }
      }

      tbody {
        tr {
          background-color: ${white};
          font-weight: bold;
          border-bottom: 1px solid ${fadedGrey};

          td {
            &.rawscore,
            &.assessmentDate {
              white-space: nowrap;
            }
          }

          td {
            padding: 10px 8px;
            text-align: center;
            font-size: 11px;
            border-bottom: 1px solid #f3f3f3;
            color: ${greyThemeDark1};

            &:nth-last-child(-n + ${(props) => props.colouredCellsNo || 0}) {
              padding: 0px;
              div {
                height: 100%;
                width: 100%;
                padding: 10px;
              }
            }

            @media (min-width: ${extraDesktopWidthMax}) {
              font-size: 14px;
            }
          }
          &.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
            > td,
          &.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
            > td,
          &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
            > td,
          &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
            > td {
            background-color: ${lightGrey3};
          }
        }
      }
    }
  }

  .ant-table-body::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  .ant-table-body::-webkit-scrollbar-track {
    background: ${grey};
  }

  .ant-table-body::-webkit-scrollbar-thumb {
    background: ${darkGrey};
  }

  .ant-pagination.ant-table-pagination {
    .ant-pagination-disabled {
      display: none;
    }
  }
`
