import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Radio } from 'antd'
import { segmentApi } from '@edulastic/api'
import {
  AI_QUESTION_UPDATE_TYPES,
  QUESTION_ADJUST_DIFFICULTY_OPTIONS,
} from '../constants/form'
import AIContentView from '../common/AIContentView'
import {
  enhanceAIQuestionAction,
  getAIEnhanceQuestionState,
} from '../ducks/enhanceQuestion'
import { titleToAIQuestionType } from '../utils/form'
import QuestionView from '../common/QuestionView'
import {
  StyledAIEnhanceQuestionContainer,
  StyledOriginalQuestionText,
  StyledRadioButtonContainer,
  StyledRadioGroupContainer,
} from '../common/styled'
import AIEnhanceQuestionFooterAction from './AIEnhanceQuestionFooterAction'
import {
  getAIEnhanceExistingQuestionPayloadData,
  transformPAQuestionToAIQuestion,
} from '../utils/common'

export const AIQuestionAdjustDifficulty = ({
  aiEnhanceQuestionState,
  enhanceAIQuestion,
  data: { question } = {},
  onFinish,
  onUseOldQuestion,
  onAIResponseFeedbackSubmit,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    QUESTION_ADJUST_DIFFICULTY_OPTIONS[0].value
  )

  const { apiStatus, result } = aiEnhanceQuestionState || {}
  const { updatedQuestionMetaData: { updatedQuestionDifficultyLevel } = {} } =
    result || {}
  const { title = '' } = question || {}
  const questionType = titleToAIQuestionType(title)

  const onGenerate = (reGenerate = false) => {
    enhanceAIQuestion({
      type: AI_QUESTION_UPDATE_TYPES.ADJUST_DIFFICULTY,
      questionType,
      existingQuestion: getAIEnhanceExistingQuestionPayloadData({
        question,
        questionType,
      }),
      options: { difficultyLevel: selectedOption },
    })
    segmentApi.genericEventTrack(
      `TestReview:AI${reGenerate === true ? 'Regenerate' : 'Generate'}Question`,
      {
        type: AI_QUESTION_UPDATE_TYPES.ADJUST_DIFFICULTY,
        questionType,
        adjustDifficultyLevelTo: selectedOption,
      }
    )
  }

  const handleUseRevisedQuestion = () => {
    onFinish({
      question: {
        ...(result?.updatedQuestion || {}),
        difficultLevel: updatedQuestionDifficultyLevel,
      },
      questionType,
      selectedOption,
    })
  }

  return (
    <>
      <StyledAIEnhanceQuestionContainer>
        <StyledOriginalQuestionText>
          Original Question
        </StyledOriginalQuestionText>
        <QuestionView
          question={transformPAQuestionToAIQuestion(question)}
          questionType={questionType}
        />
        <b>How difficult do you want to make it?</b>
        <StyledRadioGroupContainer>
          <Radio.Group
            onChange={({ target }) => setSelectedOption(target.value)}
            value={selectedOption}
          >
            {QUESTION_ADJUST_DIFFICULTY_OPTIONS.map(({ value, label }) => {
              return (
                <StyledRadioButtonContainer>
                  <span data-cy={value}>
                    <Radio value={value}>{label}</Radio>
                  </span>
                  <br />
                </StyledRadioButtonContainer>
              )
            })}
          </Radio.Group>
        </StyledRadioGroupContainer>
        {apiStatus && (
          <AIContentView
            onRegenerate={() => onGenerate(true)}
            title=""
            loadingTitle="Generating Question"
            content={
              <QuestionView
                question={result?.updatedQuestion || {}}
                questionType={questionType}
              />
            }
            status={apiStatus}
            showAIResponseFeedback
            onAIResponseFeedbackSubmit={onAIResponseFeedbackSubmit}
          />
        )}
      </StyledAIEnhanceQuestionContainer>
      <AIEnhanceQuestionFooterAction
        status={apiStatus}
        onGenerate={onGenerate}
        onFinish={handleUseRevisedQuestion}
        onUseOldQuestion={() => onUseOldQuestion({ selectedOption })}
      />
    </>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      aiEnhanceQuestionState: getAIEnhanceQuestionState(state),
    }),
    {
      enhanceAIQuestion: enhanceAIQuestionAction,
    }
  )
)

export default enhance(AIQuestionAdjustDifficulty)
