import styled from 'styled-components'
import { Modal } from 'antd'
import { black } from '@edulastic/colors'

export const StyledUnVerifiedUserWarningModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-close {
      svg {
        height: 20px;
        width: 20px;
        fill: #2f4151;
      }
    }
  }
  .ant-modal-body {
    padding: 25px;
  }
  .ant-modal-footer {
    border: none !important;
  }
`
export const StyledModalTitle = styled.p`
  font-family: Open Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: ${black};
`
export const StyledContent = styled.div`
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${black};
`
