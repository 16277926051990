/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconPDLSparkle = (props) => (
  <SVG
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9166 22.7499C11.6159 16.9043 6.93151 12.2195 1.08325 11.9166C6.92908 11.6136 11.6159 6.92889 11.9166 1.08325C12.2172 6.92889 16.9017 11.6136 22.7499 11.9166C16.9041 12.2195 12.2172 16.9067 11.9166 22.7499Z"
      fill="#C5F21F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7287 18.2331C11.4147 19.6044 11.8334 21.1327 11.9166 22.7499C11.9998 21.1331 12.4188 19.6048 13.1053 18.2334C14.2137 16.0189 16.0196 14.2135 18.2349 13.1051C19.6058 12.4193 21.1336 12.0003 22.7499 11.9166C21.1332 11.8328 19.6055 11.4142 18.2347 10.7288C16.0187 9.6208 14.2129 7.81556 13.1047 5.60051C12.4185 4.2291 11.9998 2.70061 11.9166 1.08325C11.8334 2.70055 11.4145 4.229 10.7282 5.60037C9.61977 7.81507 7.814 9.62012 5.59868 10.7282C4.22765 11.414 2.69976 11.8328 1.08325 11.9166C2.70007 12.0003 4.22794 12.419 5.59882 13.1045C7.81478 14.2126 9.62054 16.018 10.7287 18.2331ZM7.87958 11.9162C9.49337 12.9619 10.8715 14.3397 11.9172 15.9531C12.963 14.3404 14.3408 12.9628 15.954 11.9172C14.3402 10.8717 12.9621 9.49392 11.9163 7.88057C10.8706 9.49342 9.49274 10.8708 7.87958 11.9162Z"
      fill="#151D34"
    />
    <path
      d="M19.4998 23.8332C19.3796 21.4949 17.5058 19.621 15.1665 19.4998C17.5048 19.3787 19.3796 17.5048 19.4998 15.1665C19.6201 17.5048 21.4939 19.3787 23.8332 19.4998C21.4948 19.621 19.6201 21.4959 19.4998 23.8332Z"
      fill="#C5F21F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.172 20.8419C18.9441 21.6171 19.4399 22.6674 19.4998 23.8332C19.5598 22.6679 20.0558 21.6176 20.8279 20.8423C20.8328 20.8374 20.8376 20.8326 20.8425 20.8277C21.6177 20.0561 22.6679 19.5602 23.8332 19.4998C22.6674 19.4394 21.6172 18.9438 20.8421 18.1723C20.8373 18.1675 20.8325 18.1627 20.8277 18.1579C20.0556 17.3827 19.5598 16.3323 19.4998 15.1665C19.4399 16.3321 18.944 17.3823 18.172 18.1575C18.1671 18.1624 18.1622 18.1673 18.1573 18.1722C17.3821 18.9437 16.3319 19.4394 15.1665 19.4998C16.3324 19.5602 17.3826 20.056 18.1577 20.8275C18.1625 20.8323 18.1673 20.8371 18.172 20.8419Z"
      fill="#151D34"
    />
  </SVG>
)

export default withIconStyles(IconPDLSparkle)
