import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Form, Radio, Typography } from 'antd'
import { segmentApi } from '@edulastic/api'
import {
  AI_QUESTION_UPDATE_TYPES,
  QUESTION_REVISE_OPTIONS,
} from '../constants/form'
import AIContentView from '../common/AIContentView'
import {
  enhanceAIQuestionAction,
  getAIEnhanceQuestionState,
} from '../ducks/enhanceQuestion'
import QuestionView from '../common/QuestionView'
import { titleToAIQuestionType } from '../utils/form'
import {
  StyledAIEnhanceQuestionContainer,
  StyledForm,
  StyledOriginalQuestionText,
  StyledRadioButtonContainer,
  StyledRadioGroupContainer,
} from '../common/styled'
import AIEnhanceQuestionFooterAction from './AIEnhanceQuestionFooterAction'
import {
  getAIEnhanceExistingQuestionPayloadData,
  transformPAQuestionToAIQuestion,
} from '../utils/common'
import { StyledInputArea } from '../../../assessment/widgets/Text/styled/StyledInput'

export const AIQuestionRevise = ({
  aiEnhanceQuestionState,
  enhanceAIQuestion,
  data: { question = {} } = {},
  onFinish,
  onUseOldQuestion,
  onAIResponseFeedbackSubmit,
  form,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    QUESTION_REVISE_OPTIONS[0].value
  )
  const [customText, setCustomText] = useState()

  const { apiStatus, result } = aiEnhanceQuestionState || {}
  const { title = '' } = question || {}
  const questionType = titleToAIQuestionType(title)

  const { getFieldDecorator } = form

  const onGenerate = (reGenerate = false) => {
    form.validateFieldsAndScroll((err) => {
      if (!err) {
        const revisePrompt =
          selectedOption === 'custom'
            ? customText
            : QUESTION_REVISE_OPTIONS.find(
                ({ value }) => value === selectedOption
              )?.infoText

        enhanceAIQuestion({
          type: AI_QUESTION_UPDATE_TYPES.REVISE,
          questionType,
          existingQuestion: getAIEnhanceExistingQuestionPayloadData({
            question,
            questionType,
          }),
          options: {
            revisePrompt,
          },
        })

        segmentApi.genericEventTrack(
          `TestReview:AI${
            reGenerate === true ? 'Regenerate' : 'Generate'
          }Question': '`,
          {
            type: AI_QUESTION_UPDATE_TYPES.REVISE,
            questionType,
            revisePromptTo:
              selectedOption === 'custom'
                ? 'customText'
                : QUESTION_REVISE_OPTIONS.find(
                    ({ value }) => value === selectedOption
                  )?.value,
          }
        )
      }
    })
  }

  const handleUseRevisedQuestion = () => {
    onFinish({
      question: result?.updatedQuestion || {},
      questionType,
      selectedOption,
    })
  }

  return (
    <>
      <StyledAIEnhanceQuestionContainer>
        <StyledOriginalQuestionText>
          Original Question
        </StyledOriginalQuestionText>
        <QuestionView
          question={transformPAQuestionToAIQuestion(question)}
          questionType={questionType}
        />
        <b>What do you want to revise in the question?</b>
        <StyledRadioGroupContainer>
          <Radio.Group
            onChange={({ target }) => setSelectedOption(target.value)}
            value={selectedOption}
          >
            {QUESTION_REVISE_OPTIONS.map(({ value, label, infoText }) => {
              return (
                <StyledRadioButtonContainer>
                  <span data-cy={value}>
                    <Radio value={value}>{label}</Radio>
                  </span>
                  <br />
                  {infoText && (
                    <Typography.Text
                      type="secondary"
                      style={{ marginLeft: '24px', display: 'block' }}
                    >
                      {infoText}
                    </Typography.Text>
                  )}
                </StyledRadioButtonContainer>
              )
            })}
          </Radio.Group>
          {selectedOption === 'custom' && (
            <StyledForm>
              <Form.Item>
                {getFieldDecorator('customText', {
                  rules: [
                    {
                      required: true,
                      message: 'This field is required!',
                    },
                  ],
                })(
                  <StyledInputArea
                    rows={3}
                    value={customText}
                    onChange={({ target }) => setCustomText(target.value)}
                    placeholder="For eg: Make it self explanatory"
                    style={{ resize: 'none' }}
                    data-cy="question-revise-textarea"
                  />
                )}
              </Form.Item>
            </StyledForm>
          )}
        </StyledRadioGroupContainer>
        {apiStatus && (
          <AIContentView
            onRegenerate={() => onGenerate(true)}
            title=""
            loadingTitle="Generating Question"
            content={
              <QuestionView
                question={result?.updatedQuestion || {}}
                questionType={questionType}
              />
            }
            status={apiStatus}
            showAIResponseFeedback
            onAIResponseFeedbackSubmit={onAIResponseFeedbackSubmit}
          />
        )}
      </StyledAIEnhanceQuestionContainer>
      <AIEnhanceQuestionFooterAction
        status={apiStatus}
        onGenerate={onGenerate}
        onFinish={handleUseRevisedQuestion}
        onUseOldQuestion={() => onUseOldQuestion({ selectedOption })}
      />
    </>
  )
}

const enhance = compose(
  Form.create({ name: 'ai-question-revise' }),
  connect(
    (state) => ({
      aiEnhanceQuestionState: getAIEnhanceQuestionState(state),
    }),
    {
      enhanceAIQuestion: enhanceAIQuestionAction,
    }
  )
)

export default enhance(AIQuestionRevise)
