import { PERIOD_TYPES } from '@edulastic/constants/reportUtils/common'
import { compareByKeys } from '../../common/utils'

export const internalTestTypes = [
  {
    key: 'common assessment',
    title: 'District Common',
  },
  {
    key: 'school common assessment',
    title: 'School Common',
  },
  {
    key: 'assessment',
    title: 'Class Assessment',
  },
]

export const tableFilterTypes = {
  COMPARE_BY: 'compareBy',
  PAGE: 'page',
  PAGE_SIZE: 'pageSize',
  SORT_KEY: 'sortKey',
  SORT_ORDER: 'sortOrder',
  ABOVE_EQUAL_TO_AVG: 'aboveEqualToAvg',
  BELOW_AVG: 'belowAvg',
}

export const TABLE_PAGE_SIZE = 50

export const trendPeriodDateFormat = `MMM'YY`
export const trendPeriodPrefix = 'vs '

export const compareByFieldKeys = {
  [compareByKeys.SCHOOL]: 'schoolId',
  [compareByKeys.TEACHER]: 'teacherId',
  [compareByKeys.CLASS]: 'groupId',
  [compareByKeys.STUDENT]: 'studentId',
  [compareByKeys.RACE]: compareByKeys.RACE,
  [compareByKeys.GENDER]: compareByKeys.GENDER,
  [compareByKeys.FRL_STATUS]: compareByKeys.FRL_STATUS,
  [compareByKeys.ELL_STATUS]: compareByKeys.ELL_STATUS,
  [compareByKeys.IEP_STATUS]: compareByKeys.IEP_STATUS,
  [compareByKeys.HISPANIC_ETHNICITY]: compareByKeys.HISPANIC_ETHNICITY,
}

export const compareByOptions = [
  { key: compareByKeys.SCHOOL, title: 'School', hiddenFromRole: ['teacher'] },
  { key: compareByKeys.TEACHER, title: 'Teacher', hiddenFromRole: ['teacher'] },
  { key: compareByKeys.CLASS, title: 'Class' },
  {
    key: compareByKeys.STUDENT,
    title: 'Student',
  },
  { key: compareByKeys.RACE, title: 'Race' },
  { key: compareByKeys.GENDER, title: 'Gender' },
  { key: compareByKeys.FRL_STATUS, title: 'FRL Status' },
  { key: compareByKeys.ELL_STATUS, title: 'ELL Status' },
  { key: compareByKeys.IEP_STATUS, title: 'IEP Status' },
  { key: compareByKeys.HISPANIC_ETHNICITY, title: 'Hispanic Ethnicity' },
]

export const academicSummaryFiltersTypes = {
  PERFORMANCE_BAND: 'profileId',
  TEST_TYPE: 'academicTestType',
}

export const INITIAL_FILTERS = {
  reportId: '',
  termId: '',
  testGrades: '',
  testSubjects: '',
  assessmentTypes: '',
  testIds: '',
  schoolIds: '',
  teacherIds: '',
  grades: '',
  subjects: '',
  courseId: 'All',
  classIds: '',
  groupIds: '',
  assignedBy: 'anyone',
  race: 'all',
  gender: 'all',
  iepStatus: 'all',
  frlStatus: 'all',
  ellStatus: 'all',
  hispanicEthnicity: 'all',
  periodType: PERIOD_TYPES.TILL_DATE,
  customPeriodStart: undefined,
  customPeriodEnd: undefined,
}

const filterFields = [
  'termId',
  'testGrades',
  'testSubjects',
  'assessmentTypes',
  'schoolIds',
  'teacherIds',
  'grades',
  'subjects',
  'courseId',
  'classIds',
  'groupIds',
  'race',
  'gender',
  'iepStatus',
  'frlStatus',
  'ellStatus',
  'hispanicEthnicity',
  'periodType',
  'customPeriodStart',
  'customPeriodEnd',
]

const detailsExtraFields = [
  ...Object.values(tableFilterTypes),
  ...Object.values(academicSummaryFiltersTypes),
  'districtAvgScore',
]

export const filterDetailsFields = [...filterFields, ...detailsExtraFields]

export const sharedDetailsFields = ['reportId', ...detailsExtraFields]

export const districtAvgDimension = {
  _id: 'districtAvg',
  name: 'Overall',
}
