import {
  linkColor1,
  themeColor,
  white,
  lightGreen10,
  lightBlue10,
  black,
} from '@edulastic/colors'
import { IconLock as LockIcon, IconPlusCircle } from '@edulastic/icons'
import { Button, Typography } from 'antd'
import styled, { css } from 'styled-components'

const { Text } = Typography

export const TitleWrapper = styled.h1`
  font-size: ${(props) => props.theme.header.headerTitleFontSize};
  color: ${(props) => props.theme.header.headerTitleTextColor};
  font-weight: bold;
  line-height: normal;
  margin: 0px;
`

export const IconPlus = styled(IconPlusCircle)`
  margin-right: 0.5rem;
  width: 20px;
  height: 20px;
`

export const IconLock = styled(LockIcon)`
  margin-right: 0.5rem;
  width: 20px;
  height: 20px;
`

export const ButtonText = styled(Text)`
  font-size: 13px;
  padding: 0 10px 0 10px;
`

export const PopoverWrapper = styled.div`
  .ant-popover-content {
    margin-top: -6px;
  }

  .ant-popover-arrow {
    top: 0px !important;
    display: block;
  }
`

export const PopoverTitle = styled.h4`
  color: ${themeColor};
  text-align: left;
  width: 100%;
  font-weight: 700;
`

export const PopoverDetail = styled.p`
  text-align: left;
  margin-bottom: 10px;
  text-align: left;
  font: Regular 13px/18px Open Sans;
  letter-spacing: 0.24px;
  color: ${linkColor1};
`

export const PopoverCancel = styled(Button)`
  background: transparent;
  outline: none;
  border: none;
  color: ${themeColor};
  font-size: 12px;
  font-weight: 600;

  &:hover,
  &:focus {
    color: ${themeColor};
    opacity: 0.9;
  }
`

export const UpgradeBtn = styled(Button)`
  background: ${themeColor};
  color: ${white};
  font-size: 12px;

  &:hover,
  &:focus {
    background: ${themeColor};
    color: ${white};
    opacity: 0.9;
  }
`

export const StyledLink = styled.span`
  font-size: 12px;
  color: #0366d6;
  cursor: pointer;
  text-transform: uppercase;
  margin-right: 20px;
`

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
`

export const PopuAndButtonWrapper = styled.div`
  .ant-popover-inner {
    margin-top: 1px;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 8%);
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-content {
    width: 250px;
  }
`
export const Dropdown = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const SeeAllStyle = css`
  border-top: 1px solid #d8d8d8;
  justify-content: center;
  padding: 15px;
  svg {
    margin-right: 0;
    fill: #1ab395;
  }
  color: ${lightGreen10};
  &:hover {
    color: ${white};
    svg {
      fill: ${white};
    }
  }
`

const TestLibraryStyle = css`
  border-bottom: 1px solid #d8d8d8;
  padding: 15px 15px 15px 22px;
  svg {
    width: 19.5px;
    height: 22.5px;
    margin-right: 15px;
  }
  svg {
    fill: #000;
  }
  &:hover {
    color: ${white};
    svg {
      fill: ${white};
    }
  }
`

const DropDownItemStyle = css`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  color: #000;
  svg {
    margin-right: 8px;
  }
  &:hover {
    background: ${lightBlue10};
    color: ${white};
  }
`

const GoogleFormStyles = css`
  span {
    ${DropDownItemStyle}
  }
`

export const DropdownItem = styled.div`
  ${(props) => (props.innerStyle ? GoogleFormStyles : DropDownItemStyle)}
  ${(props) => props.seeAll && SeeAllStyle}
  ${(props) => props.testLibrary && TestLibraryStyle}
`

export const SeeAll = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 65px;
  justify-content: space-between;
`
export const StyledILPButton = styled(Button)`
  width: 220px;
  height: 38px;
  border-radius: 48px;
  border-width: 2px;
  padding: 0px 10px;
  border: 1.5px solid #151d34 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: #fff9e0;
  }
`
export const StyledILPButtonText = styled.p`
  font-family: Open Sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${black};
  margin-left: 10px;
`
export const StyledILPIconButton = styled(Button)`
  width: 45px;
  height: 36px;
  border-radius: 4px;
  border-width: 1px;
  border: 1px solid #151d34 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #fff9e0;
  }
`
export const StyledILPBetaTag = styled.span`
  font-family: Open Sans;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: #3f85e5;
  border: 1px solid #3f85e5;
  border-radius: 3px;
  position: relative;
  background-color: ${white};
  top: -18px;
  left: -18px;
  padding: 0px 3px;
`
