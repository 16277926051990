import { EduButton } from '@edulastic/common'
import { Divider } from 'antd'
import React from 'react'
import { StyledTranscriptGenerationFailureModal } from '../styled-components/UserTranscriptInput'

const TranscriptGenerationFailureNotification = ({
  visible,
  onCancel,
  onProceed,
}) => {
  return (
    <StyledTranscriptGenerationFailureModal
      visible={visible}
      title="Transcript Generation Failed"
      onCancel={onCancel}
      maskClosable={false}
      modalWidth="500px"
      modalHeight="720px"
      centered
      footer={[
        <EduButton onClick={onProceed} data-cy="proceed-manual-transcript">
          PROCEED
        </EduButton>,
      ]}
    >
      <Divider orientation="center" />
      <p>
        We encountered a problem in fetching the video transcript. Please
        manually input the transcript to continue using the Auto Generate
        feature.
      </p>
    </StyledTranscriptGenerationFailureModal>
  )
}

export default TranscriptGenerationFailureNotification
