import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { IconsCreateTest } from '@edulastic/icons'
import { segmentApi } from '@edulastic/api'
import { Row, Col } from 'antd'
import { WidgetWrap } from './styled'
import {
  isGcpsDistrictSelector,
  isPremiumUserSelector,
  isVideoQuizAndAIEnabledSelector,
} from '../../../../../src/selectors/user'
import EduAIQuiz from '../../../../../AssessmentCreate/components/CreateAITest'
import CreateGoogleFormQuiz from '../../../../../AssessmentCreate/components/CreateGoogleFormQuiz'
import { widgetKeys } from './constants'

const Widget = ({
  widgetKey,
  segmentEvent,
  icon,
  text,
  link,
  aiTest,
  videoQuiz,
  isVideoQuizAndAIEnabled,
  isGcpsDistrictExcludingDemoUsers,
  isPremiumsUser,
  history,
}) => {
  const Icon = IconsCreateTest[icon]
  const handleClick = () => {
    if (segmentEvent?.name) {
      segmentApi.genericEventTrack(segmentEvent.name, {
        ...(segmentEvent?.data || {}),
      })
    }
    if (link) {
      history.push(link)
    }
  }

  if (aiTest) {
    return (
      <WidgetWrap data-cy="Use AI" onClick={handleClick} innerStyle>
        <EduAIQuiz displayScreen="dashboard" isILPFlow>
          <Icon />
          <p>{text}</p>
        </EduAIQuiz>
      </WidgetWrap>
    )
  }

  if (videoQuiz && !isVideoQuizAndAIEnabled) {
    return null
  }

  // dont show import test for GCPS district excluding demo users
  if (
    isGcpsDistrictExcludingDemoUsers &&
    widgetKey === widgetKeys.IMPORT_TEST
  ) {
    return null
  }

  if (widgetKey === widgetKeys.GOOGLE_FORM_TEST) {
    // only show google form test for GCPS district excluding demo users
    return isGcpsDistrictExcludingDemoUsers && isPremiumsUser ? (
      <WidgetWrap data-cy="Use Google Form" onClick={handleClick}>
        <CreateGoogleFormQuiz displayScreen="dashboard">
          <Row type="flex">
            <Col>
              <Icon />
            </Col>
            <Col>
              <p>{text}</p>
            </Col>
          </Row>
        </CreateGoogleFormQuiz>
      </WidgetWrap>
    ) : null
  }

  return (
    <WidgetWrap data-cy={text} onClick={handleClick}>
      <Icon />
      <p>{text}</p>
    </WidgetWrap>
  )
}

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      isVideoQuizAndAIEnabled: isVideoQuizAndAIEnabledSelector(state),
      isGcpsDistrictExcludingDemoUsers: isGcpsDistrictSelector(state, true),
      isPremiumsUser: isPremiumUserSelector(state),
    }),
    null
  )
)

export default enhance(Widget)
