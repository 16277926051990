import React from 'react'
import {
  accessibilityColors,
  white,
  greyThemeDark2,
  themeColor,
} from '@edulastic/colors'
import {
  EduButton,
  EduIf,
  LikeIconStyled,
  notification,
} from '@edulastic/common'
import {
  roleuser,
  collections as collectionsConstant,
  test as testConstants,
} from '@edulastic/constants'
import TestsApi from '@edulastic/api/src/tests'
import {
  IconCopy,
  IconDescription,
  IconHeart,
  IconUsers,
  IconTrashAlt,
  IconWorldWide,
  IconEye,
  IconPencilEdit,
  IconAssignment,
  IconDynamic,
  IconShare,
  IconClose,
} from '@edulastic/icons'
import { Icon, Select, Tooltip, Col, Row, Spin } from 'antd'
import { MdInfoOutline } from 'react-icons/md'
import { find, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import { SHARE_ENTITY_TYPE } from '@edulastic/constants/const/common'
import { sanitizeImgUrl } from '@edulastic/common/src/utils/html'
import { StyledSelect } from '../../../../common/styled'
import FeaturesSwitch from '../../../../features/components/FeaturesSwitch'
import { TooltipStyled } from '../../../Dashboard/components/Showcase/components/Myclasses/components/CardAssignmentContent/styled'
import Tags from '../../../src/components/common/Tags'
import {
  getCollectionsSelector,
  getUserIdSelector,
  getUserRole,
  isPublisherUserSelector,
  getWritableCollectionsSelector,
  getInterestedCurriculumsSelector,
  getIsCurator,
} from '../../../src/selectors/user'
import {
  AssessmentName,
  AssessmentNameLabel,
  ButtonContainer,
  Description,
  DescriptionLabel,
  Footer,
  FooterIcon,
  GradeConatiner,
  GradeLabel,
  IconText,
  Image,
  ListCell,
  ListHeader,
  ListHeaderCell,
  ListRow,
  ModalColumn,
  ModalContainer,
  ModalTitle,
  SammaryMark,
  SubjectLabel,
  SummaryCard,
  SummaryCardContainer,
  SummaryCardLabel,
  SummaryCardValue,
  SummaryContainer,
  SummaryList,
  SummaryTitle,
  TagsConatiner,
  TagsLabel,
  TestTitleWrapper,
  DynamicIconWrapper,
  ModalHeader,
  CloseButton,
  RightButtonContainer,
  StyledEduButtonContainer,
  SubHeaderTags,
  ButtonHeader,
  whiteTooltipProps,
  TooltipTitleContainer,
  StyledIconClose,
  RejectButton,
} from './styled'
import {
  accessLabel,
  contentSharing,
  testStatusConstants,
  tooltipText,
} from './utils'
import {
  allowContentEditCheck,
  isContentOfCollectionEditable,
} from '../../../src/utils/permissionCheck'
import {
  getInterestedStandards,
  hasUserGotAccessToPremiumItem,
} from '../../../dataUtils'
import AuthorCompleteSignupButton from '../../../../common/components/AuthorCompleteSignupButton'
import ShareModal from '../../../src/components/common/ShareModal'
import CombineTestButton from '../Item/CombineTestButton'
import IconLink from '../../../../assessment/widgets/EssayRichText/icons/IconLink'
import {
  getUserListSelector,
  receiveSharedWithListAction,
} from '../../../TestPage/ducks'
import TestGroupSummary from './TestGroupSummary'
import AdaptiveTestSummaryIcon from './AdpativeTestSummaryIcon'

const { nonPremiumCollectionsToShareContent } = collectionsConstant
const { statusConstants } = testConstants

const SHARE_MODAL_TRIGGERED_FROM = {
  origin: 'test_library',
  trigger: 'wizzard',
}

const CLONE_ACCESS_LEVELS = [contentSharing.EDIT, contentSharing.VIEW]

const demoActionUnavailable = 'This feature is not available in demo account.'

const CloneTestToolip = () => (
  <TooltipTitleContainer>
    <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
      Cloning this test will create a copy with full edit rights. Any updates
      you make to the cloned test will not affect the original test or its
      assignments.
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>Note:</span> If the original test is
      assigned across multiple teachers&apos; classes as a common assignment,
      student scores from the original and cloned tests won&apos;t appear
      together in admin reports, which may cause confusion for administrators.
    </div>
  </TooltipTitleContainer>
)

class ViewModal extends React.Component {
  static propTypes = {
    isShow: PropTypes.bool.isRequired,
    assign: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDuplicate: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    isPlaylist: PropTypes.bool.isRequired,
    onDelete: PropTypes.func,
    onReject: PropTypes.func.isRequired,
    onApprove: PropTypes.func.isRequired,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    interestedCurriculums: PropTypes.array,
    windowWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    userId: PropTypes.string,
    collections: PropTypes.array,
    allowDuplicate: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    interestedCurriculums: [],
    userId: '',
    collections: [],
    onDelete: () => {},
  }

  state = {
    editedCollections: null,
    summary: null,
    summaryLoading: false,
    showShareModal: false,
    copied: false,
    accessLevel: undefined,
  }

  modalRef = React.createRef()

  onCollectionsChange = (value, options) => {
    const newCollections = options.map((o) => ({
      _id: o.props.value,
      name: o.props.title,
    }))
    this.setState({ editedCollections: newCollections })
  }

  handleModalClose = () => {
    const { close } = this.props
    close()
  }

  componentDidMount() {
    const { item, userId, allowDuplicate } = this.props
    if (!isEmpty(userId) && (item?.cw || item?.sharedType === 'PUBLIC')) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ summaryLoading: true, summary: null })
      TestsApi.getSummary(item._id)
        .then((summary) => {
          this.setState({ summary, summaryLoading: false })
        })
        .catch((e) => {
          console.warn('error loading tests', e)
          this.setState({ summaryLoading: false })
        })
    }
    if (!isEmpty(userId)) {
      if (item?.authors && item?.authors?.some((o) => o._id === userId)) {
        this.setState({
          accessLevel: 'EDIT',
        })
      } else {
        TestsApi.getAccessLevel(item._id)
          .then(({ accessLevel }) => {
            if (accessLevel === contentSharing.VIEW && !allowDuplicate) {
              this.setState({ accessLevel: contentSharing.ASSIGN })
            } else {
              this.setState({ accessLevel })
            }
          })
          .catch((e) => {
            console.warn('error loading access', e)
          })
      }
    }
  }

  onShareModalChange = () => {
    this.setState((prevState) => ({
      showShareModal: !prevState.showShareModal,
    }))
  }

  handleOwnerCopy = () => {
    const { sharedUsersList } = this.props
    if (!sharedUsersList.length) {
      notification({
        msg: 'Set up the sharing rule for users to view the test.',
      })
      this.onShareModalChange()
    }
  }

  handleCopyLink = () => {
    const { item, userId, getSharedUsers } = this.props
    navigator.clipboard
      .writeText(
        `${window.location.origin}/author/tests/tab/review/id/${item._id}`
      )
      .then(() => {
        if (item?.authors?.some((o) => o._id === userId) && !item.sharedType) {
          getSharedUsers({
            contentId: item._id,
            contentType: SHARE_ENTITY_TYPE.TEST,
            callback: this.handleOwnerCopy,
          })
        }
        this.setState({ copied: true })
        setTimeout(() => this.setState({ copied: false }), 3000)
      })
  }

  render() {
    const {
      isShow,
      item,
      assign,
      isPlaylist,
      onDuplicate,
      onDelete,
      onEdit,
      onReject,
      onApprove,
      status,
      windowWidth,
      userId,
      collections,
      allowDuplicate,
      userRole,
      previewLink,
      modalView = true,
      publicAccess = false,
      isPublisherUser,
      isDynamicTest,
      isSectionAdaptiveTest,
      handleLikeTest,
      isTestLiked,
      collectionName,
      interestedCurriculums,
      writableCollections,
      isDemoPlaygroundUser,
      isCurator,
      i18translate,
    } = this.props
    const { copied, summaryLoading, accessLevel } = this.state
    const {
      title = '',
      description = '',
      tags = [],
      grades = [],
      subjects: _subjects,
      thumbnail = '',
      analytics = [],
      itemGroups = [],
      alignment = [],
      permission,
      _source,
      authors,
      sharedWith,
      collections: _collections = [],
      isDocBased,
      derivedFromPremiumBankId = false,
    } = item
    let { summary = {} } = item
    if (this.state.summary) {
      summary = this.state.summary
    }
    const { editedCollections, showShareModal } = this.state

    const modalStyles = {
      modal: {
        background: white,
        padding: '20px 40px',
        width: windowWidth < 768 ? '100%' : '940px',
        maxWidth: 'unset',
        borderRadius: '5px',
      },
    }

    const gradesMap = {
      TK: 'PreKindergarten',
      K: 'Kindergarten',
      1: 'Grade 1',
      2: 'Grade 2',
      3: 'Grade 3',
      4: 'Grade 4',
      5: 'Grade 5',
      6: 'Grade 6',
      7: 'Grade 7',
      8: 'Grade 8',
      9: 'Grade 9',
      10: 'Grade 10',
      11: 'Grade 11',
      12: 'Grade 12',
      O: 'Other',
    }

    const targetGrades = Array.isArray(grades) ? grades : [grades]
    const selectedGrades = targetGrades
      .map((grade) => gradesMap[grade])
      .filter((g) => g)
    const subjects = _subjects ? _subjects.filter((f) => !!f) : []
    const gradeSubject = { grades: targetGrades, subjects }
    const isEdulasticCurator = userRole === roleuser.EDULASTIC_CURATOR
    const premiumOrgCollections = collections.filter(
      ({ _id }) =>
        !Object.keys(nonPremiumCollectionsToShareContent).includes(_id)
    )
    const testItems = (itemGroups || []).flatMap(
      (itemGroup) => itemGroup.items || []
    )
    const hasPremiumQuestion = !!testItems.find((i) =>
      hasUserGotAccessToPremiumItem(i.collections, premiumOrgCollections)
    )
    const isCollectionContentEditable = isContentOfCollectionEditable(
      _collections,
      writableCollections
    )
    const isDeleteAllowed =
      !!find(authors, (o) => o._id === userId) ||
      (sharedWith?.find((x) => x._id === userId) && permission === 'EDIT') ||
      isEdulasticCurator ||
      isCollectionContentEditable

    const hasCollectionAccess = allowContentEditCheck(
      _collections,
      writableCollections
    )
    const interestedStandards = getInterestedStandards(
      summary,
      alignment,
      interestedCurriculums
    )
    const standards = interestedStandards?.map((x) => x.identifier)
    const owner = authors.some((o) => o._id === userId)
    const isShareVisible =
      (owner || isCurator) && !isEdulasticCurator && !derivedFromPremiumBankId
    const isColletionCurator =
      (hasCollectionAccess && isCurator) ||
      userRole === roleuser.EDULASTIC_CURATOR ||
      isCollectionContentEditable
    const hasClonePermission =
      allowDuplicate && CLONE_ACCESS_LEVELS.includes(accessLevel)
    const testInreviewOrRejected =
      status === testStatusConstants.INREVIEW ||
      status === testStatusConstants.REJECTED
    const DisableTooltip = ({ action }) =>
      accessLevel ? (
        <TooltipTitleContainer>
          {tooltipText.noActionAndPermissionInfo(action, accessLevel)}
        </TooltipTitleContainer>
      ) : null
    const contanier = (
      <>
        <ModalHeader>
          <ModalTitle>
            <Tooltip title={title}>
              <TestTitleWrapper>{title}</TestTitleWrapper>
            </Tooltip>
            <Tooltip title={copied ? 'Copied' : 'Copy Link'}>
              <EduButton
                ml="5px"
                isGhost
                height="32px"
                width="32px"
                onClick={this.handleCopyLink}
                data-cy="copy-link"
                IconBtn
              >
                <IconLink style={{ marginLeft: 0, marginRight: 0 }} />
              </EduButton>
            </Tooltip>
            <EduIf condition={isShareVisible}>
              <Tooltip title="Share">
                <EduButton
                  ml="5px"
                  isGhost
                  height="32px"
                  width="32px"
                  disabled={isDemoPlaygroundUser}
                  title={isDemoPlaygroundUser ? demoActionUnavailable : ''}
                  onClick={this.onShareModalChange}
                  data-cy="share"
                  IconBtn
                >
                  <IconShare />
                </EduButton>
              </Tooltip>
            </EduIf>
          </ModalTitle>
          {modalView && (
            <>
              <RightButtonContainer>
                <CloseButton onClick={this.handleModalClose}>
                  <IconClose
                    data-cy="closeTestPopUp"
                    height="18px"
                    width="18px"
                  />
                </CloseButton>
              </RightButtonContainer>
            </>
          )}
        </ModalHeader>
        <div style={{ margin: '10px 0 30px 0' }}>
          <SubHeaderTags status={status} noMargin data-cy="testCardPopUpStatus">
            {status}
          </SubHeaderTags>
          {accessLevel && (
            <SubHeaderTags noTransform data-cy="testCardPopUpAccessLevel">
              {isColletionCurator ? accessLabel.EDIT : accessLabel[accessLevel]}
            </SubHeaderTags>
          )}
        </div>
        <ModalContainer>
          <ModalColumn data-cy="modalColumn">
            <Image src={sanitizeImgUrl(thumbnail)} alt="thumbnail" />
          </ModalColumn>

          <ModalColumn justify="center" ref={this.modalRef}>
            {!publicAccess && (
              <ButtonHeader>Test Authoring Actions</ButtonHeader>
            )}
            {!publicAccess && (
              <ButtonContainer>
                {hasCollectionAccess && testInreviewOrRejected ? (
                  <FeaturesSwitch
                    inputFeatures="isCurator"
                    actionOnInaccessible="hidden"
                  >
                    <EduButton
                      height="40px"
                      data-cy="approve-button"
                      onClick={() => {
                        onApprove(
                          editedCollections !== null
                            ? editedCollections
                            : _collections
                        )
                      }}
                      ml="0px"
                      mr="5px"
                    >
                      <Icon type="check" style={{ margin: 0 }} />
                      <span style={{ margin: 0 }}>Approve</span>
                    </EduButton>
                  </FeaturesSwitch>
                ) : null}
                {status === testStatusConstants.INREVIEW &&
                hasCollectionAccess ? (
                  <FeaturesSwitch
                    inputFeatures="isCurator"
                    actionOnInaccessible="hidden"
                  >
                    <RejectButton
                      isGhost
                      noHover
                      height="40px"
                      style={{
                        color: 'red',
                        borderColor: 'red',
                      }}
                      data-cy="reject-button"
                      onClick={() => {
                        onReject()
                      }}
                      ml="0px"
                      mr="5px"
                    >
                      <StyledIconClose height="14px" width="14px" />
                      <span>REJECT</span>
                    </RejectButton>
                  </FeaturesSwitch>
                ) : null}
                <EduButton
                  isGhost={testInreviewOrRejected}
                  height="40px"
                  style={{ justifyContent: 'center' }}
                  data-cy="details-button"
                  onClick={() => {
                    onEdit()
                  }}
                  ml="0px"
                  mr="5px"
                >
                  <IconDescription />
                  <span>REVIEW</span>
                </EduButton>
                {!isEdulasticCurator &&
                  status !== testStatusConstants.PUBLISHED &&
                  hasCollectionAccess && (
                    <TooltipStyled
                      {...whiteTooltipProps}
                      title={
                        permission === 'VIEW' ? (
                          <DisableTooltip action="Edit" />
                        ) : null
                      }
                    >
                      <span>
                        <EduButton
                          isGhost
                          height="40px"
                          justifyContent="center"
                          data-cy="testPopUpEditButton"
                          onClick={onEdit}
                          disabled={permission === 'VIEW'}
                          ml="0px"
                          mr="5px"
                        >
                          <IconPencilEdit height={14} />
                          <span>EDIT</span>
                        </EduButton>
                      </span>
                    </TooltipStyled>
                  )}
                <EduIf
                  condition={
                    !derivedFromPremiumBankId &&
                    !isEdulasticCurator &&
                    !(status === testStatusConstants.INREVIEW && isCurator)
                  }
                >
                  <TooltipStyled
                    {...whiteTooltipProps}
                    title={
                      isDemoPlaygroundUser ? (
                        demoActionUnavailable
                      ) : hasClonePermission ? (
                        <CloneTestToolip />
                      ) : (
                        <TooltipTitleContainer>
                          {!allowDuplicate
                            ? tooltipText.bucketCloneDisabled
                            : tooltipText.noActionAndPermissionInfo(
                                'Clone',
                                accessLevel
                              )}
                        </TooltipTitleContainer>
                      )
                    }
                  >
                    <span>
                      <EduButton
                        isGhost
                        height="40px"
                        style={{ justifyContent: 'center' }}
                        data-cy="duplicate-button"
                        disabled={isDemoPlaygroundUser || !hasClonePermission}
                        onClick={onDuplicate}
                        ml="0px"
                        mr="5px"
                      >
                        <IconCopy />
                        <span style={{ marginRight: 0 }}>
                          CLONE
                          <MdInfoOutline
                            fill={themeColor}
                            size="14px"
                            style={{ verticalAlign: 'middle', marginRight: 0 }}
                          />
                        </span>
                      </EduButton>
                    </span>
                  </TooltipStyled>
                </EduIf>
                <EduIf
                  condition={
                    !(
                      hasCollectionAccess &&
                      testInreviewOrRejected &&
                      isCurator
                    )
                  }
                >
                  <TooltipStyled
                    {...whiteTooltipProps}
                    title={
                      !isDeleteAllowed ? (
                        <TooltipTitleContainer>
                          {tooltipText.bucketDeleteDisabled}
                        </TooltipTitleContainer>
                      ) : null
                    }
                  >
                    <span>
                      <EduButton
                        isGhost
                        height="40px"
                        style={{ justifyContent: 'center' }}
                        data-cy="delete-button"
                        onClick={() => onDelete()}
                        disabled={!isDeleteAllowed}
                        ml="0px"
                      >
                        <IconTrashAlt />
                      </EduButton>
                    </span>
                  </TooltipStyled>
                </EduIf>
              </ButtonContainer>
            )}
            {!publicAccess &&
              testInreviewOrRejected &&
              hasCollectionAccess &&
              isCurator && (
                <ButtonContainer>
                  {status === testStatusConstants.INREVIEW &&
                    !derivedFromPremiumBankId &&
                    !isEdulasticCurator && (
                      <TooltipStyled
                        {...whiteTooltipProps}
                        title={
                          isDemoPlaygroundUser ? (
                            demoActionUnavailable
                          ) : hasClonePermission ? (
                            <CloneTestToolip />
                          ) : (
                            <TooltipTitleContainer>
                              {!allowDuplicate
                                ? tooltipText.bucketCloneDisabled
                                : tooltipText.noActionAndPermissionInfo(
                                    'Clone',
                                    accessLevel
                                  )}
                            </TooltipTitleContainer>
                          )
                        }
                      >
                        <span>
                          <EduButton
                            isGhost
                            height="40px"
                            style={{ justifyContent: 'center' }}
                            data-cy="duplicate-button"
                            disabled={
                              isDemoPlaygroundUser || !hasClonePermission
                            }
                            onClick={onDuplicate}
                            ml="0px"
                            mr="5px"
                          >
                            <IconCopy />
                            <span style={{ marginRight: 0 }}>
                              CLONE
                              <MdInfoOutline
                                fill={themeColor}
                                size="14px"
                                style={{
                                  verticalAlign: 'middle',
                                  marginRight: 0,
                                }}
                              />
                            </span>
                          </EduButton>
                        </span>
                      </TooltipStyled>
                    )}
                  {
                    <TooltipStyled
                      {...whiteTooltipProps}
                      title={
                        !isDeleteAllowed ? (
                          <TooltipTitleContainer>
                            {tooltipText.bucketDeleteDisabled}
                          </TooltipTitleContainer>
                        ) : null
                      }
                    >
                      <span>
                        <EduButton
                          isGhost
                          height="40px"
                          style={{ justifyContent: 'center' }}
                          data-cy="delete-button"
                          onClick={() => onDelete()}
                          disabled={!isDeleteAllowed}
                          ml="0px"
                        >
                          <IconTrashAlt />
                        </EduButton>
                      </span>
                    </TooltipStyled>
                  }
                </ButtonContainer>
              )}
            {!publicAccess && <ButtonHeader>Test Actions</ButtonHeader>}
            <ButtonContainer
              className={publicAccess ? 'public-access-btn-wrapper' : ''}
            >
              <Tooltip
                title={
                  isSectionAdaptiveTest
                    ? i18translate('notSupportedForSectionsAdaptiveTest.info')
                    : null
                }
              >
                <StyledEduButtonContainer>
                  <EduButton
                    height="40px"
                    isGhost
                    justifyContent="center"
                    data-cy="preview-button"
                    onClick={previewLink}
                    disabled={isSectionAdaptiveTest}
                    ml="0px"
                  >
                    {!publicAccess && <IconEye />}
                    VIEW AS STUDENT
                  </EduButton>
                </StyledEduButtonContainer>
              </Tooltip>
              {!publicAccess && (
                <CombineTestButton
                  testId={item._id}
                  test={{
                    itemGroups: item.itemGroups,
                    testCategory: item.testCategory,
                  }}
                />
              )}
              {(publicAccess ||
                (!isEdulasticCurator && !publicAccess && !isPublisherUser)) && (
                <AuthorCompleteSignupButton
                  renderButton={(handleClick) => (
                    <TooltipStyled
                      {...whiteTooltipProps}
                      title={
                        status === testStatusConstants.PUBLISHED ? null : (
                          <TooltipTitleContainer>
                            {tooltipText.cannotAssignTest}
                          </TooltipTitleContainer>
                        )
                      }
                    >
                      <span>
                        <EduButton
                          isGhost
                          height="40px"
                          justifyContent="center"
                          data-cy="testPopUpAssignButton"
                          onClick={handleClick}
                          disabled={status !== testStatusConstants.PUBLISHED}
                          ml="0px"
                        >
                          <IconAssignment />
                          <span>ASSIGN</span>
                        </EduButton>
                      </span>
                    </TooltipStyled>
                  )}
                  onClick={assign}
                />
              )}
            </ButtonContainer>
            {testInreviewOrRejected ? (
              <FeaturesSwitch
                inputFeatures="isCurator"
                actionOnInaccessible="hidden"
              >
                <ButtonContainer>
                  <StyledSelect
                    mode="multiple"
                    size="medium"
                    style={{ width: '100%' }}
                    value={
                      editedCollections !== null
                        ? editedCollections.map((o) => o._id)
                        : _collections.map((o) => o._id)
                    }
                    filterOption={(input, option) =>
                      option.props.title
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    getPopupContainer={() => this.modalRef.current}
                    onChange={this.onCollectionsChange}
                  >
                    {collections.map(({ _id, name }) => (
                      <Select.Option key={_id} value={_id} title={name}>
                        {name}
                      </Select.Option>
                    ))}
                  </StyledSelect>
                </ButtonContainer>
              </FeaturesSwitch>
            ) : null}
          </ModalColumn>
          <ModalColumn>
            <div>
              <AssessmentNameLabel>Test Name</AssessmentNameLabel>
              {isDynamicTest && (
                <DynamicIconWrapper title="SmartBuild Test. Every student might get different items in assignment">
                  <IconDynamic color={greyThemeDark2} />
                  &nbsp;&nbsp; SMARTBUILD TEST
                </DynamicIconWrapper>
              )}
            </div>
            <AssessmentName data-cy="testcard-name">{title}</AssessmentName>

            <DescriptionLabel data-cy="testcard-description">
              Description
            </DescriptionLabel>
            <Description>{description}</Description>

            <Row gutter={10}>
              <Col span={12}>
                <GradeLabel>Grade</GradeLabel>
                <GradeConatiner data-cy="testcard-grades">
                  {!!selectedGrades.length && (
                    <Tags
                      isGrayTags
                      tags={selectedGrades}
                      show={2}
                      key="grades"
                    />
                  )}
                </GradeConatiner>
              </Col>
              <Col span={12} data-cy="testcard-subject">
                <SubjectLabel>Subject</SubjectLabel>
                {subjects && (
                  <Tags isGrayTags tags={subjects} show={1} key="subjects" />
                )}
              </Col>
            </Row>

            <TagsLabel>Tags</TagsLabel>
            <TagsConatiner data-cy="testcard-tags">
              {tags && <Tags isCustomTags tags={tags} show={2} key="tags" />}
            </TagsConatiner>

            <Footer>
              <FooterIcon>
                <IconWorldWide
                  color={accessibilityColors.testLibrary.footerIconText}
                  width={14}
                  height={14}
                />
                <IconText data-cy="testcard-collection">
                  {collectionName}
                </IconText>
              </FooterIcon>
              <FooterIcon rotate>
                <IconUsers width={14} height={14} />
                {analytics && <IconText>{analytics[0]?.usage || 0} </IconText>}
              </FooterIcon>
              <LikeIconStyled
                isLiked={isTestLiked}
                onClick={handleLikeTest}
                style={{ marginLeft: '10px' }}
              >
                <IconHeart
                  color={
                    isTestLiked
                      ? '#ca481e'
                      : accessibilityColors.testLibrary.footerIconText
                  }
                  width={14}
                  height={14}
                />
                {analytics && <IconText>{analytics[0]?.likes || 0}</IconText>}
              </LikeIconStyled>
            </Footer>
          </ModalColumn>
          <ModalColumn>
            <SummaryContainer>
              <SummaryTitle>
                Summary
                {isSectionAdaptiveTest && <AdaptiveTestSummaryIcon />}
              </SummaryTitle>
              <SummaryCardContainer>
                <SummaryCard data-cy="testcard-total-items">
                  <SummaryCardValue>
                    {isPlaylist
                      ? _source.modules && _source.modules.length
                      : /**
                         * for doc based, we need to consider questions as items
                         */
                        (isDocBased
                          ? summary.totalQuestions
                          : summary.totalItems) || 0}
                  </SummaryCardValue>
                  <SummaryCardLabel>
                    {isDocBased ? 'Questions' : 'Items'}
                  </SummaryCardLabel>
                </SummaryCard>
                <SummaryCard data-cy="testcard-total-points">
                  <SummaryCardValue>{summary.totalPoints}</SummaryCardValue>
                  <SummaryCardLabel>Points</SummaryCardLabel>
                </SummaryCard>
              </SummaryCardContainer>
            </SummaryContainer>
            {summaryLoading ? (
              <Spin />
            ) : (
              <PerfectScrollbar>
                {[
                  testConstants.testCategoryTypes.DYNAMIC_TEST,
                  testConstants.testCategoryTypes.ADAPTIVE_SECTION_TEST,
                ].includes(item?.testCategory) ? (
                  <TestGroupSummary
                    groupSummary={summary?.groupSummary}
                    itemGroups={itemGroups}
                    testCategory={item?.testCategory}
                    testStandards={standards}
                  />
                ) : (
                  <SummaryList>
                    <ListHeader>
                      <ListHeaderCell>SUMMARY</ListHeaderCell>
                      <ListHeaderCell>Qs</ListHeaderCell>
                      <ListHeaderCell>POINTS</ListHeaderCell>
                    </ListHeader>
                    {!!summary?.standards?.length &&
                      summary.standards.map(
                        (data) =>
                          standards.includes(data.identifier) && (
                            <ListRow data-cy={data.identifier}>
                              <ListCell>
                                <SammaryMark>{data.identifier}</SammaryMark>
                              </ListCell>
                              <ListCell>{data.totalQuestions}</ListCell>
                              <ListCell>{data.totalPoints}</ListCell>
                            </ListRow>
                          )
                      )}
                    {summary?.noStandards?.totalQuestions > 0 && (
                      <ListRow>
                        <ListCell>
                          <SammaryMark>No Standard</SammaryMark>
                        </ListCell>
                        <ListCell>
                          {summary.noStandards.totalQuestions}
                        </ListCell>
                        <ListCell>{summary.noStandards.totalPoints}</ListCell>
                      </ListRow>
                    )}
                  </SummaryList>
                )}
              </PerfectScrollbar>
            )}
          </ModalColumn>
          {showShareModal && (
            <ShareModal
              shareLabel="TEST URL"
              isVisible={showShareModal}
              testId={item._id}
              testVersionId={item.versionId}
              hasPremiumQuestion={hasPremiumQuestion}
              isPublished={status === statusConstants.PUBLISHED}
              onClose={this.onShareModalChange}
              gradeSubject={gradeSubject}
              triggeredFrom={SHARE_MODAL_TRIGGERED_FROM}
            />
          )}
        </ModalContainer>
      </>
    )

    if (modalView) {
      return (
        <Modal
          open={isShow}
          onClose={this.handleModalClose}
          styles={modalStyles}
          showCloseIcon={false}
        >
          {contanier}
        </Modal>
      )
    }
    return contanier
  }
}

export default connect(
  (state) => ({
    userId: getUserIdSelector(state),
    collections: getCollectionsSelector(state),
    userRole: getUserRole(state),
    isPublisherUser: isPublisherUserSelector(state),
    interestedCurriculums: getInterestedCurriculumsSelector(state),
    writableCollections: getWritableCollectionsSelector(state),
    isDemoPlaygroundUser: state?.user?.user?.isPlayground,
    isCurator: getIsCurator(state),
    sharedUsersList: getUserListSelector(state),
    testList: state?.testList?.entities,
  }),
  {
    getSharedUsers: receiveSharedWithListAction,
  }
)(ViewModal)
