import React, { useMemo, useState } from 'react'
import { FieldLabel, SelectInputStyled } from '@edulastic/common'
import { Select, Tooltip, TreeSelect } from 'antd'
import { IconClass, IconGroup } from '@edulastic/icons'
import { lightGrey10 } from '@edulastic/colors'
import { flatMap, groupBy, keyBy, sortBy } from 'lodash'
import { CreateNewClassBtn, dropdownStyle } from './ClassSelector'
import {
  ClassGroupStudentOptionContainer,
  ClassNameSelect,
  HeaderButtonsWrapper,
  SelectAll,
  SelectStudentColumn,
  SelectTextInline,
  SpecificStudentSelectorContainer,
  StyledCol,
  StyledRow,
  StyledStudentSelect,
  UnselectAll,
} from './styled'
import { OptionWrapper } from '../../../ManageClass/components/ClassListContainer/styled'
import { getFormattedName } from '../../../Gradebook/transformers'
import DetailsTooltip from '../Container/DetailsTooltip'
import SettingContainer from '../Container/SettingsContainer'
import { setSelectSearchAriaLabel } from '../../../../common/utils/helpers'

const ClassStudentSelector = ({
  tootltipWidth,
  changeField,
  fetchStudents,
  classIds,
  group,
  createClassHandler,
  studentOfSelectedClass,
  unselectAllStudents,
  handleRemoveStudents,
  updateStudents,
  selectAllStudents,
  assignment,
}) => {
  const initialValueForIsSpecificStudent = useMemo(
    () => !!assignment.class?.find((cl) => cl.students),
    [assignment]
  )
  const [isSpecificStudents, toggleIsSpecificStudents] = useState(
    initialValueForIsSpecificStudent
  )
  const [selectedValues, setSelectedValues] = useState(
    flatMap(
      assignment.class?.map((cl) =>
        cl.students ? cl.students.map((st) => `${cl._id}_${st}`) : []
      ) || []
    )
  )

  const handleToggleSpecificStudents = (value) => {
    toggleIsSpecificStudents(value)
    if (!value) unselectAllStudents()
  }

  const groupKeyed = useMemo(() => keyBy(group, '_id'), [group])
  const studentsGroupedByGroupId = useMemo(
    () => groupBy(studentOfSelectedClass, 'groupId'),
    [studentOfSelectedClass]
  )

  const SelectedStudents = Object.keys(studentsGroupedByGroupId).flatMap(
    (groupId) => {
      const groupName = groupKeyed[groupId].name
      const studentRows = (studentsGroupedByGroupId[groupId] || []).map(
        ({ _id, firstName, lastName, middleName }) => {
          const fullName = getFormattedName(firstName, middleName, lastName)
          return {
            title: fullName || 'Anonymous',
            key: `${groupId}_${_id}`,
            value: `${groupId}_${_id}`,
            groupId,
          }
        }
      )
      return [
        {
          title: <SelectTextInline>{groupName}</SelectTextInline>,
          disableCheckbox: true,
          disabled: true,
          value: groupName,
        },
        ...sortBy(studentRows, (x) => x.title),
      ]
    }
  )

  const allIds = SelectedStudents.filter((x) => !x.disabled).map(
    ({ value }) => value
  )

  return (
    <SettingContainer>
      <DetailsTooltip
        width={tootltipWidth}
        title="Class/Group"
        content="Choose one or more classes or groups to receive the test. In the student field below, you can select individuals from these sections if not all students should receive the assignment."
        premium
      />
      <StyledRow gutter={16}>
        <StyledCol span={8}>
          <FieldLabel top={isSpecificStudents ? '39' : '14'}>
            CLASS/GROUP
          </FieldLabel>
        </StyledCol>
        <StyledCol span={16}>
          <ClassGroupStudentOptionContainer>
            <StyledStudentSelect
              onChange={handleToggleSpecificStudents}
              value={isSpecificStudents}
              data-cy="studentSelectDropDown"
            >
              <Select.Option value={false}>All Students</Select.Option>
              <Select.Option value>Specific Students</Select.Option>
            </StyledStudentSelect>
            <ClassNameSelect
              showSearch
              data-cy="selectClass"
              placeholder="Select a class to assign"
              mode="multiple"
              optionFilterProp="children"
              cache="false"
              onChange={changeField('class')}
              onSelect={(classId) => {
                fetchStudents({ classId })
              }}
              filterOption={(input, option) =>
                option?.props?.name
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0
              }
              value={classIds}
              getPopupContainer={(trigger) => trigger.parentNode}
              dropdownStyle={dropdownStyle}
              dropdownRender={(menu) =>
                group?.length > 0 ? (
                  menu
                ) : (
                  <CreateNewClassBtn createClassHandler={createClassHandler} />
                )
              }
              ref={(ref) => {
                setSelectSearchAriaLabel(ref, 'Search Classes')
              }}
            >
              {group.map((data) => (
                <Select.Option
                  data-cy="class"
                  key={data._id}
                  value={data._id}
                  name={data.name}
                >
                  <Tooltip
                    placement="left"
                    title={data.type === 'class' ? 'Class' : 'Group'}
                  >
                    <OptionWrapper>
                      {data.type === 'custom' ? (
                        <IconGroup
                          width={20}
                          height={19}
                          color={lightGrey10}
                          margin="0 10px 0 0"
                        />
                      ) : (
                        <IconClass
                          width={13}
                          height={14}
                          color={lightGrey10}
                          margin="0 13px 0 3px"
                        />
                      )}
                      <span>{data.name}</span>
                    </OptionWrapper>
                  </Tooltip>
                </Select.Option>
              ))}
            </ClassNameSelect>
          </ClassGroupStudentOptionContainer>
          {isSpecificStudents && (
            <SpecificStudentSelectorContainer>
              <SelectStudentColumn
                span={25}
                style={{ paddingLeft: '0px', paddingRight: '0px' }}
              >
                <Tooltip
                  autoAdjustOverflow
                  overlayStyle={{ maxWidth: '100%' }}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  title={
                    !SelectedStudents?.length
                      ? 'Select one or more Class/Group'
                      : ''
                  }
                >
                  <div>
                    <SelectInputStyled
                      as={TreeSelect}
                      placeholder={
                        !SelectedStudents?.length
                          ? 'Select one or more Class/Group'
                          : 'Select a student to assign'
                      }
                      treeCheckable
                      data-cy="selectStudent"
                      dropdownStyle={{ maxHeight: '300px' }}
                      onChange={(ids) => setSelectedValues(ids)}
                      value={selectedValues}
                      maxTagCount={2}
                      dropdownClassName="student-dropdown"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      maxTagPlaceholder={(omittedValues) =>
                        `+ ${omittedValues.length} Students ...`
                      }
                      onSelect={updateStudents}
                      onDeselect={handleRemoveStudents}
                      treeData={[
                        {
                          title: (
                            <HeaderButtonsWrapper>
                              {selectedValues.length ===
                              SelectedStudents.length ? (
                                <SelectAll className="disabled">
                                  Select all
                                </SelectAll>
                              ) : (
                                <SelectAll
                                  data-cy="selectAllButton"
                                  onClick={() => {
                                    setSelectedValues(allIds)
                                    selectAllStudents()
                                  }}
                                >
                                  Select all
                                </SelectAll>
                              )}
                              {selectedValues.length === 0 ? (
                                <UnselectAll className="disabled">
                                  Unselect all
                                </UnselectAll>
                              ) : (
                                <UnselectAll
                                  data-cy="unselectAllButton"
                                  onClick={() => {
                                    setSelectedValues([])
                                    unselectAllStudents()
                                  }}
                                >
                                  Unselect all
                                </UnselectAll>
                              )}
                            </HeaderButtonsWrapper>
                          ),
                          value: 'all',
                          disableCheckbox: true,
                          disabled: true,
                        },
                        ...SelectedStudents,
                      ]}
                    />
                  </div>
                </Tooltip>
              </SelectStudentColumn>
            </SpecificStudentSelectorContainer>
          )}
        </StyledCol>
      </StyledRow>
    </SettingContainer>
  )
}

export default ClassStudentSelector
