import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Popover } from 'antd'
import { segmentApi } from '@edulastic/api'
import {
  IconAITest,
  IconDefaultTest,
  IconGoogleFormTest,
  IconPlusCircle,
  IconSeeAllArrow,
  IconSnapQuizTest,
  IconTestBank,
  IconVideoQuizTest,
  IconSectionAdaptiveTest,
  IconContentConverter,
} from '@edulastic/icons'

import { EduButton } from '@edulastic/common'
import { Dropdown, DropdownItem, SeeAll, PopuAndButtonWrapper } from './styled'

import {
  enableSectionAdaptiveTestSelector,
  getUserFeatures,
  isGcpsDistrictSelector,
  isPremiumUserSelector,
  isVideoQuizAndAIEnabledSelector,
} from '../../../src/selectors/user'
import EduAIQuiz from '../../../AssessmentCreate/components/CreateAITest'
import CreateGoogleFormQuiz from '../../../AssessmentCreate/components/CreateGoogleFormQuiz'
import { CREATE_TEST_SEGMENT_EVENT_NAME } from '../../../AssessmentCreate/components/CreationOptions/constants'

const createTestLinks = {
  TestLibrary: '/author/tests',
  Scratch: '/author/tests/create',
  Adaptive: {
    pathname: '/author/tests/create',
    state: { hasSections: true },
  },
  VideoQuiz: '/author/vqlibrary',
  SnapQuiz: '/author/tests/snapquiz',
  SeeAll: '/author/tests/select',
  ImportTest: '/author/tests/import',
}

const CreateTestButton = ({
  history,
  isVideoQuizAndAIEnabled,
  isPremiumsUser,
  enableSectionAdaptiveTest,
  isGcpsDistrictExcludingDemoUsers,
}) => {
  const [visible, setVisible] = useState(false)

  const handleSegmentEventAndCloseDropdown = (key) => {
    segmentApi.genericEventTrack(`CreateMenu:${key}`, {
      source: 'Dashboard',
    })
    setVisible(false)
  }

  const handleNavigation = (key) => {
    handleSegmentEventAndCloseDropdown(key)
    history.push(createTestLinks[key])
  }

  const content = (
    <Dropdown>
      <DropdownItem
        onClick={() => handleNavigation('TestLibrary')}
        testLibrary
        data-cy="testLibrary"
      >
        <IconTestBank /> Use Test Library
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          handleNavigation('Scratch')
        }}
        data-cy="createTestFromScratch"
      >
        <IconDefaultTest width="32px" height="32px" /> Create From Scratch
      </DropdownItem>
      {isGcpsDistrictExcludingDemoUsers && isPremiumsUser && (
        <DropdownItem data-cy="importFromGoogleFormOnly" innerStyle>
          <CreateGoogleFormQuiz displayScreen="dashboard">
            <IconGoogleFormTest width="32px" height="32px" /> Import Google Form
          </CreateGoogleFormQuiz>
        </DropdownItem>
      )}
      {!isGcpsDistrictExcludingDemoUsers && (
        <DropdownItem
          onClick={() => {
            handleNavigation('ImportTest')
            segmentApi.genericEventTrack(
              CREATE_TEST_SEGMENT_EVENT_NAME.TEST_IMPORT_CLICK,
              {
                source: 'Dashboard - Create Test',
              }
            )
          }}
          data-cy="importFromGoogleForm"
        >
          <IconContentConverter width="32px" height="32px" /> Content Converter
        </DropdownItem>
      )}
      {isVideoQuizAndAIEnabled && (
        <DropdownItem
          onClick={() => {
            handleNavigation('VideoQuiz')
          }}
          data-cy="videoQuiz"
        >
          <IconVideoQuizTest width="32px" height="32px" /> Video Quiz
        </DropdownItem>
      )}
      <DropdownItem
        innerStyle
        onClick={() => handleSegmentEventAndCloseDropdown('UseAI')}
        data-cy="useAI"
      >
        <EduAIQuiz displayScreen="dashboard" isILPFlow>
          <IconAITest width="32px" height="32px" /> Generate using AI
        </EduAIQuiz>
      </DropdownItem>
      {enableSectionAdaptiveTest && (
        <DropdownItem
          onClick={() => {
            handleNavigation('Adaptive')
          }}
          data-cy="adaptiveTest"
        >
          <IconSectionAdaptiveTest width="32px" height="32px" />
          Multi-Section / Adaptive
        </DropdownItem>
      )}
      <DropdownItem
        onClick={() => {
          handleNavigation('SnapQuiz')
        }}
        data-cy="snapQuiz"
      >
        <IconSnapQuizTest width="32px" height="32px" /> PDF to Test (Snap Quiz)
      </DropdownItem>
      <DropdownItem
        seeAll
        onClick={() => {
          handleNavigation('SeeAll')
        }}
        data-cy="seeAll"
      >
        <SeeAll>
          See All <IconSeeAllArrow />
        </SeeAll>
      </DropdownItem>
    </Dropdown>
  )

  return (
    <PopuAndButtonWrapper>
      <Popover
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        trigger="click"
        content={content}
        placement="bottomRight"
        visible={visible}
        onVisibleChange={(newVisible) => setVisible(newVisible)}
      >
        <EduButton
          isBlue
          data-cy="dashboardCreateTestButton"
          style={{ padding: '5px' }}
        >
          <IconPlusCircle />
          CREATE TEST
        </EduButton>
      </Popover>
    </PopuAndButtonWrapper>
  )
}

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      isPremiumsUser: isPremiumUserSelector(state),
      isVideoQuizAndAIEnabled: isVideoQuizAndAIEnabledSelector(state),
      userFeatures: getUserFeatures(state),
      isGcpsDistrictExcludingDemoUsers: isGcpsDistrictSelector(state, true),
      enableSectionAdaptiveTest: enableSectionAdaptiveTestSelector(state),
    }),
    null
  )
)

export default enhance(CreateTestButton)
