import styled from 'styled-components'
import { CustomModalStyled } from '@edulastic/common'

export const StyledModal = styled(CustomModalStyled)`
  .ant-modal-content {
    padding: 25px 30px;
    .ant-modal-header {
      .ant-modal-title {
        font-size: 20px;
      }
    }

    .ant-modal-body {
      padding: 0 0 40px;

      p {
        font-size: 14px;
      }

      h2 {
        font-size: large;
        margin-top: 19px;
        font-weight: 600;
      }

      .ant-divider-horizontal {
        width: 720px;
        height: 1px;
        margin-left: -30px;
      }
    }
  }
`

export const StyledTranscriptGenerationFailureModal = styled(CustomModalStyled)`
  .ant-modal-content {
    padding: 25px 30px;
    .ant-modal-header {
      .ant-modal-title {
        font-size: 20px;
      }
    }

    .ant-modal-body {
      padding: 0 0 10px;

      p {
        font-size: 14px;
      }

      h2 {
        font-size: large;
        margin-top: 19px;
        font-weight: 600;
      }

      .ant-divider-horizontal {
        width: 520px;
        height: 1px;
        margin-left: -30px;
      }
    }
  }
`
