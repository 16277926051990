import { createSlice } from 'redux-starter-kit'
import { RESET_ALL_REPORTS } from '../../../../common/reportsRedux'
import { staticDropDownData } from '../../common/utils'
import { INITIAL_FILTERS } from '../utils/constants'

const reduxNamespaceKey = 'reportDwDashboard'

const initialState = {
  firstLoad: true,

  loadingFiltersData: false,
  filtersData: {},
  filtersTabKey: staticDropDownData.filterSections.STUDENT_FILTERS.key,
  filters: {
    ...INITIAL_FILTERS,
  },
  filterTagsData: {},

  settings: {
    requestFilters: {},
    selectedFilterTagsData: {},
    selectedCompareBy: {},
    academicSummaryFilters: {},
  },
  error: '',
  loadingTableData: false,
  loadingDistrictAverages: false,
  isDistrictAvgScoreChanged: false,
  districtAveragesRequestError: null,
  districtAveragesData: {},
  tableData: {},
  tableDataRequestError: '',
}
const slice = createSlice({
  slice: reduxNamespaceKey,
  initialState: { ...initialState },
  reducers: {
    fetchFiltersDataRequest: (state) => {
      state.loadingFiltersData = true
    },
    fetchFiltersDataRequestSuccess: (state, { payload }) => {
      state.loadingFiltersData = false
      state.filtersData = payload.filtersData
      state.error = ''
    },
    fetchFiltersDataRequestError: (state, { payload }) => {
      state.loadingFiltersData = false
      state.error = payload.error
    },
    setFirstLoad: (state, { payload }) => {
      state.firstLoad = payload
    },
    setFiltersTabKey: (state, { payload }) => {
      state.filtersTabKey = payload
    },
    setFilters: (state, { payload }) => {
      state.filters = { ...payload }
    },
    setFilterTagsData: (state, { payload }) => {
      state.filterTagsData = payload
    },
    resetFiltersData: (state) => {
      state.filtersData = {}
    },
    setSettings: (state, { payload }) => {
      state.settings = payload
    },
    setAcademicSummaryFilters: (state, { payload }) => {
      state.settings.academicSummaryFilters = payload
    },
    setSelectedFilterTagsData: (state, { payload }) => {
      state.settings.selectedFilterTagsData = payload
    },
    setIsDistrictAvgScoreChanged: (state, { payload }) => {
      state.isDistrictAvgScoreChanged = payload
    },
    fetchDashboardTableDataRequest: (state) => {
      state.loadingTableData = true
    },
    fetchDashboardTableDataRequestSuccess: (state, { payload }) => {
      state.loadingTableData = false
      state.tableData = payload.tableData
      state.tableDataRequestError = ''
    },
    fetchDashboardTableDataRequestError: (state, { payload }) => {
      state.loadingTableData = false
      state.tableDataRequestError = payload.error
      state.tableData = {}
    },
    fetchDashboardDistrictAverages: (state) => {
      state.loadingDistrictAverages = true
    },
    fetchDashboardDistrictAverageSuccess: (state, { payload }) => {
      state.loadingDistrictAverages = false
      state.isDistrictAvgScoreChanged = false
      state.districtAveragesData = payload.districtAveragesData
      state.districtAveragesRequestError = false
    },
    fetchDashboardDistrictAverageError: (state) => {
      state.loadingDistrictAverages = false
      state.isDistrictAvgScoreChanged = false
      state.districtAveragesRequestError = true
    },
    resetReport: () => ({ ...initialState }),
  },
  extraReducers: {
    [RESET_ALL_REPORTS]: () => ({ ...initialState }),
  },
})

export const { actions, reducer } = slice
export { reduxNamespaceKey }
