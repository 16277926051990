import React from 'react'
import PropTypes from 'prop-types'
import { SECTION_STATUS } from '@edulastic/constants/const/testActivityStatus'
import { EduElse, EduIf, EduThen } from '@edulastic/common'
import {
  TestSections,
  FlexBox,
  Section,
  SectionProgress,
  SectionContent,
  IconLockStyled,
} from '../styled-components'
import SectionActionButtons from './SectionActionButtons'
import { getAdaptiveTestSectionNames } from '../utils'

const TestSectionsContainer = ({
  itemsToDeliverInGroup,
  preventSectionNavigation,
  handleReviewSection,
  handleStartSection,
  isTestPreviewModal,
  isRedirectedWithQuestionDelivery,
  isSectionAdaptiveTest,
  extraTimeOnTest = 1,
}) => {
  /**
   * ref: EV-41340
   * In case of redirected test with question delivery setting, some sections items can be empty.
   * Such sections cannot be the nextSection.
   */
  // Find first non submitted section
  const nextSection =
    itemsToDeliverInGroup.find((item) =>
      isRedirectedWithQuestionDelivery
        ? item.status !== SECTION_STATUS.SUBMITTED && !!item?.items?.length
        : item.status !== SECTION_STATUS.SUBMITTED
    ) || {}

  return (
    <TestSections>
      {itemsToDeliverInGroup.map((section, index) => {
        const { items, attempted, skipped, status, settings } = section
        let { groupName } = section
        if (isSectionAdaptiveTest) {
          groupName = getAdaptiveTestSectionNames({ groupName, index })
        }
        const isLast = itemsToDeliverInGroup.length == index + 1
        const showLockIcon =
          (nextSection.groupId !== section.groupId &&
            section.status !== SECTION_STATUS.SUBMITTED &&
            preventSectionNavigation) ||
          section.status === SECTION_STATUS.LOCKED
        if (!items.length) {
          return null
        }
        return (
          <Section noBorder={isLast} disabled={showLockIcon}>
            <FlexBox
              style={{ width: '60%', position: 'relative', marginLeft: 20 }}
            >
              {showLockIcon && (
                <div style={{ position: 'absolute', left: -25 }}>
                  <IconLockStyled data-cy={`lockIcon-${index}`} />
                </div>
              )}
              <SectionContent style={{ width: '100%' }}>
                <h4 data-cy={`sectionName-${index}`}>{groupName}</h4>
                <FlexBox
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <EduIf condition={!showLockIcon}>
                    <EduThen>
                      <p data-cy={`questionsCompleted-${index}`}>
                        {attempted}/{items.length} questions completed
                      </p>
                    </EduThen>
                    <EduElse>
                      <EduIf
                        condition={section.status !== SECTION_STATUS.LOCKED}
                      >
                        <EduThen>
                          <p data-cy={`preventSection-${index}`}>
                            Opens after completing{' '}
                            <b>{itemsToDeliverInGroup[index - 1]?.groupName}</b>
                          </p>
                        </EduThen>
                        <EduElse>
                          <p data-cy={`lockSectionMessage-${index}`}>
                            Section is locked. Please wait for your Instructor
                            to unlock it.
                          </p>
                        </EduElse>
                      </EduIf>
                    </EduElse>
                  </EduIf>
                  {extraTimeOnTest > 0 && !!settings?.timeLimit && (
                    <p data-cy={`timeLimit-${index}`}>
                      🕒 {(settings.timeLimit * extraTimeOnTest) / 60000} mins
                    </p>
                  )}
                </FlexBox>
              </SectionContent>
            </FlexBox>
            <SectionProgress>
              <SectionActionButtons
                attempted={attempted}
                skipped={skipped}
                preventSectionNavigation={preventSectionNavigation}
                handleReviewSection={handleReviewSection}
                handleStartSection={handleStartSection}
                showLockIcon={showLockIcon}
                index={index}
                status={status}
                isTestPreviewModal={isTestPreviewModal}
                section={section}
              />
            </SectionProgress>
          </Section>
        )
      })}
    </TestSections>
  )
}

TestSectionsContainer.propTypes = {
  itemsToDeliverInGroup: PropTypes.array.isRequired,
  preventSectionNavigation: PropTypes.bool.isRequired,
  handleStartSection: PropTypes.func.isRequired,
  handleReviewSection: PropTypes.func,
  isTestPreviewModal: PropTypes.bool,
}

TestSectionsContainer.defaultProps = {
  handleReviewSection: () => {},
  isTestPreviewModal: false,
}

export default TestSectionsContainer
