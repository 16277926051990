/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconMagicWandGradientFilled = (props) => (
  <SVG
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_180_1774)">
      <path
        d="M4.53505 3.62826L6.09599 4.48255C6.29638 4.59154 6.3702 4.84115 6.26122 5.04154C6.22255 5.11185 6.1663 5.1681 6.09599 5.20677L4.53505 6.05755C4.46474 6.09623 4.40849 6.15248 4.36981 6.22279L3.51552 7.78373C3.40653 7.98412 3.15692 8.05795 2.95653 7.94896C2.88622 7.91029 2.82997 7.85404 2.7913 7.78373L1.94052 6.22279C1.90184 6.15248 1.8456 6.09623 1.77528 6.05755L0.214345 5.20677C0.0139544 5.09779 -0.0598737 4.84818 0.0491106 4.64779C0.0877825 4.57748 0.144032 4.52123 0.214345 4.48255L1.77528 3.62826C1.8456 3.58959 1.90184 3.53334 1.94052 3.46302L2.79481 1.9056C2.9038 1.70521 3.15692 1.63138 3.35731 1.74388C3.42411 1.78255 3.48036 1.8388 3.51903 1.9056L4.37333 3.46302C4.40849 3.53334 4.46474 3.58959 4.53505 3.62826Z"
        fill="url(#paint0_linear_180_1774)"
      />
      <path
        d="M16.1542 11.9601L17.7151 12.8144C17.9155 12.9234 17.9893 13.173 17.8804 13.3734C17.8417 13.4437 17.7854 13.5 17.7151 13.5387L16.1542 14.393C16.0839 14.4316 16.0276 14.4879 15.989 14.5582L15.1347 16.1191C15.0257 16.3195 14.7725 16.3933 14.5722 16.2844C14.5018 16.2457 14.4456 16.1894 14.4069 16.1191L13.5597 14.5547C13.521 14.4844 13.4647 14.4281 13.3944 14.3894L11.8335 13.5351C11.6331 13.4262 11.5593 13.173 11.6683 12.9726C11.7069 12.9023 11.7632 12.8461 11.8335 12.8074L13.3944 11.9531C13.4647 11.9144 13.521 11.8582 13.5597 11.7879L14.414 10.2305C14.5229 10.0301 14.7761 9.95624 14.9765 10.0652C15.0468 10.1039 15.103 10.1601 15.1417 10.2305L15.996 11.7914C16.0276 11.8652 16.0839 11.9215 16.1542 11.9601Z"
        fill="url(#paint1_linear_180_1774)"
      />
      <path
        d="M9.39712 1.84219L10.4202 2.40117C10.5502 2.475 10.5995 2.64024 10.5292 2.77031C10.5045 2.81602 10.4659 2.85117 10.4202 2.8793L9.39712 3.43828C9.35142 3.46289 9.31275 3.50156 9.28814 3.54727L8.72915 4.57031C8.65532 4.70039 8.49009 4.74961 8.36001 4.6793C8.31431 4.65469 8.27915 4.61602 8.25103 4.57031L7.69204 3.54727C7.66743 3.50156 7.62876 3.46289 7.58306 3.43828L6.56001 2.8793C6.42993 2.80899 6.38071 2.64375 6.45103 2.51016C6.47564 2.46445 6.51431 2.42578 6.56001 2.40117L7.58306 1.84219C7.62876 1.81758 7.66743 1.77891 7.69204 1.7332L8.25103 0.710158C8.32485 0.58008 8.49009 0.530861 8.62017 0.601174C8.66587 0.625783 8.70103 0.664455 8.72915 0.710158L9.28814 1.7332C9.31275 1.77891 9.35142 1.81758 9.39712 1.84219Z"
        fill="url(#paint2_linear_180_1774)"
      />
      <path
        d="M17.5992 2.30987L16.1542 0.864946C15.6199 0.330571 14.7515 0.327055 14.2136 0.86143C14.2136 0.86143 14.2136 0.86143 14.2101 0.864946L1.32886 13.7497C0.794483 14.2841 0.790968 15.1524 1.32886 15.6903L2.77378 17.1353C3.30816 17.6696 4.17652 17.6731 4.71441 17.1353L17.5992 4.25049C18.1335 3.71612 18.1335 2.84776 17.5992 2.30987ZM12.5753 7.18252L11.2816 5.88877L15.096 2.07081L16.3898 3.36455L12.5753 7.18252Z"
        fill="url(#paint3_linear_180_1774)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_180_1774"
        x1="6.31155"
        y1="1.69117"
        x2="-0.0738372"
        y2="1.76555"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007D65" />
        <stop offset="1" stopColor="#1766CE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_180_1774"
        x1="17.9307"
        y1="10.0149"
        x2="11.5453"
        y2="10.0891"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007D65" />
        <stop offset="1" stopColor="#1766CE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_180_1774"
        x1="10.5612"
        y1="0.569097"
        x2="6.3713"
        y2="0.61787"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007D65" />
        <stop offset="1" stopColor="#1766CE" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_180_1774"
        x1="18"
        y1="0.462426"
        x2="0.730359"
        y2="0.663429"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007D65" />
        <stop offset="1" stopColor="#1766CE" />
      </linearGradient>
      <clipPath id="clip0_180_1774">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </SVG>
)

export default withIconStyles(IconMagicWandGradientFilled)
