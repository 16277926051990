import React, { useEffect, useMemo } from 'react'
import { segmentApi } from '@edulastic/api'
import { roleuser } from '@edulastic/constants'
import { connect } from 'react-redux'
import AssignTest from './AssignTest'
import AssignRecommendations from './AssignRecommendations'
import {
  getUserDistrict,
  getUserId,
  getUserRole,
  isGcpsDistrictSelector,
} from '../../../src/selectors/user'
import {
  fetchTotalAssignmentCountAction,
  setEasyAssign,
} from '../../../src/actions/assignments'
import { rememberEasyAssignPreferenceLocalStorageKey } from '../SimpleOptions/constants'
import { getTotalAssignmentCount } from '../../../Dashboard/ducks'

const Container = ({
  isAssignRecommendations,
  role,
  loadAssignmentCount,
  setEasyAssignEnabled,
  currentTermId,
  userId,
  districtId,
  totalAssignmentCount,
  isGcpsDistrict,
  fetchTotalAssignmentCount,
  ...rest
}) => {
  useEffect(() => {
    const { state } = rest?.location || {}
    const { testId } = rest?.match?.params || {}
    segmentApi.genericEventTrack('testAssignStart', { ...state, testId })
  }, [rest?.location?.pathname])

  const isEasyModeRemembered = useMemo(() => {
    const rememberPreferenceLocalStorageKey = `${rememberEasyAssignPreferenceLocalStorageKey}-${userId}-${districtId}-${currentTermId}`
    const rememberPreferenceStored = localStorage.getItem(
      rememberPreferenceLocalStorageKey
    )
    return rememberPreferenceStored && JSON.parse(rememberPreferenceStored)
  }, [])

  const isNonGcpsNewTeacher =
    role === roleuser.TEACHER && !isGcpsDistrict && totalAssignmentCount < 2

  useEffect(() => {
    if (role === roleuser.TEACHER) {
      if (isEasyModeRemembered || isNonGcpsNewTeacher) {
        setEasyAssignEnabled(true)
      } else {
        setEasyAssignEnabled(false)
      }
    } else {
      setEasyAssignEnabled(false)
    }
  }, [role, isNonGcpsNewTeacher])

  useEffect(() => {
    if (!isEasyModeRemembered && isNonGcpsNewTeacher) {
      fetchTotalAssignmentCount()
    }
  }, [])

  if (isAssignRecommendations) {
    return <AssignRecommendations {...rest} />
  }

  return <AssignTest {...rest} />
}

const enhance = connect(
  (state) => ({
    role: getUserRole(state),
    currentTermId: state.user?.user?.orgData?.defaultTermId,
    userId: getUserId(state),
    districtId: getUserDistrict(state)?.districtId,
    totalAssignmentCount: getTotalAssignmentCount(state),
    isGcpsDistrict: isGcpsDistrictSelector(state),
  }),
  {
    setEasyAssignEnabled: setEasyAssign,
    fetchTotalAssignmentCount: fetchTotalAssignmentCountAction,
  }
)

export default enhance(Container)
