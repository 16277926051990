import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { segmentApi } from '@edulastic/api'
import { EduButton, TextAreaInputStyled } from '@edulastic/common'
import SwitchButton from '../../../TestPage/components/Setting/components/Common/SwitchButton'
import {
  getAssignmentSettingsModeDataSelector,
  getIsEasyAssignEnabledSelector,
} from '../../../src/selectors/assignments'
import {
  fetchAssignmentSettingsModeDataAction,
  setEasyAssign,
  updateAssignmentSettingsModeDataAction,
} from '../../../src/actions/assignments'
import { EasyAssignToggleBannerContainer, StyledModal } from './styled'
import { ErrorMessage } from '../../../../student/ManageClass/components/Container'

const EasyAssignToggleBanner = ({
  isEasyAssignEnabled,
  setEasyAssignEnabled,
  assignmentSettingsModeData,
  fetchAssignmentSettingsModeData,
  updateAssignmentSettingsModeData,
}) => {
  const [feedbackPopupVisible, setFeedbackPopupVisible] = useState(false)
  const [feedback, setFeedback] = useState('')

  useEffect(() => {
    fetchAssignmentSettingsModeData()
  }, [])

  const toggleHandler = (value) => {
    setEasyAssignEnabled(!value)
    segmentApi.genericEventTrack('AssignPage:AssignModeToggle:Switch', {
      to: value ? 'proAssign' : 'easyAssign',
      from: value ? 'easyAssign' : 'proAssign',
    })

    const { fetchError, proModeToggleCount = 0 } = assignmentSettingsModeData
    if (value && !fetchError) {
      if (proModeToggleCount < 1) {
        updateAssignmentSettingsModeData({
          proModeToggleCount: proModeToggleCount + 1,
          showErrMessage: false,
        })
      } else if (proModeToggleCount === 1) {
        setFeedbackPopupVisible(true)
      }
    }
  }

  const handleFeedbackSkip = () => {
    setFeedbackPopupVisible(false)
  }

  const handleFeedbackSubmit = () => {
    segmentApi.genericEventTrack('AssignmentSettings:EasyModeFeedback:Submit', {
      feedback,
    })
    updateAssignmentSettingsModeData({
      proModeToggleCount: 2,
      messageOnError: 'Submitting feedback failed',
    })
    setFeedbackPopupVisible(false)
  }
  return (
    <>
      <EasyAssignToggleBannerContainer>
        {isEasyAssignEnabled
          ? 'Pear Assessment shows basic settings by default. Your settings will be preserved.'
          : 'You can turn off Pro Mode to see basic settings. Your settings will be preserved.'}
        <SwitchButton
          checked={!isEasyAssignEnabled}
          onChangeHandler={toggleHandler}
          dataCy="easyAssignToggleButton"
          ariaLabel="Easy Assign Toggle Button"
        />
      </EasyAssignToggleBannerContainer>
      <StyledModal
        visible={feedbackPopupVisible}
        title="Were the basic settings not enough?"
        onCancel={() => setFeedbackPopupVisible(false)}
        maskClosable={false}
        modalWidth="720px"
        centered
        footer={[
          <EduButton
            height="40px"
            isGhost
            key="skipButton"
            onClick={handleFeedbackSkip}
            data-cy="promode-feedbackSkipButton"
          >
            SKIP & PROCEED
          </EduButton>,
          <EduButton
            height="40px"
            key="submitButton"
            onClick={handleFeedbackSubmit}
            disabled={!feedback || feedback.length > 500}
            data-cy="promode-feedbackSubmitButton"
          >
            SUBMIT FEEDBACK
          </EduButton>,
        ]}
      >
        <p>Please share your reasons so we can improve</p>
        <TextAreaInputStyled
          align="center"
          value={feedback}
          onChange={(event) => setFeedback(event.target.value)}
          autoSize={{ minRows: 3, maxRows: 5 }}
          style={{ color: 'black', fontWeight: 500 }}
          data-cy="promode-feedbackInput"
        />
        {feedback.length > 500 && (
          <ErrorMessage data-cy="limitExceededMessage">
            You have exceeded the limit of 500 characters
          </ErrorMessage>
        )}
      </StyledModal>
    </>
  )
}

const enhance = connect(
  (state) => ({
    isEasyAssignEnabled: getIsEasyAssignEnabledSelector(state),
    assignmentSettingsModeData: getAssignmentSettingsModeDataSelector(state),
  }),
  {
    setEasyAssignEnabled: setEasyAssign,
    fetchAssignmentSettingsModeData: fetchAssignmentSettingsModeDataAction,
    updateAssignmentSettingsModeData: updateAssignmentSettingsModeDataAction,
  }
)

export default enhance(EasyAssignToggleBanner)
