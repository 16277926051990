import { EduButton, FlexContainer, notification } from '@edulastic/common'
import { IconFolderWithLines, IconPlusCircle } from '@edulastic/icons'
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { themeColor } from '@edulastic/colors'
import { Tooltip } from 'antd'
import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'
import {
  AI,
  LIBRARY,
  MORE_ITEM_TYPES,
} from '@edulastic/constants/const/addQuestionQuickLinks'
import { TEST_TYPE_SURVEY } from '@edulastic/constants/const/testTypes'
import SelectGroupModal from '../../../AddItems/SelectGroupModal'
import { createTestItemAction } from '../../../../../src/actions/testItem'
import { clearDictAlignmentAction } from '../../../../../src/actions/dictionaries'
import {
  getCurrentGroupIndexSelector,
  getScrollToBottomSelector,
  hasSectionsSelector,
  isDynamicTestSelector,
  isSectionAdaptiveTestSelector,
  isTestLoadingSelector,
  setCurrentGroupIndexAction,
  setScrollToBottomAction,
} from '../../../../ducks'
import {
  AddMoreQuestionsPannelTitle,
  ButtonTextWrapper,
  NoItemsAddedTitle,
  StyledDivider,
} from './styled'
import ConfirmTabChange from '../../../Container/ConfirmTabChange'
import { hasUnsavedAiItems } from '../../../../../../assessment/utils/helpers'
import { isSectionsAdaptiveTestItemsLimitExceededAndNotify } from '../../../../utils'
import QuickItemLinks from './QuickItemLinks/QuickItemLinks'
import { isPearDomain } from '../../../../../../../utils/pear'
import NoDataPearAssessIcon from '../../../../../../common/components/NoDataNotification/noDataPearAssess.svg'
import { NoDataIcon } from '../../../../../src/components/common/NoDataNotification'
import { StyledEditCloneTooltipContainer } from './QuickItemLinkCard/styled'
import { getAddItemTooltipMessage } from './common/AddItemTooltipMessages'

const ButtonWrapper = ({ showHowerText, children, title }) => {
  return showHowerText ? (
    <Tooltip title={title || 'Edit test to add new item'} placement="top">
      <span>{children}</span>
    </Tooltip>
  ) : (
    <>{children}</>
  )
}

const AddMoreQuestionsPannel = ({
  onSaveTestId,
  createTestItem,
  test,
  clearDictAlignment,
  handleSave,
  updated,
  hasSections,
  showSelectGroupIndexModal,
  currentGroupIndexValueFromStore,
  handleNavChangeToAddItems,
  setCurrentGroupIndex,
  isEditable,
  isTestEditable,
  isTestClonable,
  isDynamicTest,
  t,
  scrollToBottom,
  setScrollToBottom,
  isSectionAdaptiveTest,
  isEditDisabledForSectionsAdaptiveTest,
  loadingTest,
}) => {
  const [showSelectGroupModal, setShowSelectGroupModal] = useState(false)
  const [
    showConfirmationOnTabChange,
    setShowConfirmationOnTabChange,
  ] = useState(false)
  const [selectedQuickItemType, setSelectedQuickItemType] = useState()

  const targetRef = useRef(null)
  const quickItemLinksRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio < 1 && scrollToBottom) {
          targetRef.current.scrollIntoView({ behavior: 'smooth' })
          setScrollToBottom(false)
        }
      },
      {
        threshold: 0.5,
      }
    )
    if (targetRef.current && scrollToBottom) {
      observer.observe(targetRef.current)
    }
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [scrollToBottom])

  // A copy of this functions exists at src/client/author/TestPage/components/AddItems/AddItems.js
  // If you make any changes here please do so for the above mentioned copy as well
  const handleCreateTestItem = (_test, quickLinkQuestionType) => {
    const { _id: testId, title } = _test
    const defaultWidgets = {
      rows: [
        {
          tabs: [],
          dimension: '100%',
          widgets: [],
          flowLayout: false,
          content: '',
        },
      ],
    }
    clearDictAlignment()
    onSaveTestId()
    createTestItem(defaultWidgets, {
      testFlow: true,
      testId,
      newPassageItem: false,
      testName: title,
      quickLinkQuestionType,
    })
  }

  // A copy of this functions exists at src/client/author/TestPage/components/AddItems/AddItems.js
  // If you make any changes here please do so for the above mentioned copy as well
  const handleSelectGroupModalResponse = (index, quickLinkQuestionType) => {
    if (index || index === 0) {
      handleSave(undefined, undefined, (_test) => {
        setCurrentGroupIndex(index)
        if (isSectionAdaptiveTest) {
          const isItemLimitExceeded = isSectionsAdaptiveTestItemsLimitExceededAndNotify(
            {
              itemGroup: _test?.itemGroups?.[index] || {},
              adaptiveDeliveryData: _test?.adaptiveDelivery || {},
              itemsToBeAdded: [...Array(1).keys()], // new item, one single item
              isBaseSection: index === 0,
            }
          )
          if (isItemLimitExceeded) {
            return
          }
        }
        handleCreateTestItem(_test, quickLinkQuestionType)
      })
    }
    setShowSelectGroupModal(false)
    setSelectedQuickItemType(null)
  }

  // A copy of this functions exists at src/client/author/TestPage/components/AddItems/AddItems.js
  // If you make any changes here please do so for the above mentioned copy as well
  const handleCreateNewItem = (checkAiItems = true, quickLinkQuestionType) => {
    const { title, itemGroups } = test
    const _hasUnsavedAiItems = hasUnsavedAiItems(itemGroups)
    if (checkAiItems && _hasUnsavedAiItems) {
      setShowConfirmationOnTabChange(true)
      setSelectedQuickItemType(quickLinkQuestionType)
      return
    }

    if (quickLinkQuestionType === AI.key) {
      if (_hasUnsavedAiItems) {
        handleSave()
      }
      quickItemLinksRef?.current?.createAIItems?.()
      return
    }

    if (!title) {
      notification({ messageKey: 'nameShouldNotEmpty' })
    }

    /*
  	  On create of new item, trigger the save test when:-
  		- the test is not having any sections and is updated or
  		- the test is having one section or
      - test has unsaved ai changes and not allowed to show the banner for checking ai items
  		- If the test is having multiple sections, then the save test is called
  		  after the user selects a particular section from modal
  	*/
    if (!hasSections || itemGroups.length === 1) {
      // check if update is needed
      if (hasSections || updated || (!checkAiItems && _hasUnsavedAiItems)) {
        handleSave(undefined, undefined, (_test) =>
          handleCreateTestItem(_test, quickLinkQuestionType)
        )
      } else {
        handleCreateTestItem(test, quickLinkQuestionType)
      }
    }

    /**
     * For sections test the select group index modal should only be shown if group index is not known.
     * When click on select items in a section the group index is known and select group index
     * modal need not be shown once again.
     * showSelectGroupIndexModal - this value is always "true" for all other tests except sections test
     */
    if (
      itemGroups.length > 1 &&
      !showSelectGroupIndexModal &&
      typeof currentGroupIndexValueFromStore === 'number'
    ) {
      handleSelectGroupModalResponse(
        currentGroupIndexValueFromStore,
        quickLinkQuestionType
      )
      return
    }
    if (itemGroups.length > 1) {
      setSelectedQuickItemType(quickLinkQuestionType)
      setShowSelectGroupModal(true)
    }
  }

  const handleQuickLinkClick = (quickLinkQuestionType) => {
    switch (quickLinkQuestionType) {
      case LIBRARY.key:
        return handleNavChangeToAddItems()
      case MORE_ITEM_TYPES.key:
        return handleCreateNewItem()
      default:
        return handleCreateNewItem(true, quickLinkQuestionType)
    }
  }

  const confirmChangeNav = (confirm) => () => {
    if (confirm) {
      handleCreateNewItem(false, selectedQuickItemType)
    }
    setShowConfirmationOnTabChange(false)
    setSelectedQuickItemType(null)
  }

  const isTestEmpty = !test.itemGroups?.find((ig) => ig.items?.length > 0)
  const showQuickLinks = !(isDynamicTest || test.testType === TEST_TYPE_SURVEY)

  return (
    <>
      {showQuickLinks ? (
        <>
          {isTestEmpty && (
            <NoItemsAddedTitle>
              <img
                src={isPearDomain ? NoDataPearAssessIcon : NoDataIcon}
                alt="No Items"
              />
              No Items Added
            </NoItemsAddedTitle>
          )}
          <FlexContainer
            flexDirection="row"
            justifyContent="center"
            id="pageBottom"
            ref={targetRef}
          >
            <AddMoreQuestionsPannelTitle isTestEmpty={isTestEmpty}>
              {isTestEmpty
                ? 'Add items from library or create items on your own'
                : 'Add more items'}
            </AddMoreQuestionsPannelTitle>
          </FlexContainer>
        </>
      ) : (
        <FlexContainer
          flexDirection="row"
          justifyContent="center"
          id="pageBottom"
          ref={targetRef}
        >
          <AddMoreQuestionsPannelTitle>
            Add more items
          </AddMoreQuestionsPannelTitle>
        </FlexContainer>
      )}
      <FlexContainer flexDirection="row" justifyContent="center">
        {showQuickLinks ? (
          <div>
            {isTestEmpty && <StyledDivider />}
            <QuickItemLinks
              loading={loadingTest}
              ref={quickItemLinksRef}
              onClick={handleQuickLinkClick}
              disabled={
                !isEditable ||
                isDynamicTest ||
                isEditDisabledForSectionsAdaptiveTest
              }
              disabledTitle={
                isDynamicTest || isEditDisabledForSectionsAdaptiveTest ? (
                  t(
                    `${
                      isEditDisabledForSectionsAdaptiveTest
                        ? 'regradeNotSupportedForSectionsAdaptiveTest'
                        : 'authoringItemDisabled'
                    }.info`
                  )
                ) : (
                  <StyledEditCloneTooltipContainer>
                    {getAddItemTooltipMessage({
                      isTestEditable,
                      isTestClonable,
                    })}
                  </StyledEditCloneTooltipContainer>
                )
              }
            />
          </div>
        ) : (
          <>
            <ButtonWrapper
              showHowerText={
                !isEditable || isEditDisabledForSectionsAdaptiveTest
              }
              title={
                isEditDisabledForSectionsAdaptiveTest
                  ? t('regradeNotSupportedForSectionsAdaptiveTest.info')
                  : ''
              }
            >
              <EduButton
                height="36px"
                isGhost
                data-cy="createFromLibrary"
                onClick={handleNavChangeToAddItems}
                disabled={!isEditable || isEditDisabledForSectionsAdaptiveTest}
              >
                <IconFolderWithLines
                  color={themeColor}
                  width={16}
                  height={16}
                />
                <ButtonTextWrapper>ADD FROM LIBRARY</ButtonTextWrapper>
              </EduButton>
            </ButtonWrapper>
            <ButtonWrapper
              showHowerText={
                !isEditable ||
                isDynamicTest ||
                isEditDisabledForSectionsAdaptiveTest
              }
              title={
                isDynamicTest || isEditDisabledForSectionsAdaptiveTest
                  ? t(
                      `${
                        isEditDisabledForSectionsAdaptiveTest
                          ? 'regradeNotSupportedForSectionsAdaptiveTest'
                          : 'authoringItemDisabled'
                      }.info`
                    )
                  : ''
              }
            >
              <EduButton
                height="36px"
                isGhost
                data-cy="createNewItem"
                onClick={handleCreateNewItem}
                disabled={
                  !isEditable ||
                  isDynamicTest ||
                  isEditDisabledForSectionsAdaptiveTest
                }
              >
                <IconPlusCircle color={themeColor} width={16} height={16} />
                <ButtonTextWrapper>CREATE NEW ITEM</ButtonTextWrapper>
              </EduButton>
            </ButtonWrapper>
          </>
        )}
        {showSelectGroupModal && (
          <SelectGroupModal
            visible={showSelectGroupModal}
            test={test}
            handleResponse={(index) =>
              handleSelectGroupModalResponse(index, selectedQuickItemType)
            }
            isSectionAdaptiveTest={isSectionAdaptiveTest}
          />
        )}
        <ConfirmTabChange
          confirmChangeNav={confirmChangeNav}
          showConfirmationOnTabChange={showConfirmationOnTabChange}
        />
      </FlexContainer>
    </>
  )
}

AddMoreQuestionsPannel.propTypes = {
  onSaveTestId: PropTypes.func,
  test: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  updated: PropTypes.bool.isRequired,
  showSelectGroupIndexModal: PropTypes.bool.isRequired,
  handleNavChangeToAddItems: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

AddMoreQuestionsPannel.defaultProps = {
  onSaveTestId: () => {},
}

const enhance = compose(
  withNamespaces('author'),
  connect(
    (state) => ({
      hasSections: hasSectionsSelector(state),
      currentGroupIndexValueFromStore: getCurrentGroupIndexSelector(state),
      isDynamicTest: isDynamicTestSelector(state),
      scrollToBottom: getScrollToBottomSelector(state),
      isSectionAdaptiveTest: isSectionAdaptiveTestSelector(state),
      loadingTest: isTestLoadingSelector(state),
    }),
    {
      createTestItem: createTestItemAction,
      clearDictAlignment: clearDictAlignmentAction,
      setCurrentGroupIndex: setCurrentGroupIndexAction,
      setScrollToBottom: setScrollToBottomAction,
    }
  )
)

export default enhance(AddMoreQuestionsPannel)
