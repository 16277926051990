import React, { useState } from 'react'
import { Popover, Tooltip, Typography } from 'antd'
import {
  Content,
  Header,
  StyledPreviewImage,
} from '../../../../../../PickUpQuestionType/components'
import {
  ImageContainer,
  ImageLabelContainer,
  MainContainer,
  StyledEditCloneTooltipContainer,
  StyledPopoverContent,
  StyledRoundDiv,
} from './styled'
import { EditableAndClonable } from '../common/AddItemTooltipMessages'

const QuickItemLinkCard = ({
  key,
  icon,
  label,
  hoverImage,
  disabled,
  onClick,
  dataCy,
  disabledTitle,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false)

  const iconContainer = (
    <Tooltip
      title={
        disabled &&
        (disabledTitle || (
          <StyledEditCloneTooltipContainer>
            <EditableAndClonable />
          </StyledEditCloneTooltipContainer>
        ))
      }
      overlayClassName="quick-link-disabled-tooltip"
      align={{ offset: [0, 120] }}
    >
      <ImageLabelContainer disabled={disabled} popoverVisible={popoverVisible}>
        <ImageContainer>{icon}</ImageContainer>
        <Typography style={{ textAlign: 'center' }}>{label}</Typography>
      </ImageLabelContainer>
    </Tooltip>
  )

  return (
    <MainContainer
      onClick={!disabled && onClick}
      disabled={disabled}
      data-cy={dataCy || key}
    >
      {hoverImage && !disabled ? (
        <Popover
          content={
            <StyledPopoverContent data-cy={`hover-image-${dataCy}`}>
              <StyledRoundDiv>
                <Header className="card-title">{label}</Header>
                <Content>
                  <StyledPreviewImage src={hoverImage} />
                </Content>
              </StyledRoundDiv>
            </StyledPopoverContent>
          }
          trigger="hover"
          key={key}
          onVisibleChange={(visible) => setPopoverVisible(visible)}
          placement="top"
        >
          {iconContainer}
        </Popover>
      ) : (
        iconContainer
      )}
    </MainContainer>
  )
}

export default QuickItemLinkCard
