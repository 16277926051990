import {
  EduButton,
  notification,
  EduSwitchStyled,
  FlexContainer,
  SelectInputStyled,
} from '@edulastic/common'
import produce from 'immer'
import { IconPencilEdit, IconQuestionCircle } from '@edulastic/icons'
import { lightRed2, fadedBlack, white, themeColor } from '@edulastic/colors'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse, Icon, Tooltip } from 'antd'
import { maxBy, isEqual } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { test as testConstants } from '@edulastic/constants'
import { testCategoryTypes } from '@edulastic/constants/const/test'
import Breadcrumb from '../../../src/components/Breadcrumb'
import {
  addNewGroupAction,
  createNewStaticGroup,
  deleteItemGroupByGroupIndexAction,
  getTestEntitySelector,
  hasSectionsSelector,
  updateGroupDataAction,
  setTestDataAction,
  isSectionAdaptiveTestSelector,
} from '../../ducks'
import { removeTestItemsAction } from '../AddItems/ducks'
import {
  BreadcrumbContainer,
  Container,
  ContentBody,
  SectionsTestCreateGroupWrapper,
  GroupField,
  ItemTag,
  Label,
  PanelHeading,
  QuestionTagsContainer,
  QuestionTagsWrapper,
  PanelStyled,
  SectionNameInput,
  StyledAddSectionsButton,
  StyledInfoMessage,
  StyledConvertToAdaptiveTest,
  StyledHorizontalLine,
  StyledIconInfo,
  SectionsContainer,
  StyledAdaptiveTestHelpButtonContainer,
  StyledAdaptiveTestHelpButton,
  StyledSelect,
  StyledSelectWrapper,
} from './styled'
import TypeConfirmModal from './TypeConfirmModal'
import SectionsAdaptiveTestGrouptems from './SectionsAdaptiveTestGrouptems'
import {
  adaptiveTestDefaultThresholdValue,
  adaptiveTestSettings,
  adaptiveTestSettingsOptions,
  adaptiveTestThresholdTypes,
  adaptiveTestTypes,
  baseSectionMaxItemsCount,
  defaultSectionAdaptiveSettingType,
  sectionsAdaptiveTestGroupNames,
  thresholdSectionMaxItemsCount,
} from './constants'
import SectionsAdaptiveTestInfoModal from './SectionsAdaptiveTest/components/SectionsAdaptiveTestInfoModal'
import AdaptiveToggleConfirmationModal from './SectionsAdaptiveTest/components/AdaptiveToggleConfirmationModal'
import {
  getAdaptiveSettingFromTest,
  getAdaptiveSettingTypeFromSettings,
} from './utils/adaptive'
import { enableSectionAdaptiveTestSelector } from '../../../src/selectors/user'
import { BetaTag2 } from '../../../../common/components/BetaTag'

const { Option } = SelectInputStyled
const { sectionTestActions } = testConstants
const MAX_SECTION_COUNT = 15

const defaultAdaptiveTestData = {
  adaptiveTestType: adaptiveTestTypes.PSAT,
  baseSectionMaxItemsCount,
  threshold: {
    thresholdType: adaptiveTestThresholdTypes.PERCENTAGE.value,
    value: adaptiveTestDefaultThresholdValue,
    thresholdSectionMaxItemsCount,
  },
}

const SectionsTestGroupItems = ({
  match,
  updateGroupData,
  addNewGroup,
  removeTestItems,
  deleteGroupByGroupIndex,
  test,
  history,
  currentGroupIndex,
  setCurrentGroupIndex,
  groupNotEdited,
  setGroupNotEdited,
  setTestData,
  setSectionsState,
  testId,
  gotoAddItems,
  handleSaveTest,
  setShowSectionsTestSelectGroupIndexModal,
  isSectionAdaptiveTest,
  isRegradeFlow,
  isEditable,
  enableSectionAdaptiveTest,
  onCreateRulesButtonClick,
  isSectionsAdaptiveTestCreateRulesButtonVisible,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmModalCategory, setConfirmModalCategory] = useState(null)
  const [deleteGroupIndex, setDeleteGroupIndex] = useState(null)
  const [activePanels, setActivePanels] = useState([])
  const [
    showSectionsAdaptiveTestInfoModal,
    setShowSectionsAdaptiveTestInfoModal,
  ] = useState(false)
  const [
    sectionsAdaptiveTestToggleConfirmationModalData,
    setSectionsAdaptiveTestToggleConfirmationModalData,
  ] = useState({ isVisible: false, value: null })

  const {
    currentTestAdaptiveSettings,
    currentTestAdaptiveSettingsType,
  } = useMemo(() => {
    let _currentTestAdaptiveSettingsType = defaultSectionAdaptiveSettingType
    let _currentTestAdaptiveSettings =
      adaptiveTestSettings[_currentTestAdaptiveSettingsType]

    if (test?.itemGroups && test?.adaptiveDelivery) {
      const thresholdType =
        test.adaptiveDelivery?.threshold?.thresholdType ||
        adaptiveTestThresholdTypes.PERCENTAGE

      _currentTestAdaptiveSettings = getAdaptiveSettingFromTest(test)
      _currentTestAdaptiveSettingsType = getAdaptiveSettingTypeFromSettings(
        _currentTestAdaptiveSettings,
        thresholdType
      )
    }

    return {
      currentTestAdaptiveSettings: _currentTestAdaptiveSettings,
      currentTestAdaptiveSettingsType: _currentTestAdaptiveSettingsType,
    }
  }, [test])

  const [selectedAdaptiveSetting, setSelectedAdaptiveSetting] = useState(
    currentTestAdaptiveSettingsType
  )

  const breadcrumbData = [
    {
      title: 'TESTS',
      to: '/author/tests',
    },
    {
      title: 'MANAGE SECTIONS',
      to: '',
    },
  ]

  const allFieldsDisabled = isRegradeFlow || !isEditable

  useEffect(() => {
    setActivePanels(test.itemGroups.map((_, i) => (i + 1).toString()))
  }, [test])

  useEffect(() => {
    /**
     * Show select group index modal in case group index is null or undefined.
     */
    if (currentGroupIndex !== 0 && !currentGroupIndex) {
      setShowSectionsTestSelectGroupIndexModal(true)
    }
  }, [currentGroupIndex])

  const handleSelectItems = () => {
    const currentGroupDetails = test.itemGroups[currentGroupIndex]
    const groupNamesFromTest = new Set(
      test.itemGroups.map((g) => `${g.groupName || ''}`.toLowerCase())
    )
    if (!currentGroupDetails.groupName?.length) {
      notification({ messageKey: 'pleaseEnterGroupName' })
      return
    }
    if (groupNamesFromTest.size !== test.itemGroups.length) {
      notification({ messageKey: 'eachGroupShouldHaveUniqueGroupName' })
      return false
    }
    // update the itemGroup corresponding to the current group being edited
    setCurrentGroupIndex(currentGroupIndex, true)
    updateGroupData({
      updatedGroupData: currentGroupDetails,
      groupIndex: currentGroupIndex,
    })
    // switch to "Add Items" section
    const addItemsUrl = match.params?.id
      ? `/author/tests/tab/addItems/id/${match.params.id}`
      : '/author/tests/create/addItems'
    history.push(addItemsUrl)
  }

  const handleChange = (fieldName, value) => {
    const oldGroupData = test.itemGroups[currentGroupIndex]
    let updatedGroupData = { ...oldGroupData }
    // set groupNotEdited for the first edit made to groups
    if (groupNotEdited) {
      setGroupNotEdited(false)
    }
    updatedGroupData = {
      ...updatedGroupData,
      [fieldName]: value,
    }
    updateGroupData({ updatedGroupData, groupIndex: currentGroupIndex })
  }

  const handleConfirmResponse = (value) => {
    if (value === 'YES') {
      if (confirmModalCategory === 'DELETE GROUP') {
        const groupToDelete = test.itemGroups[deleteGroupIndex]
        // deleteGroupByGroupIndex => deletes the group and updates the index for remaining
        deleteGroupByGroupIndex(deleteGroupIndex)
        // removeTestItems => removes selected test items for the deleted group
        removeTestItems(groupToDelete.items.map((i) => i._id))
        // if the group being edited is deleted, reset the edit details
        if (currentGroupIndex === deleteGroupIndex) {
          setCurrentGroupIndex(null)
        } else if (currentGroupIndex > deleteGroupIndex) {
          // if the edited group is positioned after the group being deleted
          // currentGroupIndex needs to be updated for the same by a factor of -1
          setCurrentGroupIndex(currentGroupIndex - 1)
        } else {
          // currentGroupIndex < deleteGroupIndex
          // no change required
        }
        setDeleteGroupIndex(null)
        // NOTE: test is not saved after this removal
      } else if (confirmModalCategory === 'DELETE LAST GROUP') {
        setTestData({
          hasSections: false,
          itemGroups: [createNewStaticGroup()],
        })
        setSectionsState(false)
        if (testId) handleSaveTest(sectionTestActions.REMOVE)
        gotoAddItems()
      }
    }
    setConfirmModalCategory(null)
    setShowConfirmModal(false)
  }

  const handleDeleteGroup = (e, index) => {
    e.stopPropagation()
    setDeleteGroupIndex(index)
    if (test.itemGroups.length <= 1) {
      setConfirmModalCategory('DELETE LAST GROUP')
    } else {
      setConfirmModalCategory('DELETE GROUP')
    }

    setShowConfirmModal(true)
  }

  const handleAddGroup = () => {
    if (test.itemGroups.length === MAX_SECTION_COUNT) {
      notification({ type: 'warn', messageKey: 'cantCreateMoreThan15Sections' })
      return
    }
    const groupNamesFromTest = test.itemGroups.map((g) =>
      `${g.groupName || ''}`.toLowerCase()
    )
    const { index } = maxBy(test?.itemGroups || [], 'index')
    let groupName = `SECTION ${index + 2}`
    for (
      let i = index + 3;
      groupNamesFromTest.includes(groupName.toLowerCase());
      i++
    ) {
      groupName = `SECTION ${i}`
    }

    const data = { ...createNewStaticGroup(), groupName, index: index + 1 }
    addNewGroup(data)
    setActivePanels([...activePanels, (test.itemGroups.length + 1).toString()])
    // make the newly created section active
    setCurrentGroupIndex(test.itemGroups.length)
    setShowSectionsTestSelectGroupIndexModal(true)
  }

  const handleEditGroup = (e, itemGroup, index) => {
    if (activePanels.includes((index + 1).toString())) e.stopPropagation()
    setCurrentGroupIndex(index)
    // Show select group modal as new group is getting edited
    setShowSectionsTestSelectGroupIndexModal(true)
  }

  const handleAdaptiveSettingChange = (value) => {
    setSelectedAdaptiveSetting(value)
    if (value !== 'custom') {
      const {
        itemGroups = [],
        adaptiveDelivery = defaultAdaptiveTestData,
      } = test

      const updatedItemGroups = itemGroups.map((item, i) => ({
        ...item,
        settings: {
          ...item.settings,
          timeLimit: adaptiveTestSettings[value].timeLimit,
          scaledScore:
            i === 0
              ? adaptiveTestSettings[value].scaledScore
              : adaptiveTestSettings[value]?.threshold?.scaledScore?.[i],
        },
      }))
      const totalAllowedTime = adaptiveTestSettings[value].timeLimit * 2
      const isTimedTest = totalAllowedTime > 0
      const baseSectionId = itemGroups[0]._id
      const belowThresholdSectionId = itemGroups[1]._id
      const aboveThresholdSectionId = itemGroups[2]._id
      setTestData({
        adaptiveDelivery: {
          ...adaptiveDelivery,
          baseSectionMaxItemsCount: adaptiveTestSettings[value]?.itemsRequired,
          baseSectionId,
          threshold: {
            ...adaptiveDelivery.threshold,
            value:
              adaptiveTestSettings[value]?.threshold[
                adaptiveDelivery.threshold.thresholdType
              ],
            belowThresholdSectionId,
            aboveThresholdSectionId,
            thresholdSectionMaxItemsCount:
              adaptiveTestSettings[value].itemsRequired,
          },
        },
        itemGroups: updatedItemGroups,
        timedAssignment: isTimedTest,
        ...(isTimedTest
          ? { allowedTime: totalAllowedTime }
          : { allowedTime: undefined }),
      })
    }
  }

  const handleMakeAdaptiveTest = (value) => {
    setSectionsAdaptiveTestToggleConfirmationModalData({
      isVisible: false,
      value: null,
    })
    if (value) {
      const sectionAdaptiveTestItemGroups = []
      for (let groupIndex = 0; groupIndex <= 2; groupIndex++) {
        const sectionAdaptiveTestItemGroup = createNewStaticGroup()
        sectionAdaptiveTestItemGroup.index = groupIndex
        if (groupIndex === 0) {
          sectionAdaptiveTestItemGroup.groupName =
            sectionsAdaptiveTestGroupNames.BASE
        } else if (groupIndex === 1) {
          sectionAdaptiveTestItemGroup.groupName =
            sectionsAdaptiveTestGroupNames.EASY
        } else if (groupIndex === 2) {
          sectionAdaptiveTestItemGroup.groupName =
            sectionsAdaptiveTestGroupNames.HARD
        }
        sectionAdaptiveTestItemGroup.settings = {}
        sectionAdaptiveTestItemGroup.settings.timeLimit =
          adaptiveTestSettings[selectedAdaptiveSetting].timeLimit
        sectionAdaptiveTestItemGroup.settings.scaledScore =
          groupIndex === 0
            ? adaptiveTestSettings[selectedAdaptiveSetting].scaledScore
            : adaptiveTestSettings[selectedAdaptiveSetting]?.threshold
                ?.scaledScore?.[groupIndex]
        sectionAdaptiveTestItemGroups.push(sectionAdaptiveTestItemGroup)
      }
      const totalAllowedTime =
        adaptiveTestSettings[selectedAdaptiveSetting].timeLimit * 2
      const isTimedTest = totalAllowedTime > 0
      const baseSectionId = sectionAdaptiveTestItemGroups[0]._id
      const belowThresholdSectionId = sectionAdaptiveTestItemGroups[1]._id
      const aboveThresholdSectionId = sectionAdaptiveTestItemGroups[2]._id
      const adaptiveTestData = {
        ...defaultAdaptiveTestData,
        baseSectionMaxItemsCount:
          adaptiveTestSettings[selectedAdaptiveSetting]?.itemsRequired,
        baseSectionId,
        threshold: {
          ...defaultAdaptiveTestData.threshold,
          value:
            adaptiveTestSettings[selectedAdaptiveSetting]?.threshold[
              defaultAdaptiveTestData.threshold.thresholdType
            ],
          belowThresholdSectionId,
          aboveThresholdSectionId,
          thresholdSectionMaxItemsCount:
            adaptiveTestSettings[selectedAdaptiveSetting].itemsRequired,
        },
      }
      setTestData({
        itemGroups: sectionAdaptiveTestItemGroups,
        preventSectionNavigation: true,
        adaptiveDelivery: adaptiveTestData,
        testCategory: testCategoryTypes.ADAPTIVE_SECTION_TEST,
        timedAssignment: isTimedTest,
        ...(isTimedTest
          ? { allowedTime: totalAllowedTime }
          : { allowedTime: undefined }),
      })
    } else {
      setTestData({
        itemGroups: [createNewStaticGroup()],
        preventSectionNavigation: false,
        adaptiveDelivery: undefined,
        testCategory: testCategoryTypes.DEFAULT,
        timedAssignment: false,
        allowedTime: 0,
      })
    }
    setCurrentGroupIndex(0)
  }

  const handleSectionAdaptiveTestToggleModalResponse = () => {
    handleMakeAdaptiveTest(
      sectionsAdaptiveTestToggleConfirmationModalData.value
    )
  }

  const onChangeSectionAdaptiveTestToggle = (value) => {
    const itemsInTest = test?.itemGroups?.flatMap(
      (itemGroup) => itemGroup?.items || []
    )
    if (itemsInTest?.length) {
      setSectionsAdaptiveTestToggleConfirmationModalData({
        isVisible: true,
        value,
      })
      return
    }
    handleMakeAdaptiveTest(value)
  }

  const handleRemoveItemFromGroup = (removedItemId, groupIndex) => {
    const { itemGroups } = test
    const updatedItemGroups = produce(itemGroups, (draft) => {
      draft[groupIndex].items = draft[groupIndex].items.filter(
        (item) => item._id !== removedItemId
      )
    })
    setTestData({ itemGroups: updatedItemGroups })
  }

  useEffect(() => {
    if (
      test?.adaptiveDelivery?.baseSectionMaxItemsCount &&
      selectedAdaptiveSetting !== 'custom'
    ) {
      const adaptiveType = test?.adaptiveDelivery?.threshold?.thresholdType
      const appliedSetting = JSON.parse(
        JSON.stringify(adaptiveTestSettings[selectedAdaptiveSetting])
      )

      const otherTypes = Object.keys(adaptiveTestThresholdTypes).filter(
        (t) => t !== adaptiveType
      )
      // Removing other types from appliedSettings to compare equality
      otherTypes.forEach((type) => {
        delete appliedSetting.threshold[type]
      })
      if (!isEqual(currentTestAdaptiveSettings, appliedSetting)) {
        setSelectedAdaptiveSetting('custom')
      }
    }
  }, [test, selectedAdaptiveSetting])

  const isAddSectionButtonDisabled =
    test?.itemGroups?.length >= MAX_SECTION_COUNT

  return (
    <Container>
      {showConfirmModal && (
        <TypeConfirmModal
          visible={showConfirmModal}
          handleResponse={handleConfirmResponse}
          confirmModalCategory={confirmModalCategory}
          additionalDeleteText="Delete of section removes the items from this section. You can still add it from the library."
          groupName={
            test.itemGroups?.[
              confirmModalCategory === 'TYPE'
                ? currentGroupIndex
                : deleteGroupIndex
            ]?.groupName
          }
        />
      )}
      {showSectionsAdaptiveTestInfoModal && (
        <SectionsAdaptiveTestInfoModal
          visible={showSectionsAdaptiveTestInfoModal}
          onCancel={() => setShowSectionsAdaptiveTestInfoModal(false)}
        />
      )}
      {sectionsAdaptiveTestToggleConfirmationModalData.isVisible && (
        <AdaptiveToggleConfirmationModal
          visible={sectionsAdaptiveTestToggleConfirmationModalData.isVisible}
          onOk={handleSectionAdaptiveTestToggleModalResponse}
          onCancel={() =>
            setSectionsAdaptiveTestToggleConfirmationModalData({
              isVisible: false,
              value: null,
            })
          }
        />
      )}
      <BreadcrumbContainer>
        <Breadcrumb data={breadcrumbData} style={{ position: 'unset' }} />
      </BreadcrumbContainer>
      <SectionsTestCreateGroupWrapper
        padding={isSectionAdaptiveTest ? '40px 60px' : '40px 80px'}
      >
        {enableSectionAdaptiveTest && (
          <>
            <FlexContainer justifyContent="space-between">
              <FlexContainer justifyContent="flex-start">
                <BetaTag2
                  backGroundColor={white}
                  borderColor={white}
                  width="auto"
                  ml="-28px"
                >
                  BETA
                </BetaTag2>
                <StyledConvertToAdaptiveTest>
                  <span>Enable Adaptive Testing</span>
                  <Tooltip title="Introducing Adaptive Test! Now create tests that show items dynamically based on student's performance in the first section of the test.">
                    <StyledIconInfo />
                  </Tooltip>
                  <EduSwitchStyled
                    checked={isSectionAdaptiveTest}
                    onChange={(val) => onChangeSectionAdaptiveTestToggle(val)}
                    disabled={allFieldsDisabled}
                    data-cy="adaptiveTestOnOffButton"
                  />
                  {isSectionAdaptiveTest && (
                    <StyledAdaptiveTestHelpButtonContainer>
                      <StyledAdaptiveTestHelpButton
                        onClick={() =>
                          setShowSectionsAdaptiveTestInfoModal(true)
                        }
                      >
                        <IconQuestionCircle
                          width="14"
                          height="14"
                          color={themeColor}
                        />{' '}
                        Help
                      </StyledAdaptiveTestHelpButton>
                    </StyledAdaptiveTestHelpButtonContainer>
                  )}
                </StyledConvertToAdaptiveTest>
              </FlexContainer>
              {isSectionAdaptiveTest && (
                <StyledSelectWrapper color={fadedBlack}>
                  <span>Use</span>
                  <StyledSelect
                    borderLess
                    width={250}
                    value={selectedAdaptiveSetting}
                    onChange={handleAdaptiveSettingChange}
                    disabled={allFieldsDisabled}
                    data-cy="adaptiveTestSettingsSelectDropDown"
                  >
                    {adaptiveTestSettingsOptions.map((option) => (
                      <Option value={option.value} key={option.id}>
                        {option.label}
                      </Option>
                    ))}
                  </StyledSelect>
                </StyledSelectWrapper>
              )}
            </FlexContainer>
            <StyledHorizontalLine />
          </>
        )}
        {isSectionAdaptiveTest ? (
          <SectionsAdaptiveTestGrouptems
            currentGroupIndex={currentGroupIndex}
            itemGroups={test.itemGroups}
            setTestData={setTestData}
            adaptiveDelivery={test.adaptiveDelivery}
            handleEditGroup={handleEditGroup}
            handleSelectItems={handleSelectItems}
            handleRemoveItemFromGroup={handleRemoveItemFromGroup}
            allFieldsDisabled={allFieldsDisabled}
            isRegradeFlow={isRegradeFlow}
            isEditable={isEditable}
            isSectionsAdaptiveTestCreateRulesButtonVisible={
              isSectionsAdaptiveTestCreateRulesButtonVisible
            }
            onCreateRulesButtonClick={onCreateRulesButtonClick}
            selectedAdaptiveSetting={selectedAdaptiveSetting}
          />
        ) : (
          <SectionsContainer>
            <Collapse
              activeKey={activePanels}
              onChange={(panels) => setActivePanels(panels)}
            >
              {test.itemGroups.map((itemGroup, index) => {
                return (
                  <PanelStyled
                    header={[
                      <PanelHeading>
                        {currentGroupIndex !== index ? (
                          <Label
                            fontWeight="600"
                            data-cy={`sectionName-${itemGroup.groupName}`}
                          >
                            {itemGroup.groupName}
                          </Label>
                        ) : (
                          <SectionNameInput
                            data-cy="sectionNameInput"
                            type="text"
                            placeholder="Provide a section name (upto 50 characters)"
                            maxLength={50}
                            value={itemGroup.groupName}
                            onClick={(e) => {
                              // Stops the collapsible click event when clicked on input box
                              e.stopPropagation()
                            }}
                            onChange={(e) =>
                              handleChange('groupName', e.target.value)
                            }
                          />
                        )}
                        <div>
                          {currentGroupIndex !== index && (
                            <div
                              data-cy={`editSection-${itemGroup.groupName}`}
                              title="edit"
                              onClick={(e) =>
                                handleEditGroup(e, itemGroup, index)
                              }
                            >
                              <IconPencilEdit />
                            </div>
                          )}
                          <div
                            title="Delete"
                            data-cy={`deleteSection-${itemGroup.groupName}`}
                            onClick={(e) => handleDeleteGroup(e, index)}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </div>
                        </div>
                      </PanelHeading>,
                    ]}
                    key={index + 1}
                  >
                    <ContentBody data-cy={`group-${itemGroup.groupName}`}>
                      <GroupField>
                        <Label>
                          Items <span style={{ color: lightRed2 }}>*</span>
                        </Label>
                        <QuestionTagsWrapper>
                          <QuestionTagsContainer
                            data-cy={`item-container-${itemGroup.groupName}`}
                          >
                            {itemGroup.items
                              .map(({ _id }) =>
                                _id.substring(_id.length, _id.length - 6)
                              )
                              .map((id) => (
                                <ItemTag>{id}</ItemTag>
                              ))}
                          </QuestionTagsContainer>
                          <Tooltip
                            title={
                              currentGroupIndex !== index
                                ? `Click on edit to select items`
                                : null
                            }
                          >
                            {/* Workaround: Need to wrap the button with span as antd tooltip doesn't work for disabled buttons */}
                            <span>
                              <EduButton
                                height="40px"
                                isGhost
                                onClick={handleSelectItems}
                                disabled={currentGroupIndex !== index}
                                data-cy={`selectItemButton-${itemGroup.groupName}`}
                              >
                                Select Items
                              </EduButton>
                            </span>
                          </Tooltip>
                        </QuestionTagsWrapper>
                      </GroupField>
                    </ContentBody>
                  </PanelStyled>
                )
              })}
            </Collapse>
            <StyledInfoMessage>
              <Icon type="info-circle" /> Use Section Settings to add
              calculators to a section and prevent students from visiting
              submitted sections
            </StyledInfoMessage>
            <GroupField style={{ marginTop: '15px', marginLeft: '45%' }}>
              <Tooltip
                title={
                  isAddSectionButtonDisabled
                    ? `Cannot create more than ${MAX_SECTION_COUNT} sections`
                    : null
                }
              >
                <span>
                  <StyledAddSectionsButton
                    type="button"
                    data-cy="add-section"
                    onClick={handleAddGroup}
                    disabled={isAddSectionButtonDisabled}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} aria-hidden="true" />
                    <span style={{ paddingLeft: 10 }}>ADD SECTION</span>
                  </StyledAddSectionsButton>
                </span>
              </Tooltip>
            </GroupField>
          </SectionsContainer>
        )}
      </SectionsTestCreateGroupWrapper>
    </Container>
  )
}

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      test: getTestEntitySelector(state),
      hasSections: hasSectionsSelector(state),
      isSectionAdaptiveTest: isSectionAdaptiveTestSelector(state),
      enableSectionAdaptiveTest: enableSectionAdaptiveTestSelector(state),
    }),
    {
      updateGroupData: updateGroupDataAction,
      addNewGroup: addNewGroupAction,
      removeTestItems: removeTestItemsAction,
      deleteGroupByGroupIndex: deleteItemGroupByGroupIndexAction,
      setTestData: setTestDataAction,
    }
  )
)

export default enhance(SectionsTestGroupItems)
