import PropTypes from 'prop-types'
import React, { memo, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { SMART_FILTERS } from '@edulastic/constants/const/filters'
import { folderTypes } from '@edulastic/constants'
import { connect } from 'react-redux'
import { grey } from '@edulastic/colors'
import { sessionFilters } from '@edulastic/constants/const/common'
import { Tooltip } from 'antd'
import TestFiltersNav from '../../../src/components/common/TestFilters/TestFiltersNav'
import Search from '../Search/Search'
import {
  Backdrop,
  Clear,
  CloseIcon,
  Container,
  FixedFilters,
  HeaderRow,
  MainFilter,
  MainFilterHeader,
  SearchWrapper,
  StyledFilterExpandContainer,
  StyledInfoIcon,
  Title,
} from './styled'
import InputTag from './SearchTag'
import Folders from '../../../src/components/Folders'
import { addItemToCartAction } from '../../ducks'
import { getUserIdSelector, getUserOrgId } from '../../../src/selectors/user'
import {
  getFilterFromSession,
  setFilterInSession,
} from '../../../../common/utils/helpers'

const ItemFilter = ({
  onClearSearch,
  search,
  onLabelSearch,
  curriculums,
  showFilter,
  onSearchFieldChange,
  onSearchInputChange,
  curriculumStandards,
  t,
  items,
  toggleFilter,
  itemCount,
  addItemToCart,
  userId,
  districtId,
}) => {
  const [libraryFolderExpanded, setLibraryFolderExpanded] = useState(
    () =>
      getFilterFromSession({
        key: sessionFilters.ITEM_LIBRARY_FILTER_EXPANDED,
        userId,
        districtId,
      }).expanded || false
  )

  const toggleLibraryFolderExpanded = () => {
    setFilterInSession({
      key: sessionFilters.ITEM_LIBRARY_FILTER_EXPANDED,
      userId,
      districtId,
      filter: { expanded: !libraryFolderExpanded },
    })
    setLibraryFolderExpanded(!libraryFolderExpanded)
  }

  const renderFullTextSearch = () => (
    <SearchWrapper>
      <HeaderRow>
        <InputTag
          onSearchInputChange={onSearchInputChange}
          value={search.searchString}
        />
      </HeaderRow>
    </SearchWrapper>
  )

  return (
    <>
      <Backdrop />
      <Container show={showFilter}>
        <CloseIcon type="close" onClick={toggleFilter} />
        <PerfectScrollbar>
          <FixedFilters>
            {renderFullTextSearch()}
            <MainFilter>
              <div>
                <MainFilterHeader>
                  <Title>LIBRARY/FOLDERS</Title>
                </MainFilterHeader>
                <TestFiltersNav
                  items={libraryFolderExpanded ? items : items.slice(0, 3)}
                  onSelect={onLabelSearch}
                  search={search}
                />
                {libraryFolderExpanded && (
                  <Folders
                    isActive={search.filter === SMART_FILTERS.FOLDERS}
                    folderType={folderTypes.ITEM}
                    removeItemFromCart={addItemToCart}
                    onSelectFolder={onSearchFieldChange('folderId')}
                  />
                )}
                <StyledFilterExpandContainer
                  onClick={toggleLibraryFolderExpanded}
                  data-cy="filterCategoryExpander"
                >
                  {libraryFolderExpanded ? `- SEE LESS` : `+ SEE MORE`}
                </StyledFilterExpandContainer>
                <MainFilterHeader
                  style={{ borderTop: `${grey} solid 1px`, paddingTop: '1rem' }}
                >
                  <Title data-cy="filterTitle">
                    {t('component.itemlist.filter.filters')}
                    <Tooltip title={t('component.libraryFilter.tooltip')}>
                      <StyledInfoIcon />
                    </Tooltip>
                  </Title>
                  <Clear data-cy="clearAll" onClick={onClearSearch}>
                    {t('component.itemlist.filter.clearAll')}
                  </Clear>
                </MainFilterHeader>
                <Search
                  search={search}
                  showStatus={search.filter !== items[0].filter}
                  curriculums={curriculums}
                  onSearchFieldChange={onSearchFieldChange}
                  curriculumStandards={curriculumStandards}
                  itemCount={itemCount}
                />
              </div>
            </MainFilter>
          </FixedFilters>
        </PerfectScrollbar>
      </Container>
    </>
  )
}

ItemFilter.propTypes = {
  search: PropTypes.object.isRequired,
  curriculums: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      curriculum: PropTypes.string.isRequired,
      grades: PropTypes.array.isRequired,
      subject: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSearchFieldChange: PropTypes.func.isRequired,
  onSearchInputChange: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  curriculumStandards: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
}

const enhance = connect(
  (state) => ({
    userId: getUserIdSelector(state),
    districtId: getUserOrgId(state),
  }),
  {
    addItemToCart: addItemToCartAction,
  }
)

export default enhance(memo(ItemFilter))
