import React, { useRef } from 'react'
import {
  getPDLDefaultGrade,
  getPDLDefaultSubject,
  getPDLDefaultSubjectId,
  getPDLGradeId,
} from './ducks/helpers'
import { StyledILPDrawer } from './styled'

const ILPDrawer = ({
  visible,
  setIsILPVisible,
  showILPProductSelectionScreen,
  defaultGrades = [],
  defaultSubjects = [],
}) => {
  const previousNode = useRef(null)

  const renderILPDrawer = (target) => {
    const showGenerateContentDrawer =
      window?.pear?.generate?.showGenerateContentDrawer

    if (showGenerateContentDrawer) {
      const pdlDefaultGrade = getPDLDefaultGrade(defaultGrades)
      const pdlDefaultGradeId = getPDLGradeId(pdlDefaultGrade)
      const pdlDefaultSubject = getPDLDefaultSubject(defaultSubjects)
      const pdlDefaultSubjectId = getPDLDefaultSubjectId(pdlDefaultSubject)

      showGenerateContentDrawer({
        target,
        onClickClose: () => setIsILPVisible(false),
        hideIncludeContextCheckbox: true,
        ...(pdlDefaultGradeId ? { gradeLevelId: pdlDefaultGradeId } : {}),
        ...(pdlDefaultSubjectId ? { subjectId: pdlDefaultSubjectId } : {}),
        contentTypes: showILPProductSelectionScreen
          ? undefined
          : ['TYPE_PEAR_ASSESSMENT_TEST'],
        expandAdvancedOptions: true,
      })
    }
  }

  return (
    <StyledILPDrawer
      title={null}
      placement="right"
      width="650px"
      closable={false}
      onClose={() => setIsILPVisible(false)}
      visible={visible}
      destroyOnClose
    >
      <div
        ref={(node) => {
          if (node !== null && node !== previousNode.current) {
            previousNode.current = node
            renderILPDrawer(node)
          }
        }}
        style={{ width: '100%', height: '100vh' }}
      />
    </StyledILPDrawer>
  )
}

export default ILPDrawer
