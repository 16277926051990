import { EduButton, TextAreaInputStyled } from '@edulastic/common'
import React from 'react'
import { Divider } from 'antd'
import { ErrorMessage } from '../../../../student/ManageClass/components/Container'
import { StyledModal } from '../styled-components/UserTranscriptInput'
import appConfig from '../../../../../app-config'

const transcriptInstructionsVideo = `${appConfig.getCDNOrigin()}/JS/assets/transcript-extraction-guide.mp4`

const InputTranscriptModal = ({
  visible,
  transcriptText,
  onChange,
  onCancel,
  onSubmit,
  disabled,
}) => {
  return (
    <StyledModal
      visible={visible}
      title="Enter Transcript"
      onCancel={onCancel}
      maskClosable={false}
      modalWidth="720px"
      modalHeight="720px"
      centered
      footer={[
        <EduButton
          key="saveAndGenerate"
          disabled={disabled}
          onClick={onSubmit}
          data-cy="manual-transcript-submit"
        >
          SAVE & GENERATE
        </EduButton>,
      ]}
    >
      <Divider orientation="center" />
      <p>
        The transcript for this video isn’t available right now, but you can add
        it manually below to continue with Auto Generate.
      </p>
      <video width="100%" controls autoPlay loop>
        <source src={transcriptInstructionsVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <h2>Enter Transcript</h2>
      <TextAreaInputStyled
        align="center"
        value={transcriptText}
        onChange={(event) => onChange(event.target.value)}
        autoSize={{ minRows: 5, maxRows: 15 }}
        style={{
          color: 'black',
          fontWeight: 500,
          border: '1px solid #D8D8D8',
          borderRadius: '4px',
          backgroundColor: 'transparent',
        }}
        data-cy="manual-transcript-input"
        placeholder="Enter/Paste transcript here"
      />
      {transcriptText?.length > 2 ** 20 && (
        <ErrorMessage data-cy="limitExceededMessage">
          You have exceeded the limit of 1MB transcript size
        </ErrorMessage>
      )}
    </StyledModal>
  )
}

export default InputTranscriptModal
