/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconPearAssessmentTest = (props) => {
  return (
    <SVG
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.05273 5.47998H14.0527C14.6047 5.47998 15.0527 5.92798 15.0527 6.47998V20H5.05273V6.47998C5.05273 5.92798 5.50073 5.47998 6.05273 5.47998Z"
        fill="#19825B"
      />
      <path
        d="M11.0527 0H19.0527C19.6047 0 20.0527 0.448 20.0527 1V20H10.0527V1C10.0527 0.448 10.5007 0 11.0527 0Z"
        fill="#19825B"
      />
      <path
        d="M9.05273 11H1.05273C0.500734 11 0.0527344 11.448 0.0527344 12V20H10.0527V12C10.0527 11.448 9.60473 11 9.05273 11Z"
        fill="#30B583"
      />
      <path
        d="M1.05273 11C0.500734 11 0.0527344 11.448 0.0527344 12V20H5.05273V11H1.05273Z"
        fill="#19825B"
      />
      <path
        d="M14.0527 5.47998H10.0527V20H15.0527V6.47998C15.0527 5.92798 14.6047 5.47998 14.0527 5.47998Z"
        fill="#195E44"
      />
    </SVG>
  )
}
export default withIconStyles(IconPearAssessmentTest)
