import React, { useEffect, useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { createPortal } from 'react-dom'
import { isEmpty } from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FileUploader, notification } from '@edulastic/common'
import IconInfo from '@edulastic/icons/src/IconInfoCircle'
import { fileTypes, aws } from '@edulastic/constants'
import {
  views,
  fileUploadStatusTypes,
} from '@edulastic/common/src/components/FileUploader/constants'
import { segmentApi } from '@edulastic/api'
import { StyledDocUploadContainer, StyledFooter } from '../components/styled'
import { getCurriculumsListSelector } from '../../../../src/selectors/dictionaries'
import {
  getImportTestFromDocSelector,
  importTestFromDocAction,
  processImportedQuestionsAndCreateTestAction,
  setImportTestFromDocStateAction,
} from '../ducks'
import { getDictCurriculumsAction } from '../../../../src/actions/dictionaries'
import { initialAlignmentData, testFromDocInitialData } from '../constants'
import { isTestFromDocDataValidAndNotify } from '../utils'
import { getDefaultInterests } from '../../../../dataUtils'
import AfterFooterButton from '../components/AfterFooterButton'
import ImportSummary from '../components/ImportSummary'
import StandardsInput from '../components/StandardsInput'

const ImportTestFromDoc = ({
  t: i18translate,
  fileDropAreaRef,
  createTestRef,
  footerRef,
  allCurriculums,
  importTestFromDocState,
  getAllCurriculums,
  importTestFromDoc,
  setImportTestFromDocState,
  processImportedQuestionsFromDocAndCreateTest,
}) => {
  const [isDocUploadInProgress, setIsDocUploadInProgress] = useState(false)
  const [testFromDocData, setTestFromDocData] = useState(testFromDocInitialData)
  const [resetFileUploaderData, setResetFileUploaderData] = useState(false)

  const {
    apiStatus: importTestFromDocAPIStatus = '',
    result: importedQuestionsData = {},
  } = importTestFromDocState || {}

  const { metaData = {} } = importedQuestionsData || {}

  const {
    cdnUrl: uploadedDocCdnUrl,
    fileName = '',
    alignment = {},
  } = testFromDocData

  const updateTestFromDocData = (updateData) => {
    setTestFromDocData((prevData) => ({ ...prevData, ...updateData }))
  }

  const updateAlignmentData = (value) => {
    updateTestFromDocData({ alignment: { ...alignment, ...value } })
  }

  const onUploadInProgress = () => {
    setIsDocUploadInProgress(true)
    setResetFileUploaderData(false)
  }

  const onFileDelete = () => {
    updateTestFromDocData({ cdnUrl: '', fileName: '' })
    setIsDocUploadInProgress(false)
  }

  const onUploadFinish = ({ filesData }) => {
    const { cdnUrl, status, name = '' } = filesData?.[0] || {}
    if (status === fileUploadStatusTypes.SUCCESS) {
      const dotSplittedFileName = name.split('.')
      updateTestFromDocData({
        cdnUrl,
        fileName:
          typeof name === 'string'
            ? dotSplittedFileName.length > 1
              ? dotSplittedFileName.slice(0, -1).join('.')
              : name
            : '',
      })
      return
    }
    updateTestFromDocData({ cdnUrl: '' })
  }

  const createTestFromDoc = () => {
    if (!isTestFromDocDataValidAndNotify(testFromDocData)) {
      return
    }
    segmentApi.genericEventTrack('TestImport:Document', {
      action: 'createTest',
    })
    importTestFromDoc(testFromDocData)
  }

  const processQuestionsAndGoToTestReview = () => {
    segmentApi.genericEventTrack('TestImport:Document', {
      action: 'review',
    })
    processImportedQuestionsFromDocAndCreateTest({ ...testFromDocData })
  }

  const resetData = () => {
    setImportTestFromDocState({
      apiStatus: false,
      result: {},
      errorMessage: '',
    })
    setIsDocUploadInProgress(false)
    updateTestFromDocData({
      cdnUrl: '',
      fileName: '',
      alignment: { ...alignment, standards: [], domains: [] },
    })
  }

  const discardAndRestart = () => {
    segmentApi.genericEventTrack('TestImport:Document', {
      action: 'discardAndRestart',
    })
    resetData()
    setResetFileUploaderData(true)
  }

  useEffect(() => {
    resetData()
    setResetFileUploaderData(false)
  }, [])

  useEffect(() => {
    if (isEmpty(allCurriculums)) {
      getAllCurriculums()
    }
    const defaultInterests = getDefaultInterests()
    let defaultSubject = defaultInterests?.subject
    defaultSubject =
      (Array.isArray(defaultSubject) ? defaultSubject[0] : defaultSubject) ||
      undefined
    const defaultGrades = defaultInterests?.grades || []
    const defaultCurriculumId = defaultInterests?.curriculumId || null
    let alignmentData = {
      ...initialAlignmentData,
      subject: defaultSubject,
      grades: defaultGrades,
      curriculumId: defaultCurriculumId,
    }

    if (allCurriculums?.length) {
      const defaultCurriculum = allCurriculums.find(
        (curriculum) => curriculum?._id === defaultCurriculumId
      )
      if (!isEmpty(defaultCurriculum)) {
        alignmentData = {
          ...alignmentData,
          curriculumId: defaultCurriculum._id,
          curriculum: defaultCurriculum.curriculum,
        }
      }
    }

    updateTestFromDocData({ alignment: alignmentData })
  }, [allCurriculums])

  const fileUploadErrorHandler = () => {
    notification({
      type: 'warn',
      msg:
        'Unsupported file. Please upload a file in .docx, .pdf, .png, or .jpg format, and ensure it is less than 10MB.',
    })
  }

  const fileUploadMaxCountHandle = () => {
    notification({
      type: 'warn',
      msg: i18translate('maxFileErrorMessage'),
    })
  }

  return (
    <>
      <ImportSummary
        importTestFromDocAPIStatus={importTestFromDocAPIStatus}
        metaData={metaData}
        fileName={fileName}
        processQuestionsAndGoToTestReview={processQuestionsAndGoToTestReview}
        discardAndRestart={discardAndRestart}
      />
      <StyledDocUploadContainer>
        <FileUploader
          fileDropAreaRef={fileDropAreaRef}
          maxFilesCount={1}
          allowedFileTypes={[
            fileTypes.PDF,
            fileTypes.PNG,
            fileTypes.JPEG,
            fileTypes.JPG,
            fileTypes.DOCX,
            fileTypes.GOOGLE_DOC,
          ]}
          view={views.TEST_SELECT_DOC_UPLOAD}
          s3Folder={aws.s3Folders.DEFAULT}
          maxFileSize={10}
          uploadType="AI_ATTACHMENT"
          resetAllData={resetFileUploaderData}
          fileUploadProgressCallback={onUploadInProgress}
          fileUploadFinishCallback={onUploadFinish}
          fileDeleteCallback={onFileDelete}
          maxFileSizeExceedCallback={fileUploadErrorHandler}
          unsupportedFileCallback={fileUploadErrorHandler}
          maxFileCountExceededCallback={fileUploadMaxCountHandle}
          eventSource="TestImport:Document"
        />
        {(isDocUploadInProgress || !!uploadedDocCdnUrl?.length) && (
          <StandardsInput
            updateAlignmentData={updateAlignmentData}
            testFromDocData={testFromDocData}
            alignment={alignment}
            allCurriculums={allCurriculums}
          />
        )}
      </StyledDocUploadContainer>
      {!isDocUploadInProgress &&
        footerRef?.current &&
        createPortal(
          <StyledFooter>
            <IconInfo width="20" height="20" />
            <p>
              Note : We are supporting MCQ, MSQ, True/False question type only
            </p>
          </StyledFooter>,
          footerRef.current
        )}
      {footerRef?.current &&
        createPortal(
          <AfterFooterButton
            isDocUploadInProgress={isDocUploadInProgress}
            uploadedDocCdnUrl={uploadedDocCdnUrl}
            createTestFromDoc={createTestFromDoc}
          />,
          createTestRef.current
        )}
    </>
  )
}

const enhance = compose(
  withNamespaces('importTest'),
  connect(
    (state) => ({
      allCurriculums: getCurriculumsListSelector(state),
      importTestFromDocState: getImportTestFromDocSelector(state),
    }),
    {
      getAllCurriculums: getDictCurriculumsAction,
      importTestFromDoc: importTestFromDocAction,
      setImportTestFromDocState: setImportTestFromDocStateAction,
      processImportedQuestionsFromDocAndCreateTest: processImportedQuestionsAndCreateTestAction,
    }
  )
)

export default enhance(ImportTestFromDoc)
