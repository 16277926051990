const PDL_GRADES = [
  {
    id: '1',
    code: 'PK',
    description: 'Pre-K',
  },
  {
    id: '2',
    code: 'K',
    description: 'Kindergarten',
  },
  {
    id: '3',
    code: '1',
    description: '1st Grade',
  },
  {
    id: '4',
    code: '2',
    description: '2nd Grade',
  },
  {
    id: '5',
    code: '3',
    description: '3rd Grade',
  },
  {
    id: '6',
    code: '4',
    description: '4th Grade',
  },
  {
    id: '7',
    code: '5',
    description: '5th Grade',
  },
  {
    id: '8',
    code: '6',
    description: '6th Grade',
  },
  {
    id: '9',
    code: '7',
    description: '7th Grade',
  },
  {
    id: '10',
    code: '8',
    description: '8th Grade',
  },
  {
    id: '11',
    code: '9',
    description: '9th Grade',
  },
  {
    id: '12',
    code: '10',
    description: '10th Grade',
  },
  {
    id: '13',
    code: '11',
    description: '11th Grade',
  },
  {
    id: '14',
    code: '12',
    description: '12th Grade',
  },
  {
    id: '15',
    code: '13',
    description: '13th Grade',
  },
]

const PDL_SUBJECTS = [
  {
    id: '1',
    code: 'CIV',
    description: 'Civic/Social Responsibility',
  },
  {
    id: '2',
    code: 'COMM',
    description: 'Communication',
  },
  {
    id: '3',
    code: 'DANC',
    description: 'Arts: Dance',
  },
  {
    id: '4',
    code: 'DISC',
    description: 'Disciplinary Literacy',
  },
  {
    id: '5',
    code: 'ECOL',
    description: 'Ecology',
  },
  {
    id: '6',
    code: 'ECON',
    description: 'Economics',

    deleted_at: null,
  },
  {
    id: '7',
    code: 'ENVI',
    description: 'Environmental Literacy',
  },
  {
    id: '8',
    code: 'ESL',
    description: 'English as Second Language',
  },
  {
    id: '9',
    code: 'FORN',
    description: 'Foreign Language',
  },
  {
    id: '10',
    code: 'GEO',
    description: 'Geography',
  },
  {
    id: '11',
    code: 'GOV',
    description: 'Government',
  },
  {
    id: '12',
    code: 'HEAL',
    description: 'Health Education',
  },
  {
    id: '13',
    code: 'HIST',
    description: 'History',
  },
  {
    id: '14',
    code: 'LANG',
    description: 'Language Arts',
  },
  {
    id: '15',
    code: 'MATH',
    description: 'Mathematics',
  },
  {
    id: '16',
    code: 'MEDA',
    description: 'Arts: Media Arts',
  },
  {
    id: '17',
    code: 'PFL',
    description: 'Personal Financial Literacy',
  },
  {
    id: '18',
    code: 'PHYS',
    description: 'Physical Education',
  },
  {
    id: '19',
    code: 'PROB',
    description: 'Reasoning and Problem Solving',
  },
  {
    id: '20',
    code: 'READ',
    description: 'Reading',
  },
  {
    id: '21',
    code: 'SCI',
    description: 'Science',
  },
  {
    id: '22',
    code: 'SCIT',
    description: 'Science and Technology',
  },
  {
    id: '23',
    code: 'SOC',
    description: 'Social Studies',
  },
  {
    id: '24',
    code: 'STEE',
    description: 'Science, Tech, Engineering, Environment',
  },
  {
    id: '25',
    code: 'THTR',
    description: 'Arts: Theatre',
  },
  {
    id: '26',
    code: 'VIS',
    description: 'Arts: Visual Arts',
  },
  {
    id: '27',
    code: 'VOC',
    description: 'Vocational Education',
  },
  {
    id: '28',
    code: 'WRIT',
    description: 'Writing',
  },
]

module.exports = {
  PDL_GRADES,
  PDL_SUBJECTS,
}
