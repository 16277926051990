import React, { useMemo, useRef } from 'react'
import { Select } from 'antd'
import { connect } from 'react-redux'
import { debounce, identity, pickBy } from 'lodash'
import { SelectInputStyled } from '@edulastic/common'
import { roleuser, assignmentStatusOptions } from '@edulastic/constants'
import styled from 'styled-components'
import { IconSearch } from '@edulastic/icons'
import { lightBlue10, lightGrey2 } from '@edulastic/colors'
import {
  getCurrentTerm,
  getUserOrgId,
  getUserRole,
} from '../../../src/selectors/user'
import {
  getsearchedAssigmentsListSelector,
  getTestListLoadingSelector,
} from '../../../src/selectors/assignments'
import {
  recieveAssignmentsListAction,
  receiveAssignmentsAction,
  receiveAssignmentsSummaryAction,
  recieveAssignmentsListSuccessAction,
} from '../../../src/actions/assignments'
import { shortTestIdKeyLength } from '../../constants'
import { setSelectSearchAriaLabel } from '../../../../common/utils/helpers'

const { DONE, IN_GRADING, IN_PROGRESS, NOT_OPEN } = assignmentStatusOptions
const showAllValue = 'Show All'

const AssignmentsSearch = ({
  filterState = {},
  userRole,
  onSetFilter,
  loadAssignmentsSummary,
  loadAssignments,
  districtId,
  testsList,
  recieveAssignmentsList,
  testListLoading,
  populateSearchResult = true,
  termId,
  setAssignmentsList,
}) => {
  const searchTextRef = useRef('')

  const handleChange = (value) => {
    if (!populateSearchResult) return
    let filters = {
      ...filterState,
      testId: value === showAllValue ? '' : value,
    }

    if (userRole === roleuser.TEACHER) {
      loadAssignments({ filters, folderId: filters.folderId })
    } else {
      filters = { ...filters, pageNo: 1 }
      loadAssignmentsSummary({
        folderId: filters.folderId,
        districtId,
        filters: pickBy(filters, identity),
        filtering: true,
      })
    }
    onSetFilter(filters)
  }

  const handleSelectAssignment = (value) => {
    if (populateSearchResult) {
      if (value === showAllValue) {
        setAssignmentsList([])
        searchTextRef.current = ''
      }
      return
    }
    onSetFilter(value)
  }

  const query = useMemo(() => {
    return {
      limit: 35,
      page: 1,
      search: {
        districtId,
        classSubjects: filterState.subject ? [filterState.subject] : [],
        classGrades: filterState.grades,
        termId: !populateSearchResult ? termId : filterState.termId,
        groupIds: filterState.classId ? [filterState.classId] : [],
        testTypes: filterState.testTypes,
        folderId: filterState.folderId,
        assignedBy: filterState.assignedBy,
        tagIds: filterState.tags || [],
        statuses: filterState.status
          ? [filterState.status]
          : [NOT_OPEN, IN_PROGRESS, IN_GRADING, DONE],
        searchString: filterState.testId,
      },
      aggregate: true,
    }
  }, [districtId, filterState])

  const handleSearch = (value = '') => {
    searchTextRef.current = value
    if (value.length < 2) {
      setAssignmentsList([])
      return
    }
    recieveAssignmentsList({
      ...query,
      search: {
        ...query.search,
        searchString: value || '',
      },
    })
  }

  const onSearch = debounce(handleSearch, 500)

  const onDropdownVisibleChange = (open) => {
    if (open) {
      setAssignmentsList([])
    }
  }

  const value =
    !filterState.testId || filterState.testId === showAllValue
      ? undefined
      : filterState.testId

  return (
    <GroupSelectAndIcon
      data-joyride-id="assignmentsSearch"
      populateSearchResult={populateSearchResult}
    >
      <SearchIcon populateSearchResult={populateSearchResult}>
        <IconSearch color={filterState.testId ? lightBlue10 : lightGrey2} />
      </SearchIcon>
      <StyledSelectInput
        data-cy="filter-test-name"
        mode="default"
        style={{
          width: 241,
          height: populateSearchResult ? 32 : 36,
        }}
        populateSearchResult={populateSearchResult}
        height="32px"
        $paddingRight="0px"
        placeholder={
          !populateSearchResult ? 'Search "Assignments"' : 'Show All'
        }
        aria-label="Search Assignments"
        value={value}
        onChange={handleChange}
        onSelect={handleSelectAssignment}
        onFocus={handleSearch}
        onDropdownVisibleChange={onDropdownVisibleChange}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
          option.props.value
            .slice(-shortTestIdKeyLength)
            .includes(input.toLowerCase())
        }
        loading={testListLoading}
        onSearch={onSearch}
        testSelected={filterState.testId}
        notFoundContent={
          testListLoading
            ? 'Loading...'
            : searchTextRef.current.length < 2
            ? 'Type 2 letters to search'
            : 'No Results'
        }
        showSearch
        ref={(node) => {
          setSelectSearchAriaLabel(node, 'Search Assignments')
        }}
      >
        {value && populateSearchResult && (
          <Select.Option key={0} value={showAllValue}>
            Show All
          </Select.Option>
        )}
        {testsList?.map(({ _id, title }, index) => (
          <Select.Option
            title={`${title} (Id: ${_id.slice(-shortTestIdKeyLength)})`}
            key={index}
            value={_id}
          >
            {title}
          </Select.Option>
        ))}
      </StyledSelectInput>
    </GroupSelectAndIcon>
  )
}

export default connect(
  (state) => ({
    userRole: getUserRole(state),
    districtId: getUserOrgId(state),
    testsList: getsearchedAssigmentsListSelector(state),
    testListLoading: getTestListLoadingSelector(state),
    termId: getCurrentTerm(state),
  }),
  {
    loadAssignments: receiveAssignmentsAction,
    loadAssignmentsSummary: receiveAssignmentsSummaryAction,
    recieveAssignmentsList: recieveAssignmentsListAction,
    setAssignmentsList: recieveAssignmentsListSuccessAction,
  }
)(AssignmentsSearch)

const StyledSelectInput = styled(SelectInputStyled)`
  color: ${({ populateSearchResult }) =>
    populateSearchResult ? 'auto' : 'black'};
  font-weight: ${({ populateSearchResult }) =>
    populateSearchResult ? 'auto' : '400'};
  &.ant-select {
    .ant-select-selection {
      &.ant-select-selection--single {
        padding-left: ${({ populateSearchResult }) =>
          populateSearchResult ? '20px' : '0px'};
        ${(props) =>
          props.testSelected ? `border: 1px solid ${lightBlue10};` : ''}
        .ant-select-arrow {
          display: none;
        }
        .ant-select-selection__rendered {
          padding: 0px 10px 0px 8px;
          .ant-select-selection-selected-value {
            ${(props) => (props.testSelected ? `color: ${lightBlue10};` : '')}
          }
          .ant-select-selection__placeholder {
            margin-left: 10px;
          }
        }
      }
    }
  }
`

const GroupSelectAndIcon = styled.div`
  position: relative;
  display: inline-block;
  height: ${({ populateSearchResult }) =>
    populateSearchResult ? 'auto' : '36px'};
`
const SearchIcon = styled.span`
  position: absolute;
  display: ${({ populateSearchResult }) =>
    populateSearchResult ? 'inline-block' : 'none'};
  width: 25px;
  z-index: 1;
  top: ${({ populateSearchResult }) => (populateSearchResult ? '8px' : '10px')};
  left: 8px;
`
