import React, { useEffect } from 'react'
import { Tooltip } from 'antd'
import { withNamespaces } from 'react-i18next'
import {
  IconWhiteUploadAttachment,
  IconWhiteUrlAttachment,
  IconCrossYtSearch,
} from '@edulastic/icons'
import { aws } from '@edulastic/constants'
import { segmentApi } from '@edulastic/api'
import { FileUploader } from '@edulastic/common'
import {
  fileUploadStatusTypes,
  views,
} from '@edulastic/common/src/components/FileUploader/constants'
import {
  StyledAttachmentBetaTag,
  StyledAttachmentBox,
  StyledAttachmentContainer,
  StyledAttachmentHeader,
  StyledAttachmentHeaderText,
  StyledAttachmentIconContainer,
  StyledAttachmentInfoText,
  StyledAttachmentInputContainer,
  StyledAttachmentLink,
  StyledAttachmentDivider,
  StyledAttachmentTextContainer,
  StyledURLInputContainer,
  StyledClearUrlButton,
  StyledURLInput,
  StyledDocInputContainer,
} from './styled'
import {
  aiAttachmentTypes,
  aiAttachmentMessageTypes,
  trackingEventSource,
  allowedAIAttachmentFiles,
  aiAttachmentMaxFileSize,
} from './constants'

const AIAttachment = ({
  t,
  attachmentType,
  onChangeAttachmentType,
  handleAttachmentChange,
  attachmentMessageData,
  setAttachmentMessageData,
  attachments,
  setIsGenerateDisabled,
  createAITestContainerRef,
}) => {
  const isUrlAttachmentType = attachmentType === aiAttachmentTypes.URL
  const { type: messageType = null, message = '' } = attachmentMessageData || {}
  const hasMessage = !!(
    (messageType === aiAttachmentMessageTypes.INFO ||
      messageType === aiAttachmentMessageTypes.ERROR) &&
    message?.length
  )
  const [attachmentObj] = attachments || []
  const { url: webUrl } = attachmentObj || {}
  const isSwitchToDifferentAttachmentTypeDisabled = !!webUrl?.length

  const switchAttachmentTypeDisabledMessage = webUrl?.length
    ? 'Only one attachment (URL or document) is supported at a time.'
    : null

  const handleAttachmentUrlChange = (event) => {
    const url = event?.target?.value
    handleAttachmentChange({ type: aiAttachmentTypes.URL, url })
  }

  const handleS3UrlChange = (s3Url) => {
    handleAttachmentChange({
      type: aiAttachmentTypes.DOCUMENT,
      url: s3Url,
    })
  }

  const handleSwitchToDifferentAttachmentType = () => {
    if (isSwitchToDifferentAttachmentTypeDisabled) {
      return
    }
    segmentApi.genericEventTrack('AIQuizCreate:SwitchAttachmentType', {
      to: isUrlAttachmentType
        ? aiAttachmentTypes.DOCUMENT
        : aiAttachmentTypes.URL,
    })
    handleAttachmentChange({ type: aiAttachmentTypes.URL, url: '' })
    onChangeAttachmentType(
      isUrlAttachmentType ? aiAttachmentTypes.DOCUMENT : aiAttachmentTypes.URL
    )
  }

  const handleAttachmentTypeChange = (_attachmentType) => {
    segmentApi.genericEventTrack('AIAttachmentClick', {
      source: trackingEventSource[_attachmentType],
    })
    onChangeAttachmentType(_attachmentType)
  }

  const onUnsupportedFiles = () => {
    setAttachmentMessageData({
      type: aiAttachmentMessageTypes.ERROR,
      message:
        'Unsupported file. Please upload a file in .docx, .pdf, .png, or .jpeg format, and ensure it is less than 5MB.',
    })
  }

  const onUploadInProgress = () => {
    setIsGenerateDisabled(true)
    setAttachmentMessageData({
      type: aiAttachmentMessageTypes.INFO,
      message: 'Uploaded documents might be used for training purposes',
    })
  }

  const onUploadFinish = ({ filesData }) => {
    const fileData = filesData?.[0] || {}
    const { cdnUrl, status } = fileData || {}
    if (status === fileUploadStatusTypes.SUCCESS) {
      handleS3UrlChange(cdnUrl)
      setIsGenerateDisabled(false)
      return
    }
    handleS3UrlChange('')
    setIsGenerateDisabled(false)
  }

  const onFileDelete = () => {
    handleS3UrlChange('')
    setAttachmentMessageData({
      type: null,
      message: '',
    })
  }

  const onMaxFileCountExceed = () => {
    setAttachmentMessageData({
      type: aiAttachmentMessageTypes.ERROR,
      message: t('maxFileErrorMessage'),
    })
  }

  useEffect(() => {
    if (attachmentType === aiAttachmentTypes.URL) {
      setAttachmentMessageData({
        type: aiAttachmentMessageTypes.INFO,
        message:
          'For best results, use Wikipedia articles or public websites with URLs that do not require a login.',
      })
    } else {
      setAttachmentMessageData({
        type: null,
        message: '',
      })
    }
  }, [attachmentType])

  return (
    <>
      {attachmentType ? (
        <StyledAttachmentInputContainer>
          <StyledAttachmentHeader>
            <StyledAttachmentHeaderText>
              {isUrlAttachmentType ? 'USE URL' : 'UPLOAD DOCUMENT'}
            </StyledAttachmentHeaderText>
            <StyledAttachmentHeaderText>
              Switch to{' '}
              <Tooltip
                title={
                  isSwitchToDifferentAttachmentTypeDisabled
                    ? switchAttachmentTypeDisabledMessage
                    : null
                }
              >
                <span>
                  <StyledAttachmentLink
                    data-cy={
                      isUrlAttachmentType
                        ? 'aiSwitchToDocUpload'
                        : 'aiSwitchToUseUrl'
                    }
                    onClick={handleSwitchToDifferentAttachmentType}
                    disabled={isSwitchToDifferentAttachmentTypeDisabled}
                  >
                    {isUrlAttachmentType ? 'DOC UPLOAD' : 'USE URL/LINK'}
                  </StyledAttachmentLink>
                </span>
              </Tooltip>
            </StyledAttachmentHeaderText>
          </StyledAttachmentHeader>
          {isUrlAttachmentType ? (
            <StyledURLInputContainer
              isError={messageType === aiAttachmentMessageTypes.ERROR}
            >
              <IconWhiteUrlAttachment fillColor="#555555" />
              <StyledURLInput
                data-cy="aiEnterUrl"
                placeholder="Enter URL/link here"
                onChange={handleAttachmentUrlChange}
                value={webUrl}
              />
              <StyledClearUrlButton
                data-cy="aiClearUrl"
                onClick={() =>
                  handleAttachmentChange({
                    type: aiAttachmentTypes.URL,
                    url: '',
                  })
                }
              >
                <IconCrossYtSearch />
              </StyledClearUrlButton>
            </StyledURLInputContainer>
          ) : (
            <StyledDocInputContainer>
              <FileUploader
                fileDropAreaRef={createAITestContainerRef}
                maxFilesCount={1}
                allowedFileTypes={allowedAIAttachmentFiles}
                view={views.ITEM_BANK_CREATE_AI_ITEMS_ATTACHMENTS}
                s3Folder={aws.s3Folders.DEFAULT}
                maxFileSize={aiAttachmentMaxFileSize}
                uploadType="AI_ATTACHMENT"
                maxFileSizeExceedCallback={onUnsupportedFiles}
                unsupportedFileCallback={onUnsupportedFiles}
                fileUploadProgressCallback={onUploadInProgress}
                fileUploadFinishCallback={onUploadFinish}
                maxFileCountExceededCallback={onMaxFileCountExceed}
                fileDeleteCallback={onFileDelete}
                uploadCancelCallback={() => handleS3UrlChange('')}
                eventSource="AIQuizCreate:Document"
              />
            </StyledDocInputContainer>
          )}
          {hasMessage && (
            <StyledAttachmentInfoText
              data-cy="aiInfoMessage"
              isError={messageType === aiAttachmentMessageTypes.ERROR}
            >
              {message}
            </StyledAttachmentInfoText>
          )}
        </StyledAttachmentInputContainer>
      ) : (
        <StyledAttachmentContainer>
          {Object.keys(aiAttachmentTypes).map((_attachmentType, index) => {
            const isUrlAttachment = _attachmentType === aiAttachmentTypes.URL
            return (
              <>
                <div>
                  <StyledAttachmentBox>
                    <StyledAttachmentTextContainer>
                      <StyledAttachmentIconContainer>
                        {isUrlAttachment ? (
                          <IconWhiteUrlAttachment />
                        ) : (
                          <IconWhiteUploadAttachment />
                        )}
                      </StyledAttachmentIconContainer>
                      {isUrlAttachment ? 'Use URL' : 'Upload Document'}
                    </StyledAttachmentTextContainer>
                    <StyledAttachmentLink
                      data-cy={isUrlAttachment ? 'aiAddLink' : 'aiUpload'}
                      onClick={() =>
                        handleAttachmentTypeChange(_attachmentType)
                      }
                    >
                      {isUrlAttachment ? 'ADD LINK' : 'UPLOAD'}
                    </StyledAttachmentLink>
                  </StyledAttachmentBox>
                  <StyledAttachmentBetaTag>BETA</StyledAttachmentBetaTag>
                </div>
                {index === 0 && (
                  <StyledAttachmentDivider>OR</StyledAttachmentDivider>
                )}
              </>
            )
          })}
        </StyledAttachmentContainer>
      )}
    </>
  )
}

export default withNamespaces('importTest')(AIAttachment)
