import React from 'react'
import {
  IconContentConverter,
  IconCreate,
  IconImport,
  IconItemLibrary,
  IconSearch,
  IconTryItOut,
} from '@edulastic/icons'
import IconMagicWand from '@edulastic/icons/src/IconMagicWand'

export const CARD_IDS = {
  TRY_IT_OUT: 'try-it-out',
  AUTHOR_ITEM: 'author-item',
  BUILD_TEST: 'build-test',
  USE_AI_TEST_CREATE: 'use-ai-test-create',
  GOOGLE_FORM_TEST: 'google-form-test',
  IMPORT_TEST: 'import-test',
  FIND_TEST: 'find-test',
}

export const CARDS = [
  {
    id: CARD_IDS.TRY_IT_OUT,
    icon: <IconTryItOut />,
    title: 'Warm-up Test',
    dataCy: CARD_IDS.TRY_IT_OUT,
    subTitle: 'Discover our wide range of item types.',
    navigation: {
      pathname: '/author/tests/tab/review/id/62fa5bb3ecd4a00009dd4314',
    },
    trackingEvent: {
      eventName: 'Dashboard:Lifecycle:Icebreaker',
      source: 'dashboard',
    },
  },
  {
    id: CARD_IDS.AUTHOR_ITEM,
    icon: <IconItemLibrary width="24" height="24" />,
    title: 'Author an Item',
    dataCy: CARD_IDS.AUTHOR_ITEM,
    subTitle: 'Make a new question',
    navigation: {
      pathname: '/author/items/new/pickup-questiontype',
    },
    trackingEvent: {
      eventName: 'Dashboard:Lifecycle:AuthorItem',
      source: 'dashboard',
    },
  },
  {
    id: CARD_IDS.BUILD_TEST,
    icon: <IconCreate />,
    title: 'Build a Test',
    dataCy: CARD_IDS.BUILD_TEST,
    subTitle: '50+ question types',
    navigation: {
      pathname: '/author/tests/create/review',
      state: { persistStore: true },
    },
    trackingEvent: {
      eventName: 'Dashboard:Lifecycle:Build',
      source: 'dashboard',
    },
  },
  {
    id: CARD_IDS.USE_AI_TEST_CREATE,
    icon: <IconMagicWand fill="black" />,
    title: 'Use AI',
    dataCy: CARD_IDS.USE_AI_TEST_CREATE,
    subTitle: 'Generate tests using custom prompts, documents, and links',
    navigation: {},
    trackingEvent: {
      eventName: 'Dashboard:Lifecycle:UseAI',
      source: 'dashboard',
    },
  },
  {
    id: CARD_IDS.GOOGLE_FORM_TEST,
    icon: <IconImport />,
    title: 'Import a Test',
    dataCy: CARD_IDS.GOOGLE_FORM_TEST,
    subTitle: 'Google Forms',
    trackingEvent: {
      eventName: 'Dashboard:Lifecycle:GoogleForms',
      source: 'dashboard',
    },
  },
  {
    id: CARD_IDS.IMPORT_TEST,
    icon: <IconContentConverter height="32px" width="32px" />,
    title: 'Content Converter',
    dataCy: CARD_IDS.IMPORT_TEST,
    subTitle: 'Convert a document or Google Form into a test',
    navigation: {
      pathname: '/author/tests/import',
    },
    trackingEvent: {
      eventName: 'Dashboard:Lifecycle:ImportTest',
      source: 'dashboard',
    },
  },
  {
    id: CARD_IDS.FIND_TEST,
    icon: <IconSearch height="20" width="40" />,
    title: 'Find a Test',
    dataCy: CARD_IDS.FIND_TEST,
    subTitle: '400k pre-built tests',
    navigation: {
      pathname: '/author/tests',
      search: '?filter=ENTIRE_LIBRARY&limit=20&page=1',
    },
    trackingEvent: {
      eventName: 'Dashboard:Lifecycle:Library',
      source: 'dashboard',
    },
  },
]
