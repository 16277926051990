import { IconCopy, IconPencilEdit } from '@edulastic/icons'
import React from 'react'

export const EditableAndClonable = (
  <>
    <IconPencilEdit /> Edit or <IconCopy /> clone the test to add more items
  </>
)

export const OnlyClonable = (
  <>
    <IconCopy /> Clone the test to add more items
  </>
)

export const NonEditableNonClonable = (
  <div>
    <div>You cannot add more items as you</div>
    <div>do not have edit permissions</div>
  </div>
)

export const getAddItemTooltipMessage = ({
  isTestEditable,
  isTestClonable,
}) => {
  if (isTestEditable && isTestClonable) return EditableAndClonable
  if (isTestClonable) return OnlyClonable
  return NonEditableNonClonable
}
