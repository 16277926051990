import styled from 'styled-components'

export const StyledPDLMissingStandardsBanner = styled.div`
  display: flex;
  width: 100%;
  height: 60;
  top: 146px;
  left: 101px;
  border-width: 1px;
  border: 1px solid #8e6601;
  background: #fecb4d26;
  border-radius: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  padding: 10px;
`
export const StyledPDLMissingStandardsBannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledPDLMissingStandardsBannerText = styled.p`
  font-family: Open Sans;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8e6601;
  .pdl-missing-standards-sub-text {
    font-size: 12px;
    line-height: 18px;
    margin-top: 5px;
  }
`
