/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconPearDeck = (props) => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.66577 19.9999V10.8237C1.66577 8.47971 2.55901 6.13571 4.34748 4.34724C6.2039 2.49082 8.6598 1.59958 11.0917 1.67152L11.0977 1.66553H19.9901V19.9999H1.66577Z"
      fill="#B7E40D"
    />
    <path
      d="M2.24683 7.61249L2.79636 8.16202L7.73015 13.0958H13.0956V7.73039L8.85119 3.48601L8.37759 3.01242L7.61225 2.24707C6.42126 2.69269 5.30422 3.39009 4.34703 4.34728C3.38985 5.30446 2.69045 6.42151 2.24683 7.61249Z"
      fill="#86A70A"
    />
    <path
      d="M1.11205 6.47747L2.24708 7.6125C2.6927 6.42152 3.39011 5.30447 4.34729 4.34729C5.30447 3.39011 6.42152 2.6907 7.6125 2.24708L6.47747 1.11205C4.99474 -0.370684 2.59279 -0.370684 1.11205 1.11205C-0.370684 2.59479 -0.370684 4.99674 1.11205 6.47747Z"
      fill="#B7E40D"
    />
  </SVG>
)

export default withIconStyles(IconPearDeck)
