import React from 'react'
import { IconWarnCircle } from '@edulastic/icons'
import {
  StyledPDLMissingStandardsBanner,
  StyledPDLMissingStandardsBannerText,
  StyledPDLMissingStandardsBannerTextContainer,
} from './styled'

const PDLMissingStandardsBanner = () => {
  return (
    <StyledPDLMissingStandardsBanner>
      <IconWarnCircle color="#8E6601" />
      <StyledPDLMissingStandardsBannerTextContainer>
        <StyledPDLMissingStandardsBannerText>
          We couldn&apos;t find the selected standards in Pear Assessment.
          <p className="pdl-missing-standards-sub-text">
            You can manually add the standards to ensure accurate tracking and
            reporting.
          </p>
        </StyledPDLMissingStandardsBannerText>
      </StyledPDLMissingStandardsBannerTextContainer>
    </StyledPDLMissingStandardsBanner>
  )
}

export default PDLMissingStandardsBanner
