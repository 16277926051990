import { CREATE_TEST_SEGMENT_EVENT_NAME } from '../../../../../AssessmentCreate/components/CreationOptions/constants'

export const widgetKeys = {
  TEST_LIBRARY: 'test-library',
  AI_TEST: 'ai-test',
  GOOGLE_FORM_TEST: 'google-form-test',
  IMPORT_TEST: 'import-test',
  VIDEO_QUIZ_TEST: 'video-quiz-test',
  SNAP_QUIZ_TEST: 'doc-based-test',
  DEFAULT_TEST: 'default-test',
}

export const widgets = [
  {
    key: widgetKeys.TEST_LIBRARY,
    icon: 'IconTestLibrary',
    text: 'Test Library',
    link: '/author/tests',
  },
  {
    key: widgetKeys.AI_TEST,
    icon: 'IconAiTest',
    text: 'Use AI',
    aiTest: true,
  },
  {
    key: widgetKeys.GOOGLE_FORM_TEST,
    icon: 'IconGoogleFormTest',
    text: 'Import Google Form',
  },
  {
    key: widgetKeys.IMPORT_TEST,
    icon: 'IconContentConverter',
    text: 'Content Converter',
    link: '/author/tests/import',
    segmentEvent: {
      name: CREATE_TEST_SEGMENT_EVENT_NAME.TEST_IMPORT_CLICK,
      data: {
        source: 'Dashboard - Quick Shortcut',
      },
    },
  },
  {
    key: widgetKeys.VIDEO_QUIZ_TEST,
    icon: 'IconVideoQuiz',
    text: 'Video Quiz',
    link: '/author/vqlibrary',
    videoQuiz: true,
  },
  {
    key: widgetKeys.SNAP_QUIZ_TEST,
    icon: 'IconPdfTest',
    text: 'PDF to Test',
    link: '/author/tests/snapquiz',
  },
  {
    key: widgetKeys.DEFAULT_TEST,
    icon: 'IconCreateFromScratch',
    text: 'Create From Scratch',
    link: '/author/tests/create',
  },
]
