import React from 'react'
import { FlexContainer } from '@edulastic/common'
import { IconMagicWandGradientFilled, IconPDLSparkle } from '@edulastic/icons'
import {
  StyledAITestCreationOptionsDivider,
  StyledAITestCreationOptionsModal,
  StyledAITestCreationOptionsModalTitle,
} from './styled'
import AITestCreationOption from './AITestCreationOption'
import { AI_CREATION_OPTIONS } from './ducks/constants'

const PickAITestCreationOptionsModal = ({
  visible,
  onOptionSelect,
  onCloseModal,
}) => {
  const handleOptionSelect = (option) => {
    onOptionSelect(option)
  }

  return (
    <StyledAITestCreationOptionsModal
      title={
        <StyledAITestCreationOptionsModalTitle data-cy="aiTestCreationOptionsPopupHeader">
          Use AI
        </StyledAITestCreationOptionsModalTitle>
      }
      visible={visible}
      onCancel={onCloseModal}
      footer={null}
      width="500px"
      height="346px"
      centered
    >
      <FlexContainer
        flexDirection="column"
        data-cy="aiTestCreationOptionsPopup"
      >
        <AITestCreationOption
          dataCy="usePearAssessmentAITestCreationOption"
          option={AI_CREATION_OPTIONS.PEAR_ASSESSMENT_AI}
          handleOptionSelect={handleOptionSelect}
          optionIcon={
            <IconMagicWandGradientFilled width="18px" height="18px" />
          }
          title="Use Pear Assessment AI"
          description="Quickly create a test on a topic"
        />

        <StyledAITestCreationOptionsDivider />

        <AITestCreationOption
          dataCy="useILPAITestCreationOption"
          option={AI_CREATION_OPTIONS.INSTANT_LESSON_PACKAGE}
          handleOptionSelect={handleOptionSelect}
          optionIcon={<IconPDLSparkle width="23px" height="23px" />}
          title="Instant Lesson Packages"
          description="Quickly create tests, lessons, and practice sets in one place"
          packageIconsText="All-in-one access"
          showBetaTag
        />
      </FlexContainer>
    </StyledAITestCreationOptionsModal>
  )
}

export default PickAITestCreationOptionsModal
