import { get } from 'lodash'
import { notification } from '@edulastic/common'
import { createSlice } from 'redux-starter-kit'
import { createSelector } from 'reselect'
import { call, put, all, takeLatest, select } from 'redux-saga/effects'

import { reportsApi, dataWarehouseApi } from '@edulastic/api'

import { RESET_ALL_REPORTS } from '../../../common/reportsRedux'
import { EXTERNAL_SCORE_TYPES, staticDropDownData } from '../common/utils'
import { INITIAL_FILTERS, REQUEST_FILTERS } from './utils'

const initialState = {
  firstLoad: true,
  loadingFiltersData: false,
  prevFiltersData: null,
  filtersData: {},
  filtersTabKey: staticDropDownData.filterSections.STUDENT_FILTERS.key,
  filters: {
    ...INITIAL_FILTERS,
  },
  filterTagsData: {},
  settings: {
    requestFilters: {
      ...REQUEST_FILTERS,
    },
    frontEndFilters: {
      externalScoretype: EXTERNAL_SCORE_TYPES.SCALED_SCORE,
    },
    selectedFilterTagsData: {},
    selectedCompareBy: {},
  },
  loadingReportTableData: false,
  loadingReportChartData: false,
  reportChartData: {},
  reportTableData: {},
  error: '',
  selectedTests: [],
}

// -----|-----|-----|-----| SLICE BEGIN |-----|-----|-----|----- //

const slice = createSlice({
  name: 'multipleAssessmentReportDw', //! FIXME key should be `slice` not `name`
  initialState: { ...initialState },
  reducers: {
    fetchDWMARFiltersDataRequest: (state) => {
      state.loadingFiltersData = true
    },
    fetchDWMARFiltersDataRequestSuccess: (state, { payload }) => {
      state.loadingFiltersData = false
      state.filtersData = payload.filtersData
      state.error = ''
    },
    fetchDWMARFiltersDataRequestError: (state, { payload }) => {
      state.loadingFiltersData = false
      state.error = payload.error
    },
    setDWMARFirstLoad: (state, { payload }) => {
      state.firstLoad = payload
    },
    setDWMARFiltersTabKey: (state, { payload }) => {
      state.filtersTabKey = payload
    },
    setDWMARFilters: (state, { payload }) => {
      state.filters = { ...payload }
    },
    setDWMARFilterTagsData: (state, { payload }) => {
      state.filterTagsData = payload
    },
    setDWMARSelectedFilterTagsData: (state, { payload }) => {
      state.settings.selectedFilterTagsData = payload
    },
    setDWMARPrevFiltersData: (state, { payload }) => {
      state.prevFiltersData = payload
    },
    resetDWMARFiltersData: (state) => {
      state.filtersData = {}
    },
    setDWMARSettings: (state, { payload }) => {
      state.settings = payload
    },
    fetchDWMARChartDataRequest: (state) => {
      state.loadingReportChartData = true
    },
    fetchDWMARChartDataRequestSuccess: (state, { payload }) => {
      state.loadingReportChartData = false
      state.reportChartData = payload.reportChartData
      state.error = ''
    },
    fetchDWMARChartDataRequestError: (state, { payload }) => {
      state.loadingReportChartData = false
      state.error = payload.error
    },
    fetchDWMARTableDataRequest: (state) => {
      state.loadingReportTableData = true
    },
    fetchDWMARTableDataRequestSuccess: (state, { payload }) => {
      state.loadingReportTableData = false
      state.reportTableData = payload.reportTableData
      state.error = ''
    },
    fetchDWMARTableDataRequestError: (state, { payload }) => {
      state.loadingReportTableData = false
      state.error = payload.error
    },
    setSelectedTests: (state, { payload }) => {
      state.selectedTests = payload
    },
    resetDWMARReport: () => ({ ...initialState }),
  },
  extraReducers: {
    [RESET_ALL_REPORTS]: () => ({ ...initialState }),
  },
})

export const { actions, reducer } = slice

// -----|-----|-----|-----| SLICE ENDED |-----|-----|-----|----- //

// =====|=====|=====|=====| =============== |=====|=====|=====|===== //

// -----|-----|-----|-----| SELECTORS BEGIN |-----|-----|-----|----- //

const stateSelector = (state) =>
  state.reportReducer.reportMultipleAssessmentDwReducer

const firstLoad = createSelector(stateSelector, (state) => state.firstLoad)
const selectedTests = createSelector(
  stateSelector,
  (state) => state.selectedTests
)
const loadingFiltersData = createSelector(
  stateSelector,
  (state) => state.loadingFiltersData
)
const prevFiltersData = createSelector(
  stateSelector,
  (state) => state.prevFiltersData
)
const filtersData = createSelector(stateSelector, (state) => state.filtersData)
const filtersTabKey = createSelector(
  stateSelector,
  (state) => state.filtersTabKey
)
const filters = createSelector(stateSelector, (state) => state.filters)
const filterTagsData = createSelector(
  stateSelector,
  (state) => state.filterTagsData
)
const selectedFilterTagsData = createSelector(
  stateSelector,
  (state) => state.settings.selectedFilterTagsData
)
const loadingReportChartData = createSelector(
  stateSelector,
  (state) => state.loadingReportChartData
)
const loadingReportTableData = createSelector(
  stateSelector,
  (state) => state.loadingReportTableData
)
const settings = createSelector(stateSelector, (state) => state.settings)
const reportChartData = createSelector(
  stateSelector,
  (state) => state.reportChartData
)
const reportTableData = createSelector(
  stateSelector,
  (state) => state.reportTableData
)
const error = createSelector(stateSelector, (state) => state.error)

export const selectors = {
  firstLoad,
  selectedTests,
  loadingFiltersData,
  prevFiltersData,
  filtersData,
  filtersTabKey,
  filters,
  filterTagsData,
  selectedFilterTagsData,
  loadingReportChartData,
  loadingReportTableData,
  settings,
  reportChartData,
  reportTableData,
  error,
}

// -----|-----|-----|-----| SELECTORS ENDED |-----|-----|-----|----- //

// =====|=====|=====|=====| =============== |=====|=====|=====|===== //

// -----|-----|-----|-----| SAGAS BEGIN |-----|-----|-----|----- //

function* fetchFiltersDataRequestSaga({ payload }) {
  try {
    const filtersDataResult = yield call(reportsApi.fetchMARFilterData, payload)
    yield put(
      actions.fetchDWMARFiltersDataRequestSuccess({
        filtersData: filtersDataResult,
      })
    )
  } catch (e) {
    const msg =
      'Error getting filter data. Please try again after a few minutes.'
    notification({ msg })
    yield put(actions.fetchDWMARFiltersDataRequestError({ error: msg }))
  }
}

function* fetchMARChartDataRequestSaga({ payload }) {
  try {
    const reportChartDataResult = yield call(
      dataWarehouseApi.getMARChartMetrics,
      payload
    )
    const dataSizeExceeded =
      reportChartDataResult?.data?.dataSizeExceeded || false
    if (dataSizeExceeded) {
      yield put(
        actions.fetchDWMARChartDataRequestError({
          error: { ...reportChartDataResult.data },
        })
      )
      return
    }
    yield put(
      actions.fetchDWMARChartDataRequestSuccess({
        reportChartData: reportChartDataResult,
      })
    )
  } catch (e) {
    console.log('err', e.stack)
    const msg =
      'Error fetching performance trends data. Please try again after a few minutes.'
    notification({ msg })
    yield put(actions.fetchDWMARChartDataRequestError({ error: msg }))
  }
}

function* fetchMARTableDataRequestSaga({ payload }) {
  try {
    const reportTableDataResult = yield call(
      dataWarehouseApi.getMARTableMetrics,
      payload
    )

    // update rows count in result from previous state if not available
    if (!payload.requireTotalCount && !reportTableDataResult?.data?.rowsCount) {
      const prevReportTableData = yield select(selectors.reportTableData)
      const { rowsCount } = get(prevReportTableData, 'data.result', {})
      Object.assign(reportTableDataResult, {
        data: {
          ...(reportTableDataResult?.data || {}),
          result: { ...(reportTableDataResult?.data?.result || {}), rowsCount },
        },
      })
    }

    // update error if data size exceeded
    const dataSizeExceeded =
      reportTableDataResult?.data?.dataSizeExceeded || false
    if (dataSizeExceeded) {
      yield put(
        actions.fetchDWMARTableDataRequestError({
          error: { ...reportTableDataResult.data },
        })
      )
      return
    }

    // update state with result for reportTableData on success
    yield put(
      actions.fetchDWMARTableDataRequestSuccess({
        reportTableData: reportTableDataResult,
      })
    )
  } catch (e) {
    console.log('err', e.stack)
    const msg =
      'Error fetching performance trends data. Please try again after a few minutes.'
    notification({ msg })
    yield put(actions.fetchDWMARTableDataRequestError({ error: msg }))
  }
}

export function* watcherSaga() {
  yield all([
    takeLatest(
      actions.fetchDWMARFiltersDataRequest,
      fetchFiltersDataRequestSaga
    ),
    takeLatest(
      actions.fetchDWMARChartDataRequest,
      fetchMARChartDataRequestSaga
    ),
    takeLatest(
      actions.fetchDWMARTableDataRequest,
      fetchMARTableDataRequestSaga
    ),
  ])
}

// -----|-----|-----|-----| SAGAS ENDED |-----|-----|-----|----- //
