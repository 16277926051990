import { notification } from '@edulastic/common'
import _, { cloneDeep, isEmpty } from 'lodash'
import { useState, useEffect, useCallback } from 'react'
import { segmentApi } from '@edulastic/api'
import { selectsData } from '../../../../TestPage/components/common'
import { isSectionsAdaptiveTestItemsLimitExceededAndNotify } from '../../../../TestPage/utils'
import { aiAttachmentTypes, aiAttachmentMessageTypes } from '../constants'
import {
  isGoogleDocsUrl,
  isValidUrl,
} from '../../../../../assessment/utils/urlUtils'

export const useSaveForm = ({
  hasSections,
  isAiGeneratedTest,
  getAiGeneratedTestItems,
  addItems,
  resetTestDetails,
  testTitle = '',
  setDefaultTest,
  clearCreatedItem,
  currentGroupIndexValueFromStore,
  showSelectGroupIndexModal,
  savePreselected,
  isSectionAdaptiveTest,
  adaptiveDeliveryData,
  itemGroups,
}) => {
  const initialAiFormData = {
    itemTypes: '',
    numberOfItems: 5,
    dok: '',
    difficulty: '',
    preference: '',
    testName: testTitle,
    attachments: [{ url: '' }],
    alignment: [
      {
        curriculum: '',
        curriculumId: '',
        domains: [],
        grades: [],
        standards: [],
        subject: '',
      },
    ],
  }

  useEffect(() => {
    if (!addItems && isAiGeneratedTest) {
      setDefaultTest()
      clearCreatedItem()
    }
  }, [addItems])

  const [selectSectionVisible, setSelectSectionVisible] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [aiFormContent, setAiFromContent] = useState(initialAiFormData)
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0)
  const [attachmentType, setAttachmentType] = useState(null)
  const [attachmentMessageData, _setAttachmentMessageData] = useState({
    type: null,
    message: '',
  })
  const [isGenerateDisabled, setIsGenerateDisabled] = useState(false)

  const trackAttachmentErrorEvent = useCallback(
    _.throttle((data) => {
      segmentApi.genericEventTrack('AIQuizCreate:AttachmentError', {
        error: data?.message,
      })
    }, 1000),
    []
  )

  const setAttachmentMessageData = (data) => {
    _setAttachmentMessageData(data)
    if (data.type === aiAttachmentMessageTypes.ERROR) {
      trackAttachmentErrorEvent(data)
    }
  }

  const updateAlignment = (alignment) => {
    setAiFromContent((state) => ({ ...state, alignment }))
  }

  const onCreateItems = (showSelectGroup = true) => {
    resetTestDetails()
    if (addItems && showSelectGroup && hasSections) {
      if (
        !showSelectGroupIndexModal &&
        typeof currentGroupIndexValueFromStore === 'number'
      ) {
        // eslint-disable-next-line no-use-before-define
        handleSelectGroupResponse(currentGroupIndexValueFromStore)
        return
      }
      setSelectSectionVisible(true)
    } else {
      setSelectSectionVisible(false)
      setIsVisible(true)
    }
  }

  const handleSelectGroupResponse = (groupIndex) => {
    if (groupIndex > -1) {
      setSelectedGroupIndex(groupIndex)
      onCreateItems(false)
    } else {
      setSelectSectionVisible(false)
    }
  }

  const resetAiFormData = () => {
    setAiFromContent({ ...initialAiFormData })
  }

  const closeDrawer = () => {
    setIsVisible(false)
    resetAiFormData()
    setAttachmentType(null)
    setAttachmentMessageData({
      type: null,
      message: '',
    })
  }

  const onCancel = () => {
    if (aiFormContent.changed) {
      segmentApi.genericEventTrack('AIQuizCreate:CancelWithChanges', {})
    }
    closeDrawer()
  }

  const handleFieldDataChange = (field, value) => {
    setAiFromContent({ ...aiFormContent, [field]: value, changed: true })
  }

  const updateAttachmentErrorMsg = (message) => {
    setIsGenerateDisabled(true)
    setAttachmentMessageData({
      type: aiAttachmentMessageTypes.ERROR,
      message,
    })
  }

  const handleAttachmentChange = ({ type, url }) => {
    if (type === aiAttachmentTypes.URL) {
      setAiFromContent((_aiFormContent) => ({
        ..._aiFormContent,
        attachments: [{ url }],
        changed: true,
      }))
      if (!url?.length) {
        setIsGenerateDisabled(false)
        return
      }

      if (!isValidUrl(url)) {
        updateAttachmentErrorMsg(
          'The URL provided is invalid. Please check the URL and try again.'
        )
        return
      }

      if (isGoogleDocsUrl(url)) {
        updateAttachmentErrorMsg(
          'Please use the Document Upload functionality for Google Docs.'
        )
        return
      }

      setIsGenerateDisabled(false)
      setAttachmentMessageData({
        type: aiAttachmentMessageTypes.INFO,
        message:
          'For best results, use Wikipedia articles or public websites with URLs that do not require a login.',
      })
    } else if (type === aiAttachmentTypes.DOCUMENT) {
      setAiFromContent((_aiFormContent) => ({
        ..._aiFormContent,
        attachments: [{ url }],
        changed: true,
      }))
      setIsGenerateDisabled(false)
    }
  }

  const handleAiFormSubmit = (_selectedGroupIndex = 0) => {
    const _aiFormContent = cloneDeep(aiFormContent)
    const {
      testName,
      itemType,
      numberOfItems,
      alignment,
      dok,
      difficulty,
      preference,
    } = _aiFormContent

    let { attachments = [] } = _aiFormContent

    if (isEmpty(testName) && !addItems) {
      return notification({
        type: 'warn',
        messageKey: 'pleaseEnterName',
      })
    }

    if (isEmpty(itemType)) {
      return notification({
        type: 'warn',
        messageKey: 'itemTypesEmpty',
      })
    }

    if (
      numberOfItems === null ||
      numberOfItems === '' ||
      (parseInt(numberOfItems, 10) < 1 && parseInt(numberOfItems, 10) > 20)
    ) {
      return notification({
        type: 'warn',
        messageKey: 'pleaseProvideValidNumberOfItems',
      })
    }

    if (isEmpty(alignment[0].grades)) {
      return notification({
        type: 'warn',
        messageKey: 'gradeFieldEmpty',
      })
    }

    if (isEmpty(alignment[0].subject)) {
      return notification({
        type: 'warn',
        messageKey: 'subjectFieldEmpty',
      })
    }

    if (isEmpty(dok)) {
      _aiFormContent.dok = selectsData.allDepthOfKnowledgeAI
        .map(({ value }) => value)
        .filter((x) => x)
    } else {
      _aiFormContent.dok = [dok]
    }
    if (isEmpty(difficulty)) {
      _aiFormContent.difficulty = selectsData.allAuthorDifficulty
        .map(({ value }) => value)
        .filter((x) => x)
    } else {
      _aiFormContent.difficulty = [difficulty]
    }

    if (attachments?.length) {
      attachments = (attachments || []).filter(
        (attachment) => attachment?.url?.length
      )
    }

    if (isSectionAdaptiveTest) {
      const isItemLimitExceeded = isSectionsAdaptiveTestItemsLimitExceededAndNotify(
        {
          itemGroup: itemGroups?.[_selectedGroupIndex] || {},
          adaptiveDeliveryData,
          itemsToBeAdded: [...Array(numberOfItems).keys()],
          isBaseSection: _selectedGroupIndex === 0,
        }
      )
      if (isItemLimitExceeded) {
        return
      }
    }

    getAiGeneratedTestItems({
      ..._aiFormContent,
      savePreselected,
      testName: testName.trim(),
      preference: preference.trim(),
      groupIndex: _selectedGroupIndex,
      attachments,
    })
  }

  return {
    selectSectionVisible,
    setSelectSectionVisible,
    isVisible,
    onCreateItems,
    onCancel,
    closeDrawer,
    handleFieldDataChange,
    aiFormContent,
    resetAiFormData,
    handleAiFormSubmit,
    updateAlignment,
    selectedGroupIndex,
    setSelectedGroupIndex,
    handleSelectGroupResponse,
    setAttachmentType,
    attachmentType,
    handleAttachmentChange,
    attachmentMessageData,
    setAttachmentMessageData,
    isGenerateDisabled,
    setIsGenerateDisabled,
  }
}
