import React from 'react'
import { FlexContainer, EduButton } from '@edulastic/common'
import {
  StyledContent,
  StyledModalTitle,
  StyledUnVerifiedUserWarningModal,
} from './styled'

const UnVerifiedUserWarningModal = ({
  visible,
  onCloseModal,
  closeButtonText,
}) => {
  return (
    <StyledUnVerifiedUserWarningModal
      title={
        <StyledModalTitle data-cy="verifyEmailWarningPopUpTitle">
          Verify Your Email to Proceed
        </StyledModalTitle>
      }
      visible={visible}
      onCancel={onCloseModal}
      footer={
        <FlexContainer>
          <EduButton height="32px" onClick={() => onCloseModal()}>
            {closeButtonText}
          </EduButton>
        </FlexContainer>
      }
      width="450px"
      height="246px"
      centered
    >
      <StyledContent data-cy="verifyEmailWarningPopUpContent">
        Please verify your email to access this feature. Check your inbox for
        the verification email.
      </StyledContent>
    </StyledUnVerifiedUserWarningModal>
  )
}

export default UnVerifiedUserWarningModal
