import React, { useState } from 'react'
import { Checkbox, Col, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { DatePickerStyled, FieldLabel, RadioBtn } from '@edulastic/common'
import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'
import {
  test as testConst,
  assignmentPolicyOptions,
} from '@edulastic/constants'
import {
  StyledEasyAssignRememberPreferenceContainer,
  TabContentContainer,
} from '../Container/styled'
import { rememberEasyAssignPreferenceLocalStorageKey } from './constants'
import { getUserDistrict, getUserId } from '../../../src/selectors/user'
import DetailsTooltip from '../Container/DetailsTooltip'
import CalculatorSettings, {
  CalculatorDetailsTooltip,
} from '../../../Shared/Components/CalculatorSettings'
import {
  AlignSwitchRight,
  Label,
  StyledCol,
  StyledRadioGroupWrapper,
  StyledRow,
} from './styled'
import DollarPremiumSymbol from '../Container/DollarPremiumSymbol'
import ClassStudentSelector from './ClassStudentSelector'
import {
  isNormalSectionsTestSelector,
  isSectionAdaptiveTestSelector,
  isVideoQuizSelector,
} from '../../../TestPage/ducks'
import SettingContainer from '../Container/SettingsContainer'
import PlayerSkinSelector from './PlayerSkinSelector'
import { isPearDomain } from '../../../../../utils/pear'
import {
  edulasticText,
  pearAssessmentText,
} from '../../../../common/utils/helpers'

const {
  POLICY_CLOSE_MANUALLY_BY_ADMIN,
  POLICY_CLOSE_MANUALLY_IN_CLASS,
  POLICY_AUTO_ON_STARTDATE,
} = assignmentPolicyOptions

const EasyAssign = ({
  termId,
  userId,
  tootltipWidth,
  assignment,
  testSettings,
  featuresAvailable,
  freezeSettings,
  overRideSettings,
  isDocBased,
  changeField,
  fetchStudents,
  classIds,
  group,
  createClassHandler,
  studentOfSelectedClass,
  updateStudents,
  selectAllStudents,
  unselectAllStudents,
  handleRemoveStudents,
  isAssignRecommendations,
  t: i18Translate,
  isVideoQuiz,
  isNormalSectionsTest,
  isSectionAdaptiveTest,
  districtId,
  userRole,
  assignmentSettings,
}) => {
  const {
    safeBrowser = testSettings.safeBrowser,
    calcTypes = testSettings?.calcTypes,
    restrictNavigationOut = testSettings.restrictNavigationOut,
    restrictNavigationOutAttemptsThreshold = testSettings.restrictNavigationOutAttemptsThreshold,
    shuffleQuestions = testSettings.shuffleQuestions,
    vqPreventSkipping = testSettings?.vqPreventSkipping,
    vqEnableClosedCaption = testSettings?.vqEnableClosedCaption,
    lockSections = testSettings.lockSections,
    passwordPolicy,
    startDate,
    endDate,
    closePolicy = POLICY_AUTO_ON_STARTDATE,
  } = assignment

  const {
    assessmentSuperPowersShowCalculator,
    assessmentSuperPowersShuffleQuestions,
    selectPlayerSkinType,
    premium,
  } = featuresAvailable

  const navigationThresholdMoreThan1 =
    restrictNavigationOut === 'warn-and-report-after-n-alerts' &&
    restrictNavigationOutAttemptsThreshold > 1

  const disabledStartDate = (_startDate) => {
    if (!_startDate || !endDate) {
      return false
    }
    return _startDate.valueOf() < Date.now()
  }

  const disabledEndDate = (_endDate) => {
    if (!_endDate || !startDate) {
      return false
    }
    return (
      _endDate.valueOf() < startDate.valueOf() ||
      _endDate.valueOf() < Date.now()
    )
  }

  const rememberPreferenceLocalStorageKey = `${rememberEasyAssignPreferenceLocalStorageKey}-${userId}-${districtId}-${termId}`
  const [rememberPreference, setRememberPreference] = useState(() => {
    const rememberPreferenceStored = localStorage.getItem(
      rememberPreferenceLocalStorageKey
    )
    return JSON.parse(rememberPreferenceStored || 'false')
  })

  const playerSkinType =
    assignmentSettings.playerSkinType || testSettings.playerSkinType

  return (
    <TabContentContainer>
      <ClassStudentSelector
        tootltipWidth={tootltipWidth}
        changeField={changeField}
        fetchStudents={fetchStudents}
        classIds={classIds}
        createClassHandler={createClassHandler}
        group={group}
        studentOfSelectedClass={studentOfSelectedClass}
        updateStudents={updateStudents}
        selectAllStudents={selectAllStudents}
        unselectAllStudents={unselectAllStudents}
        handleRemoveStudents={handleRemoveStudents}
        assignment={assignment}
      />
      <>
        {!isAssignRecommendations && (
          <SettingContainer id="open-date-setting">
            <DetailsTooltip
              width={tootltipWidth}
              title="Open date and Close date"
              content={`Open Date indicates when the test window begins. It is the earliest day and time that the students can access the test. You can set it to the day, hour, and minute.\n\nThe Close Date indicates the last time the test is available to students. You can set it to the day, hour, and minute. Once closed, the data from the test will flow to the Insight reports. You may adjust this close date on the Live Class Board Settings at any time.`}
              premium
            />
            <StyledRow mb="15px" gutter={16}>
              <StyledCol span={8}>
                <FieldLabel top={10}>Open AND CLOSE DATE</FieldLabel>
              </StyledCol>
              <StyledCol span={8}>
                <Tooltip
                  placement="top"
                  title={
                    passwordPolicy ===
                    testConst.passwordPolicy.REQUIRED_PASSWORD_POLICY_DYNAMIC
                      ? 'To modify set Dynamic Password as OFF'
                      : null
                  }
                >
                  <DatePickerStyled
                    allowClear={false}
                    data-cy="startDate"
                    size="large"
                    style={{ width: '100%' }}
                    disabledDate={disabledStartDate}
                    showTime={{ use12Hours: true, format: 'hh:mm a' }}
                    format="YYYY-MM-DD hh:mm a"
                    value={startDate}
                    placeholder={i18Translate(
                      'common.assignTest.openDatePlaceholder'
                    )}
                    onChange={changeField('startDate')}
                    disabled={
                      passwordPolicy ===
                      testConst.passwordPolicy.REQUIRED_PASSWORD_POLICY_DYNAMIC
                    }
                  />
                </Tooltip>
              </StyledCol>
              <StyledCol span={8}>
                {[
                  POLICY_CLOSE_MANUALLY_BY_ADMIN,
                  POLICY_CLOSE_MANUALLY_IN_CLASS,
                ].includes(closePolicy) ? (
                  'Close Manually by User'
                ) : (
                  <DatePickerStyled
                    allowClear={false}
                    data-cy="closeDate"
                    style={{ width: '100%' }}
                    size="large"
                    disabledDate={disabledEndDate}
                    showTime={{ use12Hours: true, format: 'hh:mm a' }}
                    format="YYYY-MM-DD hh:mm a"
                    value={endDate}
                    placeholder={i18Translate(
                      'common.assignTest.closeDatePlaceholder'
                    )}
                    showToday={false}
                    onChange={changeField('endDate')}
                  />
                )}
              </StyledCol>
            </StyledRow>
          </SettingContainer>
        )}
      </>
      {/* Show Calculator */}
      {!isVideoQuiz && !(isNormalSectionsTest || isSectionAdaptiveTest) && (
        <SettingContainer>
          <DetailsTooltip
            width={tootltipWidth}
            title="SHOW CALCULATOR"
            content={<CalculatorDetailsTooltip count={calcTypes.length} />}
            premium={assessmentSuperPowersShowCalculator}
          />
          <StyledRow gutter={16} mb="15px">
            <Col span={8}>
              <Label paddingTop="10px">
                SHOW CALCULATOR (Desmos)
                <DollarPremiumSymbol
                  premium={assessmentSuperPowersShowCalculator}
                />
              </Label>
            </Col>
            <Col span={16}>
              <CalculatorSettings
                disabled={
                  freezeSettings || !assessmentSuperPowersShowCalculator
                }
                value={calcTypes}
                onChange={(value) => overRideSettings('calcTypes', value)}
              />
            </Col>
          </StyledRow>
        </SettingContainer>
      )}

      {/* Restrict navigation out */}
      <SettingContainer id="restrict-nav-out-setting">
        <DetailsTooltip
          width={tootltipWidth}
          title="Restrict Navigation Out Of Test"
          content={
            <>
              <p>{i18Translate('restrictNavigationOut.infoPara1')}</p>
              <p>{i18Translate('restrictNavigationOut.infoPara2')}</p>
              {navigationThresholdMoreThan1 ? (
                <>
                  <br />
                  <p>
                    Alert will appear if student has navigated away for more
                    than 5 seconds and student will be blocked after{' '}
                    {restrictNavigationOutAttemptsThreshold * 5} seconds
                  </p>
                </>
              ) : (
                ''
              )}
            </>
          }
          premium={premium}
        />
        <StyledRow gutter={16} mb="15px">
          <Col span={8}>
            <Label paddingTop="4px">Restrict Navigation Out Of Test</Label>
          </Col>
          <Col span={16}>
            <StyledRadioGroupWrapper
              value={!premium ? undefined : restrictNavigationOut || undefined}
              disabled={freezeSettings || !premium || safeBrowser}
              onChange={(e) => {
                overRideSettings('restrictNavigationOut', e.target.value)
              }}
              noTopPadding
              noWrap
            >
              <RadioBtn
                value={undefined}
                data-cy="restrict-nav-out-disabled"
                title={
                  safeBrowser && 'Disabled since Safe Exam Browser Enabled'
                }
              >
                DON’T RESTRICT
              </RadioBtn>
              <RadioBtn
                title={
                  safeBrowser && 'Disabled since Safe Exam Browser Enabled'
                }
                value="warn-and-report"
                data-cy="restrict-nav-out-warn-report"
              >
                WARN & REPORT
              </RadioBtn>
              <RadioBtn
                value="warn-and-report-after-n-alerts"
                data-cy="restrict-nav-out-warn-report-alerts"
                title={
                  safeBrowser
                    ? 'Disabled since Safe Exam Browser Enabled'
                    : 'Alert will appear if student has navigated away for more than 5 seconds'
                }
              >
                WARN & BLOCK
              </RadioBtn>
            </StyledRadioGroupWrapper>
          </Col>
        </StyledRow>
      </SettingContainer>

      {
        /* Shuffle Question */
        !isDocBased &&
          !isVideoQuiz &&
          !(isNormalSectionsTest || isSectionAdaptiveTest) && (
            <SettingContainer id="shuffle-items-setting">
              <DetailsTooltip
                width={tootltipWidth}
                title="SHUFFLE ITEMS"
                content="If ON, then order of questions will be different for each student."
                premium={assessmentSuperPowersShuffleQuestions}
              />
              <StyledRow gutter={16} mb="15px">
                <Col span={8}>
                  <Label>SHUFFLE ITEMS</Label>
                </Col>
                <Col span={16}>
                  <AlignSwitchRight
                    disabled={
                      freezeSettings || !assessmentSuperPowersShuffleQuestions
                    }
                    size="small"
                    checked={shuffleQuestions}
                    data-cy="shuffle-questions"
                    onChange={(value) =>
                      overRideSettings('shuffleQuestions', value)
                    }
                    aria-label="Shuffle Items"
                  />
                </Col>
              </StyledRow>
            </SettingContainer>
          )
        /* Shuffle Question */
      }
      {isVideoQuiz && [
        <SettingContainer id="vq-prevent-skipping">
          <DetailsTooltip
            width={tootltipWidth}
            title="Prevent Skipping"
            content=" If ON, Students won't be able to skip ahead in a video."
            placement="rightTop"
            premium={premium}
          />
          <StyledRow gutter={16} mb="15px">
            <Col span={8}>
              <Label>Prevent Skipping</Label>
            </Col>
            <Col span={16}>
              <AlignSwitchRight
                disabled={freezeSettings}
                size="small"
                checked={vqPreventSkipping}
                data-cy="vqPreventSkipping"
                onChange={(value) =>
                  overRideSettings('vqPreventSkipping', value)
                }
              />
            </Col>
          </StyledRow>
        </SettingContainer>,
        <SettingContainer id="vq-enable-cc">
          <DetailsTooltip
            width={tootltipWidth}
            title="Turn on closed captions"
            content="Enable closed captions if available for YouTube videos."
            placement="rightTop"
            premium={premium}
          />
          <StyledRow gutter={16} mb="15px">
            <Col span={8}>
              <Label>Turn on closed captions</Label>
            </Col>
            <Col span={16}>
              <AlignSwitchRight
                disabled={freezeSettings}
                size="small"
                checked={vqEnableClosedCaption}
                data-cy="vqEnableClosedCaption"
                onChange={(value) =>
                  overRideSettings('vqEnableClosedCaption', value)
                }
              />
            </Col>
          </StyledRow>
        </SettingContainer>,
      ]}

      {isNormalSectionsTest && (
        <SettingContainer id="lock-sections-setting">
          <DetailsTooltip
            width={tootltipWidth}
            title="Enable Lock Sections"
            content={i18Translate('lockSectionsSettings.info')}
            premium={premium}
          />
          <StyledRow gutter={16} mb="15px">
            <Col span={8}>
              <Label>LOCK SECTIONS</Label>
            </Col>
            <Col span={16}>
              <AlignSwitchRight
                disabled={freezeSettings || !premium}
                size="small"
                checked={lockSections}
                data-cy="lock-sections"
                onChange={(value) => overRideSettings('lockSections', value)}
              />
            </Col>
          </StyledRow>
        </SettingContainer>
      )}
      {(assignmentSettings?.testType || testSettings.testType) !== 'testlet' &&
        !testSettings.isDocBased && (
          <SettingContainer id="player-skin-setting">
            <DetailsTooltip
              width={tootltipWidth}
              title="Test Interface"
              content={`Teachers can change the look and feel of the assessments to more closely align with formats similar to state and nationally administered assessments. If you don’t see your state, select the generic option, ${
                isPearDomain ? pearAssessmentText : edulasticText
              } Test.`}
              premium={selectPlayerSkinType}
              placement="rightTop"
            />
            <PlayerSkinSelector
              userRole={userRole}
              playerSkinType={playerSkinType}
              onAssignmentTypeChange={changeField('playerSkinType')}
              testType={assignmentSettings?.testType || testSettings.testType}
              selectBackgroundWhite
              disabled={freezeSettings || !selectPlayerSkinType}
              fullwidth
            />
          </SettingContainer>
        )}
      <SettingContainer>
        <StyledEasyAssignRememberPreferenceContainer>
          <Checkbox
            data-cy="basicSettingsAsPreference"
            checked={rememberPreference}
            onChange={() => {
              localStorage.setItem(
                rememberPreferenceLocalStorageKey,
                JSON.stringify(!rememberPreference)
              )
              setRememberPreference(!rememberPreference)
            }}
          />{' '}
          Remember my preference to use basic settings in the future
        </StyledEasyAssignRememberPreferenceContainer>
      </SettingContainer>
    </TabContentContainer>
  )
}

const enhance = compose(
  withNamespaces('author'),
  connect((state) => ({
    termId: state.user?.user?.orgData?.defaultTermId,
    userId: getUserId(state),
    districtId: getUserDistrict(state)?.districtId,
    isVideoQuiz: isVideoQuizSelector(state),
    isNormalSectionsTest: isNormalSectionsTestSelector(state),
    isSectionAdaptiveTest: isSectionAdaptiveTestSelector(state),
    assignmentSettings: state.assignmentSettings,
  }))
)

export default enhance(EasyAssign)
